import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0014 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.04.02</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>The business cards (with the question) have been ordered.  The article on getting MO was published in the Democracy Chronicles (<span><a href='https://democracychronicles.org/time-to-get-mo/'>https://democracychronicles.org/time-to-get-mo/</a></span>). I decided to put together a discord server and use that medium as a way to get and keep people engaged.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>I will wait until through next week and ask again. So, not this update, but next.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>Although I did do a lot of studying on <span><a href='https://codewithmosh.com//'>https://codewithmosh.com</a></span>, I think the right answer is to set up the Discord Server as the center of the score voting movement.  I think the most important difference with this will be that it is supported by a small team, but not directed by it.  The vision is simple and the goals are easy, but up to now, people (and not that many) would just sign up on the site, and then what.  Discord provides that “and then what.”  It should be up not later than the end of next week, if that long.  Then I am ready for the book to come out.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>The MO is good, but I am leaning toward creating a talk based on the one election.  I must remember that different people will approach the issue differently, but even tonight I can across people who believe that RCV will end polarization and gerrymandering.  I will need a slew of talks, and supporter tools, that handle several different approaches.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I will get the discord site up and then populate it with some of my best supporter materials and such.  It needs to be fully tested as well.  Additionally, I might develop the single picture talk.  Zelda says I’m a good speaker.  Maybe I’ll go with that.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0014;
