import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import BlackCurtain1 from "./blogImg/BlackCurtain1.png";
import BlackCurtain2 from "./blogImg/BlackCurtain2.png";
import BlackCurtain3 from "./blogImg/BlackCurtain3.png";
import BlackCurtain4 from "./blogImg/BlackCurtain4.png";
import BlackCurtain5 from "./blogImg/BlackCurtain5.png";
import BlackCurtain6 from "./blogImg/BlackCurtain6.png";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0035 = () => {
    const [image] = useState(logo);
    const [b1] = useState(BlackCurtain1);
    const [b2] = useState(BlackCurtain2);
    const [b3] = useState(BlackCurtain3);
    const [b4] = useState(BlackCurtain4);
    const [b5] = useState(BlackCurtain5);
    const [b6] = useState(BlackCurtain6);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.09.03</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>If you were in charge, what would you do?</strong><p />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                If you were in charge, what would you choose for the people of United States.
            </Col></Row><p />
            <Row><Col>
                <img className="imageInternal" src={b1} alt="" />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">To be specific, in this picture you’re looking at the result of an election between three candidates, A, B, and C with five voters.  Each voter has indicated how much they desire each candidate to win on a score ranging from 0, the least amount of desire, to 9, the most amount of desire.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                This is everything you know.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                You don’t know anything more about the candidates, what these candidates stand for, their political parties, if they have one, their experience, gender, ethnicity, age, nothing.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                You don’t know anything more about the voters, their demographics, if they voted strategically or honestly. You don’t even know how many voters there actually are.  It may be that each voter represents a million voters or 10 million voters.  Because you don’t even know what this election is for.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                This election could be for President of the United States.  It could be for a state office, or local office. It may be partisan or non-partisan.  In short, it could be any election.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                As you are in charge, you get to decide, “who should win?”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Please write down the letter of that candidate.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                If you think candidate C should win, then using plurality voting will create that result as candidate C would receive 60% of the vote to A, who received 40%. Plurality voting is where everyone picks the one candidate they most want to win and the candidate most picked wins.  By the way, 60% of the vote is a land-slide victory. By comparison, only on four occasions in the history of the United States, has a presidential candidate received as much as 60% of the popular vote.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Ranked-choice voting (RCV) is a plurality voting-style election where a 50% minimum threshold has been established and an instant runoff takes the place of a live runoff.  RCV is also known as Instant Runoff Voting, Preferential Voting, Top Four Voting, and Top Five Voting.  The 50% threshold means that, if no candidate obtains 50% of the vote, the candidate with the fewest votes is dropped and a runoff election is triggered.  The instant runoff happens as each voter can submit a list of candidates in their preferred order so that if their top choice is eliminated the voting method knows who they would vote for instead. A voter’s ballot always goes to the voter’s most preferred candidate still in the race.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                RCV would also elect candidate C and would ensure that the winning candidate received a least a 50% majority.  In this case, there is no need for the voting method to adjust any voter’s choices. This makes this the best possible result for RCV as well.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                STAR voting stands for “Score then Automatic Runoff.” Score voting is where every voter gives every candidate a score from 0 to 5.  The “automatic runoff” happens when the two highest scoring candidates are compared to see which candidate was preferred by voters most often. That candidate wins.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                STAR Voting also elects candidate C.  Here the most desired candidates are B with 40 points and C with 27 points.  C is then preferred over B three times to two.  STAR Voting also identifies Candidate C as the 60% majority winner.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Maximum voting is where every voter gives points to every candidate, say 0 to 9, and the candidate getting the highest average number of points from voters wins.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                If you think Candidate B should win, maximum voting would deliver that result.  Here, candidate B received 40 points; C, 27; and A, 18.  Notice that no voter had identified candidate B as their top choice.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Approval Voting is when voters identify all the candidates they at least approve of.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                To determine the approval voting result, we can interpolate that scores from 0 to 4 are disapprove and scores from 5 to 9 are approve.  As such candidate B was approved the most, 5 times, over C’s three times or A’s two times.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Choosing the voting method allows you to deliver the result you think the population desires.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Now look at this election. Again, we know nothing more than what we see here.
            </Col></Row><p />
            <Row><Col>
                <img className="imageInternal" src={b2} alt="" />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Who should win? Please, write down your answer.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                In this instance all the voting methods, except approval voting, will elect candidate C.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Approval voting leaves it to become a coin toss between candidate A and candidate C as both of these candidate’s received five approval votes each.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Once again, in this election, “Who should win?” please write down your answer.
            </Col></Row><p />
            <Row><Col>
                <img className="imageInternal" src={b3} alt="" />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                In this instance, as in the previous two, plurality, RCV, and STAR Voting would all elect candidate C.  Both score voting and approval voting would elect candidate A.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                One final election. </Col></Row><p />
            <Row><Col>
                <img className="imageInternal" src={b4} alt="" />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                This time there will be four candidates, but it will be the same question.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Who should win here?  Write down your answer.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                If you think candidate C should win, again, either plurality, RCV, or STAR Voting would deliver that result.  Approval voting would create a toss-up between candidates D and C as both were approved 3 times.  Score voting, would declare candidate B the winner with 28 points to C’s 27 points, to A’s 18 points, to D’s 17 points.
            </Col></Row><p /><Row><Col>
                <img className="imageInternal" src={b5} alt="" />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                By now you should have an idea of which voting method will deliver to your people the result they desire. There is no “right” voting method, just like there is no “right” society.  It is just a matter of what society you want.  How divided do you want it to be?  How much do you want the representatives to listen to all the voters?  How empowered do you want all the voters to be?  Remember, the voting method not only determines who will win, but who we’ll be.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                The last thing I want you to understand is that choosing a voting method also means choosing what you can know about what the people want.  If these four elections had use either plurality or ranked choice voting elections, this is all you would have ever see of what the voters desire.
            </Col></Row><p /><Row><Col>
                <img className="imageInternal" src={b6} alt="" />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">

                Four identical land-slide victories for candidate C. Take a look at list of the candidates you wrote down.  Are they all the same?

            </Col></Row><p />

        </Container>
        </>
    )
}

export default Blog0035;
