import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0049 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.12.10</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>What Is Your Problem</title>
                <strong>What Is Your Problem?</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Before you consider whether or not to support Ranked Choice Voting (RCV) ask yourself, “what is the problem I am trying to solve?”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">If your problem is that the runoffs for your 50% threshold plurality voting elections are expensive, then RCV has something to offer.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">If your problem is that your elections allow some political faction other than the majority faction to win, then RCV has something to offer.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But, if your problem is that your nation is polarized, gridlocked, and gerrymandered and you don’t want that, than NO, RCV has nothing to offer.

            </Col></Row><p /><br />
            <Row ><Col xs="12" sm="12" md="12" className="main">RANKED CHOICE VOTING: WHAT PROBLEM WAS IT DESIGNED TO SOLVE?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">RCV was designed to end expensive and poorly attended run-off and reduce mug slinging. According to the actual information provided to voters by the city for Proposition A in 2002:
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Proposition A will allow San Francisco to elect candidates supported by a popular majority without needing expensive, low-turnout December runoff elections. This will SAVE $2 MILLION TAX DOLLARS PER YEAR, RAISE VOTER TURNOUT and REDUCE NEGATIVE CAMPAIGNING. Runoffs are costly to taxpayers. The December runoff for city attorney cost nearly $2 MILLION, an average of $ 29 per voter. Previous runoff elections have seen excessive negative campaigning and hit pieces. Such mudslinging is common when the field is reduced to two candidates, and candidates can win by attacking their lone opponent rather than attracting voters. The purpose of the runoff to ensure majority support for winners is a good one, but huge declines in voter turnout, high costs, and negative campaigning undermine this worthy goal.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Proposition A, reasons to approve, <a href="http://www.smartvoter.org/2002/03/05/ca/sf/prop/A/">http://www.smartvoter.org/2002/03/05/ca/sf/prop/A/</a>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">RANKED CHOICE VOTING KEEPS INDEPENDENTS OUT (AS MUCH AS PLURALITY ANYWAY).
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">RCV was never designed or intended to open up the field of candidates to those beyond the two main political parties, and it doesn’t.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">In 1918, the conservative faction of Australia implemented ranked choice voting to ensure the liberal faction could not win (unless they became the majority faction).  Burlington Vermont did the same thing in 2021 when the liberal factions teamed up ensure the conservatives could not win.
            </Col></Row><p /><br />
            <Row ><Col xs="12" sm="12" md="12" className="main">RANKED CHOICE VOTING IS NOT DESIGNED TO DELIVER THE MOST DESIRED CANDIDATE.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">RCV was never designed or intended to deliver the voter’s most desired candidate. To do that would require the election system to know which candidates a voter likes, dislikes and how all of them rate in relation to each other. RCV, like all other pikc-one-candidate min voting methods, uses one piece of information to determine the winner of an election--for which candidate did a voter vote. This system can deliver some of the voter’s least desired candidates.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Max Voting delivers. This happens because it ensures maximum voter power. Voters provide the most possible information about the candidates that they can, which they like, which they dislike, and how much they like or dislike all candidates.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Max voting solves America’s problems.  Using max voting candidates gain an advantage by aligning to the majority opinion. The majority-opinion is determined by the opinions of all of the voters. With all candidates establishing the same political platform, political polarization is severely curtailed, campaign rhetoric is toned down, and party lines blur. With party lines blurred and more independents and third-party candidates being elected the two-party system (in fact, the political party system) ends. When political parties lose majorities, they lose the ability to gerrymander and gain an incentive to undo gerrymandering.
            </Col></Row><p /><br />
            <Row ><Col xs="12" sm="12" md="12" className="main">THERE IS NEVER A REASON TO SUPPORT RANKED CHOICE VOTING.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">There is no reason to ever choose RCV over score voting–any kind of score voting. Approval voting, STAR Voting, simple score voting, any variation delivers on all the promises of score voting and vastly outperforms RCV.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">RCV is unnecessary at best, and dangerous at worst. The only people who should advocate for it are employees of the Democratic National Committee and the Republican National Committee. Only those who profit from our broken nation should advocate for RCV. It is dangerous as it makes things look better (voters research more candidates, yea!) without making them better (political parties still control the ballot, ah poop!).


            </Col></Row><p />


        </Container>
        </>
    )
}

export default Blog0049;
