import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0009 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.02.26</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>It was a full as expected so not much progress.  The video was redone and my article was published.  However, I only now have realized that the audio does not sync to the video when you make a video from PowerPoint.  More concerning was the fact a thoughtful reader of the article noted (yet another) massive oversight on my part, again, which means I needed to pull the article, again.  And change the video.  And change a chapter in the book.  Better to know now than later.  Also progress on the cover video.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>Still waiting.  But should have a path forward prior to the next update.  But now I have a chapter to rewrite anyway.  It won’t be difficult, but I will need to look at the focus and function of the piece within the larger work. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>Nothing further here.  If the cover video continues to progress, I’ll see how and if it can be highlighted.  The cover video is a short 30 sec spot that tries to set the stage for what the movement is about—fixing the societal division of the country.  This was the first look and it has a way to go.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>Given my gross errors in getting facts right in my last article, I’m going to be very cautious moving forward.  I don’t have much credibility, and I don’t want to waste effort on inaccuracies, so more thought on the advantage that Approval Voting would have for the Republican Party. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>The path is clear.  Fix the video, fix the book, and Googlefying the website and upgrading the Swarm Age program.  Full week, but hopefully I’ll have the time.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0009;
