import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0038 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.09.24</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Ballad of Ashli Babbitt</strong><p />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                For Ashli Babbitt, the sixth of January was a heartfelt mission to stop "the steal" and restore democracy.  Ashli had served in our nation's armed forces, deployed eight times to our wars in Iraq and Afghanistan, and mentored our junior airmen. Friends and family described her as thoughtful and kind. She adopted retired military working dogs so they could spend their last years in comfort, for crying out loud.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Ashli was concerned about the problems in her community which she felt her representatives were either ignoring or manipulating for their political gain. Feeling powerless to improve things, she became angrier and angrier. She began to consume a steady stream of political tirades and QAnon posts that preached stories of a satanic cabal that had masterminded "the steal."

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Ashli had sworn an oath to support and defend the constitution of the United States against all enemies, foreign and domestic. And now her environment and her media told her who those enemies were. Ashli was the kind of person who would act to try to make things better; literally, an activist, so she acted. On the sixth of January, Ashli Babbitt was shot dead while trying to enter the U.S. Capitol Building through a window shattered by that mob.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">When you look at Ashli Babbitt, what do you see? a radical extremist?  a patriotic martyr? I would offer that Ashli is neither. Instead, she is an output, an inevitable result of our divided country. And she is not the only one.   My wife and her brother no longer speak to each other due to their political differences.  Someone I deeply respect told me about a plot by one of our political parties to reduce the size of our population by culling our elderly and babies.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Think about what you have heard from your friends and family, and think about what you have seen in your news and media.  Does it feel to you like we might be approaching the end of our great democratic experiment?

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I served as an officer in the United States Navy for 28 years. My job was then, as it is now, to solve problems by addressing the system that causes them. I, like Ashli, swore an oath to support and defend our constitution against all enemies, foreign and domestic. And I, like Ashli, believe we are facing a real enemy. But this enemy is not Mitch McConnell, or Nancy Pelosi, or Donald Trump, or ANTIFA, or Black Lives Matter, or the Boogaloo Boi's.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">After 28 years of service, I learned that sometimes the enemy isn't a person or a group of people, sometimes it's a system.  A system that works to compel Ashli Babbitts, spread conspiracy theories, and break apart families. A system that creates gridlocked government, gerrymandered districts, and disenfranchised voters.  A two-party system that divides our society.  But no one intentionally created this system. Instead, it is an unavoidable consequence of our current voting method.

            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0038;
