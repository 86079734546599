import LevelFinder from "./common/levelFinder";
import FindStateName from "./common/findStateName";
import TweetCheck from "./common/tweetChecker";

import axios from "axios";

const BE_URL = process.env.REACT_APP_BE_URL;
const url = `${BE_URL}/api/tweet`;

const SendTweets = async (
  state,
  county,
  precinct,
  levelValues,
  movementHash
) => {
  const response1 = TweetCheck(levelValues[0]);
  if (response1 === true) {
    const level = LevelFinder(levelValues[0][0]);
    const message = `The United States has reached ${movementHash} supporter level ${level}. Congratulations America! Nothing can stop you. #All50ScoreBy2024 https://maximumvoting.org`;
    try {
      const response = await axios.post(url, {
        message,
      });
      return { data: response.data };
    } catch (e) {
      return { error: (e.response && e.response.data) || e.message };
    }
  }

  const response2 = TweetCheck(levelValues[1]);
  if (response2 === true) {
    const encodeState = encodeURI(FindStateName(state));
    const level = LevelFinder(levelValues[1][0]);
    const message = `In the state of ${encodeState}, ${movementHash} supporters have reached supporter level ${level}. Keep it going! How is your state doing? #AllScoreBy2024 https://maximumvoting.org`;
    try {
      const response = await axios.post(url, {
        message,
      });
      return { data: response.data };
    } catch (e) {
      return { error: (e.response && e.response.data) || e.message };
    }
  }

  const response3 = TweetCheck(levelValues[2]);
  if (response3 === true) {
    const level = LevelFinder(levelValues[2][0]);
    const message = `In ${county} county, ${state}, ${movementHash} supporters have reached supporter level ${level}. This county rocks! How is your county doing? #all50scoreby2024 https://maximumvoting.org`;
    try {
      const response = await axios.post(url, {
        message,
      });
      return { data: response.data };
    } catch (e) {
      return { error: (e.response && e.response.data) || e.message };
    }
  }

  const response4 = TweetCheck(levelValues[3]);
  if (response4 === true) {
    const level = LevelFinder(levelValues[3][0]);
    const message = `In ${county} county, ${state}, voting zone ${precinct}, ${movementHash} supporters have reached supporter level ${level}. How is your voting zone doing? https://maximumvoting.org`;
    try {
      const response = await axios.post(url, {
        message,
      });
      return { data: response.data };
    } catch (e) {
      return { error: (e.response && e.response.data) || e.message };
    }
  }
};
export default SendTweets;
