import React, { useState } from "react";

import "./StaticTitleBar.css";

export default function AffliatedButton() {
  const [drop, setDrop] = useState(false);

  return (
    <div>
      <button
        className="btn btn-default affiliated-link"
        style={{ paddingTop: "15px" }}
        onClick={() => setDrop(!drop)}

      >
        <a href="/resources" target="_blank" rel="noopener noreferrer">
          <span style={{ color: "#5A8A8E ", fontFamily: "Gill Sans MT", fontSize: "20px" }}><b>Resources</b></span>
        </a>

        {!drop && (
          <span className="tooltiptext">click for more resources and information</span>
        )}
      </button>{" "}
    </div>
  );
}
