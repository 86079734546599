import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0046 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.11.19</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>Redistricting Division Solved</title>
                <strong>How to avoid division over redistricting</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Having listened to the session of the <a href="https://www.leg.state.nv.us/Video/">Committee to Conduct an Investigation into Matters Relating to Reapportionment and Redistricting in Nevada</a> that occurred on the 23 of October personally, I fear that the words of Casey Lynch, “there is no neutral way to do it,” will prove prophetic.  Despite the glaring success of 2011’s redistricting, I do not think there is any possible way for Chair Miller and her dedicated redistricting team to develop and choose a redistricting map that doesn’t create widespread protests throughout the state.  It isn’t that Nevadan’s are evil, blind, or stupid—because they aren’t—but we live in such a politically charged state, any map chosen will be seen as an intentional political manipulation.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                I would offer that the committee and Nevada do have two options that would allow the state to move smoothly through a redistricting in the future.  One of those options could solve this problem of redistricting ire today.  The other makes this problem obsolete entirely.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Numerous mathematical formulas exist today that we can use to divide up a state into equally populated districts.  <a href='https://rangevoting.org/SplitLR.html'>Dr. Warren Smith’s split-line algorithm</a> represents one option, but UNLV and UNR mathematics departments can certainly produce many satisfactory alternatives.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                In each of these algorithms, the formula starts by dividing the state equally from East to West.  But it could make the initial division from North to South or along any of the other 358 degrees. And that’s the secret. The committee and the legislature would discuss, take public comment on, and vote on the formula that we would use, but the initial direction of the first cut would be randomly chosen only after the law was signed (maybe we would have a big redistricting party where one from 360 Keno balls is chosen).
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                We, the voters, our representatives, and everyone else would all find out, at exactly the same time, what the new divisions are (again, maybe a party-style event could happen?).  Granted, this method would likely not keep together like-communities; Senate districts would not align with House districts; there would be no guarantee that some districts would not be almost wholly Republican or Democrat (or non-partisan); or that minorities would be equally dispersed.  However, this method would guarantee freedom from intentional governmental (or any other) manipulation.  In short, we could trust its fairness.  In a state of gamblers, we take our chances and accept the result as long as we know the table is fair.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                The second, better, and more lasting method though is simply to end our political division.  That sounds crazy until you understand what is causing it.  It isn’t a person, party, media, foreign power, or any of the other list of usual suspects.  Those all come and go, but division has been with us since the beginning—a horrific, vicious, civil war level division over much the same arguments.  As a retired military officer whose job it was (and is) to solve problems by looking at the underlying system that causes them, I have concluded that our division stems from our minimal voting method.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Plurality voting (and its twin cousin ranked-choice voting) force voters into candidate camps by restricting each voter to ultimately give their vote to one candidate.  That is natural, unavoidable division right there and forces primary candidates to the extremes of their party.  A max voting method, where every voter can give their full opinion on each candidate in the field of candidates, offers a disproportional advantage to candidates who align their political platforms with the majority opinion, the true will of the people.
            </Col></Row><p />


        </Container>
        </>
    )
}

export default Blog0046;
