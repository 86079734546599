import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0004 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.01.22</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>I continue to face the challenge of resourcing the position of the developmental editor.  I improved the webtool <span><a href='https://scorevoting.swarmage.net'>https://scorevoting.swarmage.net</a></span> so that it now shows who has recently joined.  I am working on smaller messaging efforts. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>I currently wait for a decision on finances.  I continue to have 100% confidence in the editor I have chosen and the direction in which she would help take the book.  However, there is a cost to bear, and I have limits.  As I wait for a decision on the availability of funds, which will probably come with the turning of the month, I will continue to make plans, branches, and sequels to support the effort.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>I secured the website ScoreVoting.US and have pointed it (for the time being) to <span><a href='https://scorevoting.swarmage.net'>https://scorevoting.swarmage.net</a></span>.  I plan to wait to build it as part of the marketing for the national push.  I wait for approval from my employer as to whether or not I can initiate a non-profit. Typically there is a three-week to a month turn on such decisions, so maybe sometime in February, I will hear. Doing so opens the way for donations and greater legitimacy.  If my employer says no, I have already developed contingency plans. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>The focus became New Hampshire this week.  Coming up for discussion is HB 505, which would institute Approval Voting.  For fans of STAR Voting and noters of Approval Voting's limitations, never forget, any form of score voting brings candidates to the center-majority, forces them to listen to their voters, and places the political agenda (of New Hampshire in this case) in the voters' hands.  The nuance, worry over "strategic"/" tactical" voting comes in a distant second place.  New Hampshire has scheduled the Zoom meeting discussions for the 29th of January.  <span><a href='http://gencourt.state.nh.us/bill_status/bill_docket.aspx?lsr=0321&sy=2021&txtsessionyear=2021&txtbillnumber=hb505&sortoption=&q=1'>Hearing Announcement.</a></span>
                Free seminar from Heroic Public Speaking, 1-2 Feb: <span><a href='https://heroicpublicspeaking.com/'>https://heroicpublicspeaking.com/</a></span>.
                I'm working on a new video to explain the core benefit of score voting systems—pushing candidates to the political center-majority.
            </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>Watch what happens in New Hampshire, hoping and praying for success.  Complete the first draft of the script for the new animated short. Complete additional drafts of the business card-sized handout. Start training on ARCGIS, which may be a way to improve the webtool.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0004;
