import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import * as constants from "./const";
// import Book from ".././containers/book";
import "./StaticTitleBar.css";
import AffliatedButton from "./affiliatedButton";
import SupporterButton from "../dynamicHeader/supporterButton";

// import BookBase from "./../../bookBase";
// import {  /* getSessionuserName,*/ removeSession,
// } from "./../../services/session";

export default class StaticTitleBar extends Component {
  // constructor(props) {
  //   super(props);
  // }

  buttonChoice() {
    if (!this.props.session) return <SupporterButton />;
    else return <AffliatedButton />;
  }
  render() {
    return (
      <Row className="static-title-bar">
        <Col
          xs="12"
          sm="12"
          md="12"
          className="static-column highlighted-title tag-line"
          style={{fontSize: '250%', justifyContent:'center'}}
        >
          {constants.USA_VOTING}
         

        </Col>
        <Col
          xs="12"
          sm="12"
          md="12"
          className="static-column highlighted-title tag-line"
          style={{fontSize: '175%', justifyContent:'center'}}
        >
                   {/*constants.EMPOWERING*/}

        </Col>
        {/*<Col           xs="12"
          sm="12"
          md="4"
          className="static-column highlighted-title tag-line-second"
        >
          <a href="/book" target="_blank" rel="noopener noreferrer"><br/>
            Read our draft book
          </a>
          
        </Col>{" "}
        <Col
          xs="12"
          sm="12"
          md="4"
          className="static-column highlighted-title tag-line-second"
        >
          <a
            href="https://twitter.com/MaxVoting"
            target="_blank"
            rel="noopener noreferrer"
          ><br/>
            "Watch-us-grow" tweets
          </a>
          
        </Col>
        <Col
          xs="12"
          sm="12"
          md="4"
          className="static-column highlighted-title tag-line-second"
        >
          <a href = "mailto:commonsenseforunitingamerica@gmail.com?subject = Feedback&body = Message">
          <br/>Upgrade to Activist (write us)
          </a>
          {/*<a
            href="https://discord.gg/TF9Tpp8m2c"
            target="_blank"
            rel="noopener noreferrer"
          ><br/>
            Meet up on Discord
          </a>
          
        </Col>
        <Col
          xs="12"
          sm="12"
          md="4"
          className="static-column highlighted-title tag-line-second"
        >
          <a href="/FAQs" target="_blank" rel="noopener noreferrer"
          ><br/>
            FAQs
          </a>
          
        </Col>
        <Col
          xs="12"
          sm="12"
          md="4"
          className="static-column highlighted-title tag-line-second"
        >
          <a href="/press" target="_blank" rel="noopener noreferrer"
          ><br/>
            Press Room
          </a>
          
        </Col>
        <Col           xs="12"
          sm="12"
          md="4"
          className="static-column highlighted-title tag-line-second"
        >
          <a href="/resources" target="_blank" rel="noopener noreferrer"><br/>
            Resources
          </a>
          
        </Col>{" "}
        {/*<Col
          xs="12"
          sm="12"
          md="4"
          className="static-column highlighted-title tag-line-second"
        >
          <a
            href="https://rangevoting.org"
            target="_blank"
            rel="noopener noreferrer"
          ><br/>
            Resources
          </a>
          {constants.EMPOWERING}
        </Col>*/}
        
        <Col xs="12" sm="12" md="12" className="static-column" style={{paddingBottom:"30px"}}><br/>
          {this.buttonChoice()}
        </Col>
        <Col
          xs="12"
          sm="12"
          md="12"
          className="static-column highlighted-title tag-line"
          style={{fontSize: '155%', justifyContent:'center',paddingBottom:"60px", color:"#C7543D"}}
        >
                   You get to choose!   <br/>
                   Fight to survive in a politically divided society with a partisan government, or<br/>
                   experience calm progress in a united society with a voter-led goverment.<br/><br/>
                   <span style={{color:"#5A8A8E"}}>First,</span> take 58 seconds to register. <br/> 
                   <span style={{color:"#5A8A8E"}}>Next,</span> tell your friends. <br/>  
                   <span style={{color:"#5A8A8E"}}>Then,</span> consider joining our team.
                        </Col>
      </Row>
    );
  }
}
