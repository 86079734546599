import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0027 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.07.09</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                9 – 15 July:  get Ohio on the <span><a href='https://scorevoting.swarmage.net'>scorevoting.us</a></span> scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>Focused again on the script.  Had a first run with an audience and it went very well, but I will need to change the tone.  Also, started some planning some changes to the Swarm Age system to make sign-up easier.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Newsletter. </strong>No news.  Goal was for a second article this week, but we are still in the nascent stages of getting this done.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>No new progress on the org—no new joiners this week.  I’m adjusting the tone of the presentation.  As a result, it may see few sign-ups out of the gate, but I don’t know.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>For the presentation I am very focused on keeping the discussion educational.  It is too easy for me to become confrontational when I become advocational. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>On next Sunday, I will be presenting so everything is about practicing and getting down the script.  Maybe another practice performance during the week.  I am also going to try to fix the Swarm Age tool so that sign up is easier.  Goal is to have that done this weekend. </Col></Row>

        </Container>
        </>
    )
}

export default Blog0027;
