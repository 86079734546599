import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0062 = () => {
    const [image] = useState(logo);

    return (
        <>
            <Container>
                <Row><Col>
                    <img className="image" src={image} alt="" />
                </Col>
                </Row>
                <Row><Col className="date">22.03.26</Col></Row>
                <p />
                <Row ><Col xs="12" sm="12" md="12" className="title">
                    The Weekly Heart Beat
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><title>#DivisionFreeVoting in Nevada</title>
                    <strong><h1>Division Free Voting in Nevada is Go!</h1></strong><p />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">This week we passed the 15th business day for public complaint to Nevada state constitutional initiative C-05-2022 which institutes #DivisionFreeVoting.  The means we can now gather signatures to get the initiative on the ballot.  But before we do that, we want to ensure we have the supporter base.  If you would like to support ending political division in Nevada (or in the United States in general) register as a supporter on this site!
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>New Video</h2>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">In addition to this milestone, we also released a new <a href="https://www.youtube.com/watch?v=OfBNUdk5TmU">video</a> that explains why #RankedChoiceVoting doesn't end political polarization and division.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Our Team Expands</h2>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Our swarm leadership team has doubled in size, but we still have room for you to join. Just send us an <a href="mailbox@CommonSenseForUnitingAmerica.org"> email at Mailbox@CommonSenseForUnitingAmerica.org</a>.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Future Looks Bright</h2>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">This upcoming week you can expect to see another video on our <a href="https://www.youtube.com/channel/UCqHP9kv-hN7E-AvU9bEq06Q">channel</a> and another article out on our <a href="https://bit.ly/CmnSens">substack site"</a>.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>How to Help</h2>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Here are a few things you can do to participate in the nationwide civic wellness campaign to end political division in the United States.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">1.	<a href="https://bit.ly/MaxVote">Register your</a> support for ending political division.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">2.	<a href="Mailbox@CommonSenseForUnitingAmerica.org">Join our team!</a>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">3.	Re-transmit and interact with our posts.  Here are a few of our media streams:
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">a.	<a href="https://www.youtube.com/channel/UCqHP9kv-hN7E-AvU9bEq06Q">Youtube</a>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">b.	<a href="https://www.facebook.com/MaximumVoting">Facebook</a>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">c.	<a href="https://twitter.com/MaxVoting">Twitter</a>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">d.	<a href="CommonSenseForUnitingAmerica.substack.com">Substack</a>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">4.  Learn more about MaxVoting. Check out our draft book on this site.  If you want the real geeky stuff, check out Dr. Warren Smith's <a href="https://rangevoting.org">rangevoting.org</a> site.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Progress With Each Small Step</h2>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Remember that this is a nationwide civic wellness <i>marathon</i>, we progress through any little step we take. With continuous small actions, our success is inevitable.

                </Col></Row><p />
            </Container>
        </>
    )
}

export default Blog0062;
