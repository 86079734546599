import React from 'react'
import Blog0001 from "./blog0001"
import Blog0002 from "./blog0002"
import Blog0003 from "./blog0003"
import Blog0004 from "./blog0004"
import Blog0005 from "./blog0005"
import Blog0006 from "./blog0006"
import Blog0007 from "./blog0007"
import Blog0008 from "./blog0008"
import Blog0009 from "./blog0009"
import Blog0010 from "./blog0010"
import Blog0011 from "./blog0011"
import Blog0012 from "./blog0012"
import Blog0013 from "./blog0013"
import Blog0014 from "./blog0014"
import Blog0015 from "./blog0015"
import Blog0016 from "./blog0016"
import Blog0017 from "./blog0017"
import Blog0018 from "./blog0018"
import Blog0019 from "./blog0019"
import Blog0020 from "./blog0020"
import Blog0021 from "./blog0021"
import Blog0022 from "./blog0022"
import Blog0023 from "./blog0023"
import Blog0024 from "./blog0024"
import Blog0025 from "./blog0025"
import Blog0026 from "./blog0026"
import Blog0027 from "./blog0027"
import Blog0028 from "./blog0028"
import Blog0029 from "./blog0029"
import Blog0030 from "./blog0030"
import Blog0031 from "./blog0031"
import Blog0032 from "./blog0032"
import Blog0033 from "./blog0033"
import Blog0034 from "./blog0034"
import Blog0035 from "./blog0035"
import Blog0036 from "./blog0036"
import Blog0037 from "./blog0037"
import Blog0038 from "./blog0038"
import Blog0039 from "./blog0039"
import Blog0040 from "./blog0040"
import Blog0041 from "./blog0041"
import Blog0042 from "./blog0042"
import Blog0043 from "./blog0043"
import Blog0044 from "./blog0044"
import Blog0045 from "./blog0045"
import Blog0046 from "./blog0046"
import Blog0047 from "./blog0047"
import Blog0048 from "./blog0048"
import Blog0049 from "./blog0049"
import Blog0050 from "./blog0050"
import Blog0051 from "./blog0051"
import Blog0052 from "./blog0052"
import Blog0053 from "./blog0053"
import Blog0054 from "./blog0054"
import Blog0055 from "./blog0055"
import Blog0056 from "./blog0056"
import Blog0057 from "./blog0057"
import Blog0058 from "./blog0058"
import Blog0059 from "./blog0059"
import Blog0060 from "./blog0060"
import Blog0061 from "./blog0061"
import Blog0062 from "./blog0062"
import Blog0063 from "./blog0063"
import Blog0064 from "./blog0064"
import Blog0065 from "./blog0065"
import Blog0066 from "./blog0066"
import Blog0067 from "./blog0067"
//write blog using Blog001 as a guide, use word for spell check
//import to above
//add to string below
//change which Blog is used on index.js front page.
// also ensure button to get to past blogs is uncovered.
const blogs = [<Blog0067 />,<Blog0066 />,<Blog0065 />, <Blog0064 />, <Blog0063 />, <Blog0062 />, <Blog0061 />, <Blog0060 />, <Blog0059 />, <Blog0058 />, <Blog0057 />, <Blog0056 />, <Blog0055 />, <Blog0054 />, <Blog0053 />, <Blog0052 />, <Blog0051 />, <Blog0050 />, <Blog0049 />, <Blog0048 />, <Blog0047 />, <Blog0046 />, <Blog0045 />, <Blog0044 />, <Blog0043 />, <Blog0042 />, <Blog0041 />, <Blog0040 />, <Blog0039 />, <Blog0038 />, <Blog0037 />, <Blog0036 />, <Blog0035 />, <Blog0034 />, <Blog0033 />, <Blog0032 />, <Blog0031 />, <Blog0030 />, <Blog0029 />, <Blog0028 />, <Blog0027 />, <Blog0026 />, <Blog0025 />, <Blog0024 />, <Blog0023 />, <Blog0022 />, <Blog0021 />, <Blog0020 />, <Blog0019 />, <Blog0018 />, <Blog0017 />, <Blog0016 />, <Blog0015 />, <Blog0014 />, <Blog0013 />, <Blog0012 />, <Blog0011 />, <Blog0010 />, <Blog0009 />, <Blog0008 />, <Blog0007 />, <Blog0006 />, <Blog0005 />, <Blog0004 />, <Blog0003 />, <Blog0002 />, <Blog0001 />];

export function getBlogs() {
    return blogs
};


export default getBlogs;

