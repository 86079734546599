import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import ballimg from "./blogImg/ballroomGood.png";
import bedimg from "./blogImg/bedroomGood.png";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0036 = () => {
    const [image] = useState(logo);
    const [b1] = useState(bedimg);
    const [b2] = useState(ballimg);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.09.10</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Bedroom or Ballroom, where do you want to go?</strong><p />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Imagine a hotel with a number of rooms, each assigned to a candidate. You are in the hallway.  If you wish to participate in the “vote” you need to go into one of the candidate’s rooms. The goal of the candidate is to have the most people in their room. That’s how plurality voting works.  We divide ourselves up, and each candidate tries to entice the most voters into their room.  What is the best way for them to do that?
            </Col></Row><p />
            <Row><Col>
                <img className="imageInternal" src={b1} alt="" />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Quick side-story. So there I am walking down the street and my friend Pat comes running up and says, “Hey Teddy Baby, I just heard about this great deal where you could be making another 10 dollars a day you just have to follow these few simple steps.”  Well, I’m already making 100 dollars a day, and I have stuff to do, so I say, thanks but I’m good.
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                So, I keep walking down the street and then my friend Sam comes running up and says, “Hey Teddy Baby, I just heard about how you’re losing 10 dollars a day, but you can stop that from happening.  You just have to follow these few simple steps.”  Well, I don’t want to be losing my money. That’s my money! so I’m inclined to do what Sam says.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                It’s called <a href="https://journals.sagepub.com/doi/abs/10.1177/019251296017002004">prospect theory</a> and what researchers such as Jack Levy of Rutgers University have demonstrated is that people more heavily weight losses with respect to comparable gains. The two situations are the same, but people are more likely to act in the second, where additional ten dollars is viewed as a loss.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Candidates have a better chance of attracting voters, not by explaining what the voters gain if that candidate wins, but rather, what the voter loses if the other candidates win. Making your room the fullest is all about attacking your opponents and what they and their supporters stand for.  Sound familiar?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Back to the hotel.  A winning hotel room may only consist of 29% of the total number of people in rooms.  If that happens, we don’t know how the other 71% of the people in rooms feel about that candidate.  This gal from a losing candidate’s room, may love the winning candidate or hate them, but we only know that the winning candidate wasn’t her first choice given the choices available.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                To solve this perceived lack of a public mandate, plurality elections often include a threshold, a minimum-percentage-of-the-total-vote that a candidate must achieve to win the election, such as 50% of the people in rooms must be in the room of a candidate before they can be declared the winner.  If no candidate achieves that threshold, a runoff election is triggered.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                A runoff is simply a re-running of the election after eliminating one or more of the rooms.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                So, we close off the room with the fewest voters in it, clearing them out and asking them to find another room. They go to another room, or maybe stay in the hall, not participating.  And we check the totals again.  If the threshold is not met, we keep closing off rooms (executing runoffs) until a candidate does meet the threshold.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                We can automate that process and avoid the cost of the runoff elections by asking voters to give their preferences for candidates when they vote the first time.  If their first choice is eliminated, who do they want instead? Et cetera.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                When we do this, we call it instant runoff voting or ranked-choice voting, or preferential voting or top four or top five voting, it’s all the same.  But are we not still requiring the population to divide themselves into rooms? Are we not still demanding the candidates attract the most people to their room?  Is this not the same game? How can we expect less division and less antagonism if we are playing the same game?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Why are we divided?  Isn’t it obvious?  Why are we polarized?  Isn’t it obvious? Why doesn’t ranked-choice voting fix this? Isn’t it obvious?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                So, let’s get out of the bedroom and go to the ballroom.  As all the people enter the ballroom, we ask them what their major issues are and what should be done about it.  By listening to the voters, we can start to form an understanding of the ten or so issues that this group is most concerned about and what they think needs to be done.  For each issue, we can then have them stand in lines with each line representing a degree of change from “yes, always” to “no, never.”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                For example, if we asked people, “should we institute more gun control?” some would say, “yes, always” and stand in the line on the far left, others would say “no, never” and stand in the line on the far right, but most would say, “maybe, sometimes, it depends” and they would stand in some line most closely aligning to how much and what type of change in society they would like to see.  In our gun example, this may include types of firearms sold, who can sell them, to whom they can be sold, how they can be modified, quantities that can be possessed, how they must be stored, etc.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Once all the voters are in their lines, the candidates come in and BASED ON WHAT THE VOTERS WANT pick a line, a campaign platform plank, to support.  In addition to gaining support from that specific line, they will also have the support of a number of lines to its left and right—the political reach of their platform.
            </Col></Row><p />
            <Row><Col>
                <img className="imageInternal" src={b2} alt="" />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                If we were to do this, where do you think the candidates would go and stand?  Where their political party told them to stand? Where the special interests told them to stand? Or where they will have the most voters voting for them?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                If we do this ballroom dance issue after issue, we end up with candidates having political platforms that align with the majority opinion of the voters.  Which is what we want!
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                With bedrooms, we go to the candidates.  With ballrooms, the candidates come to us.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Maximum voting, where every voter can give points to every candidate and the candidate with the most points wins, sets up the ballroom and ensures the voters are driving the political agenda, not the candidates.  This happens because every candidate can gain points from every voter, so they have an incentive to align with what the most voters want.  Welcome to the ballroom!
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Bedroom or ballroom is your choice, but do you want to be chasing candidates or have them chasing you?
            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0036;

