import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0047 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.11.26</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>Why Min Voting Methods Create Division</title>
                <strong>Why Min Voting Methods Create Division</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Anytime a candidate wins a 51% majority, they have demonstrated two things. 1) they can completely ignore the 49% minority and still be elected and 2) they cannot ignore the 51% majority if they hope to be re-elected.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Imagine this room, divided in half, exactly half of you are on the left, and half on the right.  Whichever group holds the majority, holds total control over what happens in this country over the next two years.  Think about what you want, what you want for this country to do about climate change, COVID, immigration, infrastructure, taxes, trade, abortion, whatever.  If you win, you get it. If that other side wins, you not only don’t get it, but, you get, “lasting harm to our country.”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">One person moves from their side to your side.  You win.  Now, you’re moving forward. Now, they’ll see that you were right all along, even though you are doing the exact opposite of what they want. But if just two people, move from your side to the other side, you lose everything.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">What are you willing to do to keep control? The question isn’t whether or not you should gerrymander, the question is will gerrymandering alone be enough.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Apparently not. Two years pass.  Two of your group, went to the other side.  The country is doing everything you disagree with, tearing down everything you built, knowing that you’ll see that they right all along.  What are you willing to do, to get that power back, and shove it down their stupid, gloating throats?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Min voting, regardless of what you call it, because it creates majorities and mninorities is always an all or nothing game that divides us.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But what if that 51% comes from the middle, neither on the right nor the left?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Fair question, let’s answer it by starting with you.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Right here, right now, in this room (assuming this is an audience at a TED talk), there is a single majority opinion on any and every issue to include the simple ones like, abortion. "Wait, what, we never agreed to anything."  You don’t have to.  "But we didn’t even discuss it." You don’t need to.  It already exists, but no one knows where it is.  To find it, someone would need to listen to each and every one of you (or a statistical sampling), to understand what you actually believed.  Then they could find the majority opinion, the MO.  But that is not the nation’s majority opinion, or California’s, or Texas’ or Chicago’s, it would be the MO for you in this room, at this moment, and only the one who did the listening would know what it is.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But for this demonstration I need you to see it as well.  So we are going to magically change the theater. now each aisle represents a specific political viewpoint, and you only sit in an aisle that matches your politically view on this issue.  On that far side, would sit people who say, abortion? No, Never.  On that far side, people who say abortion, yes always. Don’t actually move, it’s an example, And as we flow from one extreme to the other we pass through all the shades of “maybe, sometimes, it depends.”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I then as the candidate who could see where the majority opinion was and place my political platform by standing in front of the aisle that reflects it.  The people in that aisle would love me, and the people sitting in the next aisles, would probably also support me, and maybe that support would continue a few aisles out on either side say 5 each way.  Beyond that, they think I’m an idiot and currently have not incentive to vote, after all, I am the only candidate.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Oh my god! What just happened? Another candidate showed up in the aisle right next to me.  Gosh, should have seen that coming.  Cheeky git just took away half my voters.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But wait, there are more people out beyond the 5th aisle from me.  If I slide just one aisle over, my political platform will be close enough to those people for them to join my faction, and the ones I slid off of in the center are now exactly half-way between me and slim here, so they don’t have a reason to desert me.  And then I’ll have the most voters and win.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But wait, he’s doing the same thing, he slid toward the fringe, now he has the most.  But I can again slide to the fringe, he slides to the fringe, I slide to the fringe, he slides to the fringe, and freeze.  if I move further out, I’m more than 5 aisles from the majority opinion, too far for them to want to support me and I will lose more voters in the middle than I can gain from the fringe.  But if I slide back toward the MO, I lose more voters from the fringe than I gain in the middle. I lose more voters by moving either left or right.  Stalemate, we flip a coin.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But who’s this? What does she think she’s doing, Oh, she’s joining the election, right between us at the majority opinion—fool.  I still get half the voters between me and her, and so does that jerk on the other side.  She has no chance to win from there, she’s too close to us. Thanks for coming doll face, don’t let the door hit ya where the good lord split ya.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">What, you want to make this a Ranked Choice Voting election? Sure. Gosh, let me see.  Who has the fewest first place votes, oh, look it’s you, again.  Get off the stage moron!  Newbies am I right?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">In min voting methods the majority faction will be on either the right or the left, just like today. In min voting, candidates need to find a balance between being away from other candidates and trying to be near the largest number of voters. Min voting, of any kind, creates two power positions, one to the left of the majority opinion and one to the right, and divides us between them.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Min voting always, always, always works to divide us, it ensures we have majorities fighting minorities.

            </Col></Row><p />



        </Container>
        </>
    )
}

export default Blog0047;
