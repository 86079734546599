import React from 'react';

class Ads extends React.Component {
    render() { 
        return <div>
            google.com, pub-9199808665660381, DIRECT, f08c47fec0942fa0
            </div>;
    }
}
 
export default Ads;