import React, { Component } from 'react';
import Pagination from "../src/components/common/pagination";
import getBlogs from "./blogs/allBlogs";
import { Container } from "reactstrap";
import { Row, Col } from "reactstrap";
import { paginate } from './utils/paginate';


class HeartBeat extends Component {

    state = {
        allBlogs: getBlogs(),
        pageSize: 4,
        currentPage: 1
    }

    handlePageChange = (page) => {
        this.setState({ currentPage: page })
    }

    renderBlogs() {
        const { allBlogs, currentPage, pageSize } = this.state;
        if (allBlogs.length === 0) return <p>There are no blogs yet written.</p>;
        const blogs = paginate(allBlogs, currentPage, pageSize);
        return (
            <>
                {blogs.map(blog =>
                    <Row key={blog}>
                        <Col xs="12" sm="12" md="12">
                            {blog}
                        </Col>
                    </Row>)
                }
            </>)
    }


    render() {
        const { allBlogs, pageSize, currentPage } = this.state;
        const count = allBlogs.length;
        return (
            <>
                <Container>
                    {this.renderBlogs()}
                    {count === 0 && 'Please write a blog'}
                    <Row style={{ marginTop: '30px', justifyContent: 'center' }}>
                        <Pagination
                            blogCount={count}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onPageChange={this.handlePageChange} />
                    </Row>
                </Container>
            </>
        )
    }
}

export default HeartBeat;