import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0061 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">22.03.19</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>Common Sense for Uniting America grows</title>
                <strong>Common Sense for Uniting America, continues to grow</strong><p />

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Faster and faster, more and more</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"> Another week of success for Common Sense.  As we watch Ukraine continue to successfully fight onward against Russia, we, too, continue forward.  We had another <a href="https://thenevadaindependent.com/article/proposed-nevada-ballot-measure-calls-for-using-star-ratings-to-elect-candidates">article</a> written about us--this one went beyond just the press release and it is clear that Ms. Eom has done her research. We will also be putting out a new video on our <a href="https://www.youtube.com/channel/UCqHP9kv-hN7E-AvU9bEq06Q">channel</a>, and a new article on our <a href="https://commonsenseforunitingamerica.substack.com">substack</a>, so be looking out for those.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We have also expanded the ranks of our national team with even more incredibly gifted people. Soon we should also have our Nevada Nonprofit established within the month.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>We are just about to begin</h2>

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">When political tensions rise over the war in Ukraine or the rising price of gas, it is so important for everyone to remember that this is not the end of the great democratic experiment, it is just about to begin.  The United States has always been divided, from the first elections in 1784 to today, but this division has not been about who we are or what has been happening around us, but it has been about a system.  A system that pushes us to divide.  It pushed us to divide in 1784, it pushed us to divide in 1860, it pushed us to divide in 1910, it pushed us to divide in the 1960s, and it pushes us to divide today.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>“How much?” over “Which One?”</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Our simple act of casting a ballot divides us into factions, factions that then battle to become the biggest faction.  Majority rule creates a minority ignored, and Americans don't like to be ignored.  Those were not the winners marching on Washington D.C. in 2017, those were not the winners attacking the Capitol Building in 2021. When we stop asking voters, "which candidate do you to represent you?" and start asking, "how much does each candidate represent you?" we replace majority rule with majority opinion rule.  Every voice contributes to the majority opinion, every voice is heard when we use max voting methods.  Max voting methods are any voting method that allows all voters to rate all candidates, highest rated candidate wins--think Amazon ratings for candidates.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">People are not the problem, it's the system, and when we change it, we begin the true golden age of democracy.  We have no idea how great America can be, we have never seen America at its best, but we can if we have the courage to try, if we implement Max Voting methods nationwide.  It's just common sense.  It's just common sense for Uniting America.
            </Col></Row><p />


        </Container>
        </>
    )
}

export default Blog0061;
