import * as types from '../constants'

import { getCurrentLevel } from '../../containers/MainPage/const'

const initialState = {
    level: "nation",
    loading: false,
    currentSelInfo: {
        nation: "USA",
        state: "",
        county: "",
        precinct: "",
    },
    selStatisticInfo: {
        nation: null,
        state: null,
        county: null,
        precinct: null,
    },
    subordinates: [],
    staticInfo: {},
    curBeltLevel: 0,
    stateScreenShow: false,
    mapSelectedView: 1,
    percentViewMode: 0,
}

const voteReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SCREEN_SHOW:
            return {
                ...state,
                stateScreenShow: action.data
            }
        case types.SET_LOADING:
            return {
                ...state,
                loading: action.data,
            }
        case types.SET_CUR_BELT_LEVEL:
            return {
                ...state,
                curBeltLevel: action.data
            }
        case types.SET_PERCENT_VIEW_MODE:
            {
                const { selStatisticInfo, level } = state
                const newPercentMode = action.data

                const percent = !newPercentMode ? selStatisticInfo[level].headPercent : selStatisticInfo[level].recentPercent;
                return {
                    ...state,
                    percentViewMode: action.data,
                    curBeltLevel: getCurrentLevel(percent),
                }
            }
        case types.SET_LEVEL_SUCCESS:
            {
                const { level, response, innerText } = action.data
                const { headVoteTotal, headSupportTotal, headPercent, headRecentTotal, headRecentPercent, subordinates } = response
                let { percentViewMode } = state

                let percent = !percentViewMode ? headPercent * 100 : headRecentPercent * 100

                return {
                    ...state,
                    curBeltLevel: getCurrentLevel(percent),
                    loading: false,
                    level,
                    subordinates,
                    currentSelInfo: {
                        ...state.currentSelInfo,
                        [level]: innerText,
                    },
                    selStatisticInfo: {
                        ...state.selStatisticInfo,
                        [level]: { headVoteTotal, headSupportTotal, headRecentTotal, headPercent: headPercent * 100, headRecentPercent: headRecentPercent * 100 }
                    },
                    stateScreenShow: false,
                    staticInfo: types.getSortedStaticInfo(subordinates, innerText)
                }
            }
        case types.SET_LEVEL_FAILED:
            {
                return {
                    ...state,
                    loading: false,
                }
            }
        case types.SET_MAP_VIEW:
            {
                return {
                    ...state,
                    mapSelectedView: action.data,
                }
            }
        default:
            break
    }
    return state
}

export default voteReducer