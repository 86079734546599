//Identifies what level a specific supporter percentage is at (supporter in area/total voters in area)
const LevelFinder = (check) => {
  var level = 0;
  if (check >= 0.0005 && check < 0.001) {
    level = 1;
  } else if (check >= 0.001 && check < 0.002) {
    level = 2;
  } else if (check >= 0.002 && check < 0.0039) {
    level = 3;
  } else if (check >= 0.0039 && check < 0.0078) {
    level = 4;
  } else if (check >= 0.0078 && check < 0.016) {
    level = 5;
  } else if (check >= 0.016 && check < 0.031) {
    level = 6;
  } else if (check >= 0.031 && check < 0.063) {
    level = 7;
  } else if (check >= 0.063 && check < 0.13) {
    level = 8;
  } else if (check >= 0.013 && check < 0.25) {
    level = 9;
  } else if (check >= 0.25) {
    level = 10;
  } else {
    level = 0;
  }

  return level;
};

export default LevelFinder;
