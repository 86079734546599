import {
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { LogoLG, LogoLGIcon } from "../../../img/svg";
import { TextInput } from "../../../styles/common/textInput";

const ComingSoonModel = ({ isOpen, toggleModel, substack }) => {
  const theme = useTheme();
  const { orange_four, blue, black } = theme.palette.common;

  return (
    <Modal
      sx={{
        display: "grid",
        maxWidth: "80%",
        margin: "auto",
        zIndex: 999999,
      }}
      open={isOpen}
      onClose={toggleModel}
    >
      <Box sx={{ position: "relative", margin: "auto" }}>
        <Box
          sx={{
            position: "absolute",
            cursor: "pointer",
            right: "10px",
            top: "5px",
            background: "#000",
            borderRadius: "50%",
            padding: "5px 10px",
            color: "#fff",
          }}
          onClick={(e) => toggleModel(e)}
        >
          X
        </Box>
        {substack ? (
          <iframe
            src="https://commonsenseforunitingamerica.substack.com/embed"
            title="substack"
            className="SubStack-iframe"
            height="300"
            style={{ border: "1px solid #EEE", background: "white" }}
            frameborder="0"
            scrolling="no"
          ></iframe>
        ) : (
          <Box pt={7.5} pb={8} px={{ xs: 4, md: 12, lg: 20.25 }} bgcolor="#fff">
            <Grid
              container
              height="calc(100vh - 124px)"
              spacing={4}
              justifyContent="center"
              alignItems="center"
              textAlign={{ xs: "center", md: "start" }}
            >
              <Grid item xs={12} md={7}>
                <LogoLG />

                <Typography
                  mt="33px"
                  maxWidth="620px"
                  variant="h1"
                  lineHeight="107px"
                  letterSpacing="0.01em"
                  color={blue}
                >
                  WE ARE COMING SOON!!!
                </Typography>

                <Box mt="85px">
                  <Typography variant="h4" color={black}>
                    Get notified when we launch
                  </Typography>

                  <Stack
                    direction="row"
                    mt="14px"
                    justifyContent={{ xs: "center", md: "start" }}
                  >
                    <TextInput
                      placeholder="Email"
                      id="email"
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "40px",
                          borderColor: "black",
                          backgroundColor: "transparent",
                          borderRadius: "12px 0 0 12px",
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      ml="-5px"
                      sx={{
                        borderRadius: " 0px 12px 12px 0px",
                        backgroundColor: orange_four,
                      }}
                    >
                      Subscribe
                    </Button>
                  </Stack>
                </Box>
              </Grid>
              <Grid
                item
                xs={5}
                display={{ xs: "none", md: "flex" }}
                alignItems="center"
              >
                <LogoLGIcon />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ComingSoonModel;
