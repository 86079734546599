import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0021 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.05.28</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                28 May-03 June: get New York on the scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>Developed my section of the Op-Ed.  I have not been practicing my presentation, but I did hear more word on the event.  Definitely on the 17th of July, and it looks to be earlier in the day now (not 0200-0330).</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book. </strong>The book direction is shifting again.  It seems to do that every week, but I am happy that it does because I feel us moving closer and closer to what this needs to be.  The book will come about, we hope, through a major publisher.  Before we get a major publisher, we need to be publishable, to wit, a demonstration that we can bring in readers.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>No major updates, but we are looking to start doing a weekly post using Substack.  If we do, and I think we will, this could be the penultimate version of the weekly heartbeat as I will republish the newsletter here.  I think we keep putting important pieces into place, there are just more and more important pieces that I sense we are missing.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>The piece I did for the Op-Ed had some really good sticky phrases and points in it.  I won’t republish it here, but even Bev is saying now that we need to address both ranked choice voting and plurality at the same time.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>We are focusing on a weekly newsletter that will be article focused with a score voting lens.  This week I will be looking for articles and reviewing ones that Bev finds for me.  I think our first publication may be a couple weeks out, but it should be a fun ride.  I do like writing.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0021;
