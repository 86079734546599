export function getSession(sessionKey = "session") {
  const sessionString = localStorage.getItem(sessionKey);
  if (!sessionString) return null;

  try {
    return JSON.parse(sessionString);
  } catch (e) {
    console.warn("SessionService - While getting session", e);
    return null;
  }
}

export function removeSession(key = "session") {
  window.location.reload();
  localStorage.removeItem(key);
}

export function setSession(key = "session", session) {
  localStorage.setItem(key, JSON.stringify(session));
}

export function getSessionToken() {
  const session = getSession();
  return session ? session.token : null;
}

export function getSessionuserName() {
  const session = getSession();
  return session ? session.userName : null;
}
