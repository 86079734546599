import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0022 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.06.04</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                04-10 June: get New York on the scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>I did a fair amount of research, looking at articles and on-line media, for materials for the weekly essay. I also started working hard on the developing the presentation on score voting for Vote Nevada which I will perform as part of the Summer Civics Fair 15-17 July.  </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book. </strong>Bev will generate some of the initial articles for the substack.  At least she has in her mind what right looks like and I want to give her full ability to articulate that before I step in and ensure that we keep our focus on real benefits of score voting (it doesn’t do everything).  I also will look to ensure we stay out of the partisanship debate by not choosing a side.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>South Carolina has joined us!  This is great news as I’m not sure at this point how people are even hearing about the site.  The ads appear to be up and working, though I have them toned down for the moment. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>The piece for the Vote Nevada Summer Civics Fair will start with helping people identify what they see as the problem.  Following that I will launch into score voting.  I want to ensure that people understand that this isn’t about score voting.  This is about fixing the country.  It’s just that score voting is the tool to do it.  I explain score voting and then show how it does solve our problems (and why ranked-choice voting can’t), just a brief touch on how people can help (https://scorevoting.us), and then into the demo section of the class.  It will go for 45 min of lecture and then the voting demo.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I need to get the slides and script, first draft, done by Sunday and off to Bev for review.  As Bev explores the articles for substack, I’ll support that as well.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0022;
