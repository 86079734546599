import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./components/StaticTitleBar/StaticTitleBar.css";
import AffliatedButton from "./components/StaticTitleBar/affiliatedButton";
import SupporterButton from "./components/dynamicHeader/supporterButton";

// import BookBase from "./../../bookBase";
// import {  /* getSessionuserName,*/ removeSession,
// } from "./../../services/session";

export default class Press extends Component {
  // constructor(props) {
  //   super(props);
  // }

  buttonChoice() {
    if (!this.props.session) return <SupporterButton />;
    else return <AffliatedButton />;
  }
  render() {
    return (
      <><Row >
        <Col
          xs="12"
          sm="12"
          md="12"
          className="static-column highlighted-title tag-line"
          style={{ fontSize: '250%', justifyContent: 'center' }}
        >
          Our Press Releases <br />(Click the Article Link Below)


        </Col></Row>

        <Row style={{ justifyContent: 'center' }}>
          <Col
            xs="12"
            sm="12"
            md="4"
            className="static-column highlighted-title tag-line-second"
          >
            <a href="/press220301" target="_blank" rel="noopener noreferrer"><br /><br />
              <b>01 March 2022</b><br />Notice to Initiate Signature Campaign for <br />
              Division Free Voting State Constitutional initiative

            </a>

          </Col>{" "}</Row>





      </>
    );
  }
}
