import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0056 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">22.02.04</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>choice and ranked-choice</title>
                <strong>Choice versus Ranked Choice</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I often hear people say that they like ranked-choice voting because it offers more choices.  Does it?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Imagine that you are on a street that has two shoe stores.  The one shoe store offers only two kinds of shoes, brown ones and black ones.  The other has a whole window full of beautiful shoes to buy.  Which store offers more choice?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">You go into the full-window store and ask for the sweet pair of blue shoes.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">- sadly, they don’t have them in your size.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">You ask for the red pair.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">- sadly, they are temporarily out of stock.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">You ask for the yellow pair.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">- sadly, that model has been discontinued.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">You work your way down through all the shoes until at last only the brown or the black remain.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">You look at the attendant and say, “It really looked like you have a lot of choices here.” And he responds, “I know, right!  So much better than that old store down the street that only offers two.”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Which store offered more choices?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">From examinations of ranked-choice voting contests in California and Minnesota (256 RCV elections reviewed on official election websites), we discover that the ranked-choice voting mechanism (candidate elimination) only affects about 1/3 of the elections.  Of those, 95% of the time, the contest is reduced to only two choices (with some contests beginning with as many as 30 candidates) with the winner getting on average 55% of the vote.  We know from elections in Australia that ranked-choice voting “disproportionately favors major parties.” So, we can understand that ranked-choice voting contests, at the end of the day, offer only two choices.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Does ranked-choice voting offer “more choice?”
            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0056;
