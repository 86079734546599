import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import axios from "axios";

import "./SignupFlow1.css";

const BE_URL = process.env.REACT_APP_BE_URL;
const MOVEMENT = process.env.REACT_APP_MOVEMENT;

export default class SignupFlow1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      statesList: []
    };
    this.source = null;
  }

  async componentWillMount() {
    if (this.source) {
      this.source.cancel("ERR_CANCELLED");
    }

    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();

    this.props.setLoading(true);

    try {
      const response = await axios.get(`${BE_URL}/api/total/${MOVEMENT}/USA`, {
        cancelToken: this.source.token
      });

      const data = response.data;
      if (data && typeof data.subordinates === "object") {
        const statesList = Object.keys(data.subordinates)
          .map(supporter => supporter)
          .sort();
        this.setState({ statesList, dropdownOpen: true });
      }
      this.source = null;
    } catch (e) {
      if (e.message === "ERR_CANCELLED") return;
      this.setState({ statesList: [] });
    } finally {
      this.props.setLoading(false);
    }
  }

  componentWillUnmount() {
    if (this.source) {
      this.source.cancel("ERR_CANCELLED");
    }
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    const { statesList } = this.state;
    const { state } = this.props;
    const states = statesList.map((item, index) => {
      return (
        <DropdownItem
          key={index}
          onClick={e => this.props.setSelectedState(item)}
        >
          {item}
        </DropdownItem>
      );
    });

    return (
      <div className="sf-1">
        <p>In which state are you registered to vote?</p>
        <Dropdown
          className="dd"
          isOpen={this.state.dropdownOpen}
          toggle={() => this.toggleDropdown()}
        >
          <DropdownToggle caret className="dd-toggle">
            {state || "Select a state"}
          </DropdownToggle>
          <DropdownMenu
            className="dd-menu"
            modifiers={{
              setMaxHeight: {
                enabled: true,
                order: 890,
                fn: data => {
                  return {
                    ...data,
                    styles: {
                      ...data.styles,
                      overflow: "auto",
                      maxHeight: 100
                    }
                  };
                }
              }
            }}
          >
            {states}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}
