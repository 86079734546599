import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0029 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.07.23</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                23 - 29 July:  get Ohio on the <span><a href='https://scorevoting.swarmage.net'>scorevoting.us</a></span> board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>I gave the <span><a href='https://drive.google.com/file/d/1wI6gCRYZDwCuiFf0J4WheGNX5789gvUG/view?usp=sharing'>presentation</a></span> to Vote Nevada on Saturday (at 2:00 am!).  But it needs work and so I also worked on the presentation.  </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Newsletter. </strong>Not sure how this is continuing forward.  Talking to Bev we may need the presentation first so that the newsletters can spin off of it.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>We now have 22 states!!  Four more people joined.  And they were two each on two days, which is a record.  An arguably pathetic record, but still, it is progress in the right direction. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>I think it will subtly change.  One solid point from discussions with Bev is that, all of this effort to secure voters’ rights or lock voters out . . . what does matter?  What will change if all of these groups got their wish.  That was something from Vote Nevada as well.  They want more un-heard voices to get out into the campaigns.  But the plurality voting system will still divide the population, hobble the government, and disenfranchise the voter, so, what’s the point?</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I’m reworking the technical bits of the presentation . . . and maybe rehearsing those bits as well.  We’ll see.  I met with my editor again on Tuesday and we’ll see where we are. </Col></Row>

        </Container>
        </>
    )
}

export default Blog0029;
