import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0017 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.04.30</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                30 April -6 May:  get New York on the scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>Missed a week somehow.  Focus this past week was researching election stories that could be good examples of the perils of the two-party system and benefits of score voting methods.  Also did some additional work on the book clarifying some of the main themes. I also did a whole Malcolm Gladwell writing course the week before.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book. </strong>Working with Bev is great!  What a difference to have someone else enthusiastic in the project and who knows what their doing.  She did amazing research and is onto a great lead for one of the stories while I be researching a second.  When you work with someone else, the project makes progress even if you don’t.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>Very quiet.  We have a discord now, but, ultimately, I’m not social.  I don’t go onto or participate in social media as a matter of course so there is no natural roll over to that here.  In all the stories I have heard about social movements taking off quickly, they had been started by social people.  I think my goal is find social people.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>It is hard not to trigger every time ranked-choice voting comes up with someone saying it will reduce polarization.  How is that possible when 51% of the voters vote Republican and 49% vote Democrat.  That’s what we get today and we’re polarized; that is what we would get under ranked-choice voting why would we not be polarized.  The book will have 10 or so unifying and positive themes, things that aim to improve society.  I’m also looking for the simplest most illuminating framing I can.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I will be researching Burlington in 2009.  I will also polish my latest short pitch (5 min) and begin practicing it.  I just need to do that.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0017;
