import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0001 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.01.01</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The purpose of the weekly heartbeat is to keep all supporters and swarmers informed on the movement's progress nationwide. I'll keep it short, sweet, and pithy as possible.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Lines of Effort.  </strong>The movement currently has three lines of effort: the book, the organization, and the message.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book. </strong>The book aims to provide supporters, swarmers, and curious minds information on how score voting systems empower voters, unite governments, and heal nations.  A draft pdf is accessible from this site, and I am still taking reader comments.  I will complete the next draft by 8 January and then start working with a substantive editor.  I estimate sixty days for that process.  After that comes the copy editor, the layout artist, and then the publicist.  I will publish it in 2021, but not sure when.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>I have found another interested in a nationwide effort, and we are looking for a third.  We plan to base the structure on Rick Falkwinge's swarm system making that an easy piece.  The book timing will likely drive the movement effort.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>I will try to arrange for a TED talk to carry the base message.  Having worked with Heroic Public Speaking, I feel I have a better than average chance of creating a transformational performance.  I will work with an editor until February on the presentation and then start seeking out a TEDx venue to present it.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Updates on the webtool.</strong>The website continues to function fine.  These heartbeat messages, a link to the Twitter account, and a list of recent joiners constitute upgrades to the website in the near future.  I will also need to start updating the voter database based on the 2020 election. </Col></Row>

        </Container>
        </>
    )
}

export default Blog0001;
