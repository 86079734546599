import { Box, Typography } from "@mui/material";
import React from "react";
import "./loader.css";

const Loader = () => {
  return (
    <Box height="100vh" display="grid" >
    <Box mt="auto">
      <Box id="man"mt="auto">
        <Box id="eye-l"></Box>
        <Box id="eye-r"></Box>
        <Box id="nose"></Box>
        <Box id="mouth"></Box>
      </Box>
    </Box>
      <Typography variant="h4" id="wall">
        loading...
      </Typography>
    </Box>
  );
};

export default Loader;
