import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0037 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.09.17</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Finding the Voters' Most Desired Candidate</strong><p />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                If we listen to a conversation between a group of friends on where they want to go or what they want to do, we hear people mention what they want, what they don’t want, and how other options relate to these. Through this very organic process, the group of friends identifies the best choice for the group.  The most desired choice may not be anyone’s favorite, but the individual inputs combine to determine the group’s most desired option. Max Voting methods mimics this by recording what voters like, dislike, and how options compare to each other, and then identifying a winner based on all of this input.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                A Max Voting election is one in which every voter gives points to every candidate and the highest number of points wins.   Every voter identifies how much they desire the election of each candidate as compared to the others.  Voters use a set range to give points to each candidate independent of how they gave points to any other candidate.  The number of points can be as low as one (or “approve” or “disapprove”) as it is with Approval Voting, or more such as from 0-9.  Max Voting methods use ALL the points supplied by the voters to identify the winner.  The methods identify the voters most desired candidate every time.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Max Voting elections seek to answer the question, “Of the options (candidates) available, which is the voting population’s most desired?” This question differs from the question plurality-style elections ask which is “Of the options available, which options was most selected as a voter’s top choice?”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                A quick aside:  Is it fair to say that Max Voting methods deliver the “most desired” candidate, as that would imply other voting methods do not deliver the voters’ most desired candidate? Consider what goes through my mind when I give points to a candidate. What, exactly, am I representing when I do that?  How well I think they cook, how tall they are, how much they make me laugh?  The points demonstrate how much a voter desires that person to be their representative. After we know how much a voter desires a candidate, we can add that amount of desire (as shown by points) to another voter’s desire for that candidate.  Through this process we determine how much the total voter population desires that candidate to be their elected representative.  By comparing how much each candidate is desired, we can learn which candidate voters desire the most, the “voters’ most desired candidate.”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Max Voting identifies the voters’ most desired candidate every time, and, if there is no additional instant run-off added (though there are good arguments why you may want one), declares them the winner. Other voting systems do not do that. They identify the candidate most often chosen by voters (plurality), they identify the candidate chosen by over half of the voters (majoritarian), they identify which candidate their voting system calculated to win (ranked-choice voting).  But it is only when the voting method asks all voters how much each voter desires each candidate, and then adds up the desire each voter has for each candidate, AND declares the candidate receiving the greatest amount of desire from voters as the winner, can we say that the voting method elects “the voter’s most desired candidate.”  There is no way around this.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Max Voting delivers the most desired candidate every time because,
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                - it asks how much each voter desires each candidate,
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                - it uses all that information in determining the winner,
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                - it declares the most desired candidate to be the winner.

            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0037;
