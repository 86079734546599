import React, { useState } from "react";

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


export default function Book(props) {
  const { downloadName } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function downloadFile() {
    fetch(`https://swarmage-bucket.s3.us-east-2.amazonaws.com/book.pdf`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${downloadName}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log("Error::", err);
      });
  }

  return (
    <>
      <div>
        <button type="button" className="pdf-button" onClick={downloadFile}>
          Download
        </button>
      </div>
      <Document
        file="https://swarmage-bucket.s3.us-east-2.amazonaws.com/book.pdf"
        // options={{ workerSrc: "./pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(e) => console.log("onLoad Error::::", e)}
      >
        <Page
          pageNumber={pageNumber}
          style={{
            display: "block",
            userSelect: "none",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
          }}
        />
      </Document>
      <div>
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <button
          type="button"
          className="pdf-button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </button>
        <button
          type="button"
          className="pdf-button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
      </div>
    </>
  );
}
