import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0008 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.02.19</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>The draft of the new video was created and I got some great feedback from Jack, Tenley, and Sara of Equal.Vote.  The audio came out fine, which was potentially a problem.  I also discovered factual issues with my article so that it had to be pulled, but has since been republished. The next draft of the book has also been completed and posted to this website.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>Still waiting.  I plan to move forward at the end of the month, either it will be the most expansive route, or the minimalist route.  There will be an answer, not in next week’s Heart Beat, but the following week’s. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>Oregon has a STAR Voting initiative on the ballot, and I have voiced my support for it and will be standing by to see if there is anything else that I can do.  Colorado, I understand, has a measure coming up as well.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>I believe there is great benefit to pushing the Republican Party toward using Approval voting to avoid a schism in party in 2024.  Not sure the right people to try to get that message to.  I have sent it to my representatives and I’ll try to do an article for Democracy Chronicles on it.  I think it would make sense to push the Democratic Party in the same direction as it ensures that they won’t be split amount like-minded parties in 2024. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I will try to get the next draft of video done, and try to reach out to some Republican Party magazines and such.  I don’t expect much time next week to be available, but I’m also looking at that potential side project to start to develop better help files for the swarm age tool. </Col></Row>

        </Container>
        </>
    )
}

export default Blog0008;
