import React, { Component } from "react";
import { Col } from "reactstrap";
import './PercentBlock.css'

class PercentBlock extends Component {
    render() {
        const { percent } = this.props
        return (
            <Col
                xs="3"
                sm="3"
                md="3"
                className='percent-block'
            >
                {`${percent}%`}
            </Col>
        )
    }
}

export default PercentBlock;