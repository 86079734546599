import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0067 = () => {
    const [image] = useState(logo);

    return (
        <>
            <Container>
                <Row><Col>
                    <img className="image" src={image} alt="" />
                </Col>
                </Row>
                <Row><Col className="date">23.04.05</Col></Row>
                <p />
                <Row ><Col xs="12" sm="12" md="12" className="title">
                    The Weekly Heart Beat for Uniting America
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><title>MaxVoting Update</title>
                    <h1>What is MaxVoting?</h1><br />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>“What exactly is MaxVoting?”</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">A great question to ask during these challenging times of divided America. </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">MaxVoting methods are any voting methods in which all voters can freely rate all candidates. </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">By “freely” I mean, it doesn’t matter how you voted for candidate A when it comes to how you will vote on candidate B. </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">By “rate” I mean either simply voting for them, like you do today, or given them a rating like you rate products on Amazon. </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">And there are many different MaxVoting methods including approval voting, STAR voting, majority judgement voting, score voting, and 1-2-3Vote. Each work in unique ways, but end with the same result; the U.S. united and you heard. </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Plurality, our voting system today, ranked-choice voting, and final-five voting are not MaxVoting methods as they cause political division and voter disenfranchisement.</Col></Row><p />
            </Container>
        </>
    )
}

export default Blog0067;
