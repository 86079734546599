import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0020 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.05.21</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                21-27 May:  get New York on the scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>The search for suitable stores proved difficult.  A couple surfaced, but they all lack the emotional punch required.  Still, perhaps more digging may prove useful.  I also contracted the services of Brickwork in India to help with the research, but they found dearth of information equally frustrating.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book. </strong>I think we have a good story and way forward and between Bev and myself can come up with a solid article that both shows the pain and problem as well as the promise.  At the least we have a strong focus for the week.  </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>No major updates.  Still working on getting New York onto the boards.  But the ads have now been added to the Swarm Age site which hopefully will help ensure a more stable financial future. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong> I did a small bit of playing around with the five-minute message in hope of having the opportunity to present it not later than July to an engaged group in Nevada.  I shall practice the delivery to ensure the best possible presentation, but after that, it truly falls into the hands of Nevadans and what they truly want.  More, if not more, of the same, or a chance for unity and greater function in the government.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I will keep working on my portion of the proposed op-ed.  I will also start to practice my presentation each night.  It is supposed to be just a five-minute, elegant unpacking of one simple idea.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0020;
