import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0030 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.07.30</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                30 Jul - 5 Aug:  get Ohio on the <span><a href='https://scorevoting.swarmage.net'>scorevoting.us</a></span> board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>I was waved off on the brief as Bev has some ideas on how to re-invent it.  I was also on travel and, honestly, a little lost on my focus for the week.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Newsletter. </strong>So, here is the latest plan.  I want to start writing articles for daily release.  I’ll just write them this upcoming week, well on Sunday, anyway, and then send them to Bev and see what she thinks of them.  That may, or may not, result in publications to <span><a href="https://commonsenseforunitingamerica.substack.com">https://commonsenseforunitingamerica.substack.com</a></span>. The focus of the article is to help people understand our political environment from a score voting angle.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>No new joiners this week, but probably no real reason we would gain any.  None from Nevada, which is a little surprising, but something we will take in stride.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>Okay, I think we have three major points.  1) Score voting unites, plurality (and RCV) divides.  2) Score voting delivers the voters most desired candidate.  3) We are wasting time chasing other issues before this is fixed.  We’ll see how well these are reflected in this week’s articles.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>Macro: start consistently turning out articles (end of August), create a transformational performance (I just need to keep working at it), create another easy concept video (end of August). For this week, do five articles, and script out the video.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0030;
