import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0033 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.08.20</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Fable of Annie's Vacation.</strong><p />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                My friend Annie and her family of seven want to go on a family vacation.  As a group they have decide on four possible vacation options: amusement parks, beach vacation, camping, or downhill skiing.  Everyone votes and the amusement parks wins with 4 votes, a majority, to 3 votes for the Beach.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                The family goes on vacation to the amusement part and has a miserable time.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Annie later learns that four of the seven people in her family, a majority, hate amusement parks, including her youngest, who voted for amusement parks. So, she asks him, “If you hate amusement parks, why did you vote for it?” and he says, “Because, I knew that three of you love amusement parks, and that the other three love the beach.  But I wanted to go downhill skiing. So, instead of throwing away my vote, I choose the lesser of two evils and picked the amusement parks.”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                The next time, Annie asks everyone to pick all the vacation options that they find at least acceptable. They then discover that only three people each find either the amusement park or the beach acceptable, but four people find camping acceptable, and six people, almost everyone, and a majority, find downhill skiing to be acceptable.  So, they went downhill skiing.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Everyone had a good time on the vacation, but Annie later learned that four of the seven members of the family, a majority, would have preferred camping over downhill skiing.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                The next time, Annie decides to find out how much people liked each of the options. To do this she has everyone give a number of points, from zero to nine, to each of the options to shows how much they desire to go on that vacation, the more points the more desire.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                After she did this, Annie and the rest of her family can understand how much the family, as a group, desire each option. The beach has an average score of only 3.9 out of 9.  The amusement parks: 4 out of 9. Downhill skiing: 5.4 out of 9. And camping, an average score of 6 out of 9. Even though no member of the family had going camping as their top choice, and even though it was not a “majority,” it is the majority opinion of the group. This is Maximum Voting.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Pop quiz.  How many different “majorities” did you hear me identify during the story of Annie?  We can make any number of majorities out of a group of people, but there is only ever one majority opinion.  So, before we go ensuring that we have “majority rule,” we should first clarify if we are talking about being ruled by the richest majority, the whitest majority, the most powerful majority, the most cunning majority, or one of the hundreds of other possible majorities, or the one and only majority opinion that exists in any group of voters waiting to be revealed not created.
                .           </Col></Row><p />

        </Container>
        </>
    )
}

export default Blog0033;

