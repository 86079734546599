import React from "react";
import {
  DoneIcon,
  FacebookIcon,
  InstagramIcon,
  LikeIcon,
  NetworkIcon,
  RoseIcon,
  SunFlowerSmIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../../img/svg";
import Avatar1 from "../../img/avatar1.png";
import Avatar2 from "../../img/avatar2.png";
import Avatar3 from "../../img/avatar3.png";
import Avatar4 from "../../img/avatar4.png";
import Avatar5 from "../../img/avatar5.png";
import {
  FifthStep,
  FirstStep,
  ForthStep,
  SecondStep,
  ThirdStep,
} from "../home/stepSection/steps";

export const BUTTON_ON_BORDER = "#FFFF00";
export const BUTTON_ON_FILL = "#FFFFCD";
export const BUTTON_ON_LINES = "#000000";

export const BUTTON_OFF_BORDER = "#D4D2D2";
export const BUTTON_OFF_FILL = "#FFFDF3";
export const BUTTON_OFF_LINES = "#D4D2D2";

export const NAV_FIRST_BTN_TEXT = "Free Gifts";
export const NAV_SECOND_BTN_TEXT = "Join Now and #MaxTheVote";
export const JOIN_BTN_TEXT = "Join The Journey";
export const DONATE_BTN_TEXT = "Donate";

export const HERO_HEADER_TEXT = "Are you being";
export const HERO_HEADER_TEXT_COLOR = "represented";
export const HERO_HEADER_TEXT_RULED = "ruled";
export const HERO_HEADER_TEXT_OR = "or";
export const HERO_SUBTITLE =
  "We ensure you are represented regardless of whom wins";

export const ThirdSectionList = [
  "ThirdSectionFirstPoint",
  "ThirdSectionSecondPoint",
  "ThirdSectionThirdPoint",
  "ThirdSectionForthPoint",
];

export const SUB_STACK_URL =
  "https://commonsenseforunitingamerica.substack.com";

export const PDF_JOIN_THE_JOURNEY_URL =
  "https://swarmage-bucket.s3.us-east-2.amazonaws.com/Volunteer+information+member+roles+(220715).pdf";

export const ThirdSectionTypoTwo =
  "Common Sense for Uniting America reimagines America as a country guided by the majority opinions of the people, where the voice of every American is heard. #MaxTheVote";

export const FOURTH_SECTION_HEADING =
  "Fair representation for you, for now, for always. #MaxTheVote";
export const fourthSectionList = [
  "All voters freely rate all candidates.",
  "All input is used to find the winner",
];

export const fourthSectionSecondList = [
  "Approval",
  "Star",
  "Division Free",
  "Majority Judgement",
  "Vote 3-2-1",
];

export const STEPS = [
  {
    icon: <SunFlowerSmIcon />,
    step: "learn",
    title: <FirstStep />,
  },
  {
    icon: <RoseIcon />,
    step: "join",
    title: <SecondStep />,
  },
  {
    icon: <NetworkIcon />,
    step: "discuss",
    title: <ThirdStep />,
  },
  {
    icon: <LikeIcon />,
    step: "do",
    title: <ForthStep />,
  },
  {
    icon: <DoneIcon />,
    step: "done",
    title: <FifthStep />,
  },
];

export const SUPPORTERS_LIST = [
  { src: Avatar1, name: "William Smith" },
  { src: Avatar2, name: "Oswald Mike" },
  { src: Avatar3, name: "Kasey Miz" },
  { src: Avatar4, name: "Jane Cooper" },
  { src: Avatar5, name: "David Alvin" },
  { src: Avatar3, name: "Oswald Mike" },
  { src: Avatar3, name: "Kasey Miz" },
  { src: Avatar4, name: "Jane Cooper" },
  { src: Avatar3, name: "Kasey Miz" },
  { src: Avatar4, name: "Jane Cooper" },
  { src: Avatar5, name: "David Alvin" },
];

export const footerAddresses = [
  "Swarm Age LLC,",
  "401 Ryland St. STE 200-A,",
  "Reno, Nevada 89502.",
];

export const EMAIL = "admin@SwarmAge.org";

export const footerLinks = [
  {
    text: "privacy",
    link: "/PrivacyPolicy",
  },
  // { text: "contactUs", link: "https://commonsenseforunitingamerica.substack.com" },
  {
    text: "termsOfService",
    link: "/TermsOfUsage",
  },
  // {
  //   text: "volunteer",
  //   link: "https://swarmage-bucket.s3.us-east-2.amazonaws.com/Volunteer+information+member+roles+(220715).pdf",
  // },
  {
    text: "googleAdsense",
    link: "https://policies.google.com/technologies/partner-sites",
  },
  // {
  //   text: "donate",
  //   link: "/coming-soon",
  // },
];

export const footerIcons = [
  { element: <InstagramIcon />, link: "/coming-soon" },
  {
    element: <FacebookIcon />,
    link: "https://www.facebook.com/MaximumVoting",
  },
  { element: <TwitterIcon />, link: "https://twitter.com/MaxVoting" },
  {
    element: <YoutubeIcon />,
    link: "https://www.youtube.com/channel/UCqHP9kv-hN7E-AvU9bEq06Q",
  },
];
