import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0065 = () => {
    const [image] = useState(logo);

    return (
        <>
            <Container>
                <Row><Col>
                    <img className="image" src={image} alt="" />
                </Col>
                </Row>
                <Row><Col className="date">22.04.16</Col></Row>
                <p />
                <Row ><Col xs="12" sm="12" md="12" className="title">
                    The Weekly Heart Beat
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><title>MaxVoting Update</title>
                    <h1>More engagement on #voting and #democracy for #MaxVoting</h1><p />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>last week</h2><p />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- <a href="https://bit.ly/CmnSens">new substack article is out</a>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- <a href="https://youtu.be/8ZotZkU3FhA">new video is out</a> (https://youtu.be/8ZotZkU3FhA)
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- interviewed for Braver Angels article
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- book of the week: <a href="https://www.amazon.com/Who-Not-How-Accelerating-Teamwork/dp/B08KYKR23H/ref=sr_1_1?crid=72BZG1D70AUU&keywords=who+not+how&qid=1650200467&sprefix=who+not+ho%2Caps%2C345&sr=8-1">Who not How</a>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- team is draft up our business plan
                </Col></Row><p /><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>next week</h2><p />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- continuing to setting up 501(c)3
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- working with
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><a href="https://cpfuture.org/">Common Power Future</a>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><a href=" https://www.govlearn.org/">GovLearn</a>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><a href=" https://www.electoralcollegereformmovement.com/">Electoral College Reform Movement</a>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- new article development
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- new video (MaxVoting Minute: How Does MaxVoting End Political Division)
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- writing an article for the newspapers in NV.

                </Col></Row><p /><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Your work in #democracy and promoting #MaxVoting is key</h2><p />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Just a shout out to everyone in the movement for all you're doing to fix Democracy.  Fixing Democracy so it listens to the voters, all the voters, IS what we are doing and IS what MaxVoting promises.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">The difference between saving the world and trying to save the world is that one we can't do and one we must do.  Each little step you take is progress, so please take one little step this week.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- send me some questions to make videos
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- echo our posts on your social networks
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- tell a friend or colleague
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- work on (write, review, edit, engage on) an article, video, or plan.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- throw in an idea

                </Col></Row><p /><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>The clock is ticking</h2> <p />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">There is a race between fixing our country and losing it, losing the nation to hopelessness and distrust both between the people and between the people and their government created by establishing factional or majority rule. We are working to bring real trust and hope be ending those archaic practices.
                </Col></Row><p />
            </Container>
        </>
    )
}

export default Blog0065;
