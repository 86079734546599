import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0026 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.07.02</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                2 – 8 July:  get Ohio on the <span><a href='https://scorevoting.swarmage.net'>scorevoting.us</a></span> scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>Last week was focused on the script and getting through performing it.  Not quite there yet, but getting close.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Newsletter. </strong>The current system is for Bev to have the great article ideas and do the research and then I provide the score voting view of it.  However, no articles have come yet.  Will see how the week goes.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>No new progress on the org—no new joiners this week.  I’m hoping that the briefing this month may generate more interest, but now I’m concerned about the difficulty to join because of the requirement for people to input their precinct.  I’m going to start trying to shift that so people only need to know their county.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>Message is strong and focused on the problems with the voting system.  I really want to nail this brief, because I still never hear people voicing these ideas. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I will keep practicing with the script.  I expect another draft newsletter to come to me this week, so, hopefully by next week we will have one out. </Col></Row>

        </Container>
        </>
    )
}

export default Blog0026;
