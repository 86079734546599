import axios from "axios";
const BE_URL = process.env.REACT_APP_BE_URL;
// const BE_URL = process.env.REACT_APP_BE_URL;

export const loginSupporter = async requestBody => {
  const url = `${BE_URL}/api/auth/supporters`;
  try {
    const response = await axios.post(url, requestBody);
    if (response.data) {
      const session = {
        token: response.data.token,
        userName: response.data.userName
      };
      localStorage.setItem("session", JSON.stringify(session));
      return { data: session };
    }
    return { data: null };
  } catch (e) {
    return { error: (e.response && e.response.data) || e.message };
  }
};

export const forgotPassword = async requestBody => {
  const url = `${BE_URL}/api/auth/forgotPassword`;
  try {
    const response = await axios.post(url, requestBody);
    if (response.data) {
      return { data: response.data.password };
    }
  } catch (e) {
    return { error: (e.response && e.response.data) || e.message };
  }
};
