import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0043 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.10.29</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Not Listening to More Voices Does Not Help</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Many activists and commentators have recently spoken out about the disenfranchisement of U.S. voters.  Some see the disenfranchisement as coming from the creation of state laws which make the act of voting more difficult (or impossible) for some.  Others see the disenfranchisement as coming from federal laws which may open up greater avenues of voter fraud creating even greater distrust in our elections.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But what if we could magically snap our fingers and create laws in every state that allowed 100% of voters to vote easily and ensured the voting system was 100% secure against voter fraud.  What do we hope would be the result?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">If we are optimists, that hope might be that the will of the people could be accurately understood and employed.  If we are pessimists, that hope might be that our political party would become the most powerful one with an ability to finally get things fixed.  But would either hope even have a chance of realization in our “100% inclusive, 100% secure” imagery voting utopia?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Article 1 of the U.S. constitution gave the right to determine election law to the individual states, but also gave the federal government the ability to set those laws.  Initially, the states generally followed some form of the English practice that, "<a href="http://jer.pennpress.org/media/26167/sampleart22.pdf"> only people who had freehold landed property sufficient to ensure that they were personally independent and had a vested interest in the welfare of their communities could vote.</a>”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">In this condition, the young United States found itself quickly and strongly divided over the issues of slavery, foreign alliances, and the role and power of the central government in relation to the states, among many other issues.  All of these issues began to solidify into two only factions, each embracing one side of each issue. Partisan groups, Federalists and Democratic-Republicans, began to immerge to push their own specific agendas.  Both realized the latent political power of those not currently enfranchised to vote and began to work to expand voting rights.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Between 1784 and 1860 states had expanded suffrage to include almost all white males, but the country remained strongly divided.  Each and every election was seen as critical to the future of the nation, the most important election ever.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">As the political parties continued to wage political war against each other, voting rights continued to expand. The <a href="https://guides.loc.gov/15th-amendment">15th Amendment</a>  gave African American men the right to vote in 1870, ‘though state laws and prejudicial practices kept this from happening fully until the 1960s.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The <a href="https://www.archives.gov/exhibits/featured-documents/amendment-19">19th Amendment</a>, gave U.S. women the right to vote in 1920.  Despite this enfranchisement of over half of the nation’s population, the parties each maintained a close to 50% division of the electorate each with U.S. policy waffling back and forth between liberal and conservative legislation depending upon the orientation of congress and the presidency.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The <a href="https://history.house.gov/HistoricalHighlight/Detail/37045">24th Amendment</a>, ratified in 1964, eliminated poll taxes, which some states had used as tools to keep minorities from voting in federal elections.  However, even when these groups received greater representation, the national divide, still close to 50% (as attested to by the average winning margin of popular votes for U.S. president), remained.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The <a href="https://history.house.gov/HistoricalHighlight/Detail/37022">26th Amendment</a>, ratified in 1971, brought in the more youthful voice of those ages 18-21.  But still our nation is divided into two halves, each claiming to represent the true will of the people.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">And now we look toward the success or defeat of the “For the People Act,” “<a href="https://www.congress.gov/bill/117th-congress/senate-bill/1/text">to expand Americans' access to the ballot box, reduce the influence of big money in politics, strengthen ethics rules for public servants, and implement other anti-corruption measures for the purpose of fortifying our democracy</a>,” which we believe would allow us to understand the will of the people and ensure our more correct political party to finally take control of the government so that it can fix the mess we are in.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">After every expansion of voter suffrage, after every effort to hear the voter’s voice clearly, we have found ourselves every bit as divided as before, as caustic as before, as dysfunctional as before.  Upon what then to we pin our hopes of that being different this time?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Our voting method remains deaf to the voice of the people not because there aren’t enough people with the ability to vote. Our nation remains divided into two antagonistic halves not because there is some unrepresented group that will support but one political agenda.  We are divided because our minimal voting method divides us.  We are disenfranchised because our voting method can only except and use the minimum amount of input from the voters.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I’m not saying I’m against either ensuring all voters can easily vote or that our voting system is robustly protected against fraud.  What I am say is that we improve nothing by NOT listening to more people in a more secure fashion.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We can only understand the will of the people if we let the people give their full, unfettered input—what every voter thinks about every candidate.  We can only unite our country when our voting method pushes candidates to support the will of the people over the whim of the party.  That happens when we adopt a Max Voting method.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Max Voting methods allow all voters to give up to a set number points (say from 0 to 9) to each candidate based on how much they personally desire them to win.  The candidate receiving the most points wins. Because Max Voting methods (like Approval Voting and STAR Voting) use all of the points in determining the winner, candidates have an incentive to align their political platforms with the desires of greatest number of people.  The candidate who best does this improves their chance of winning. With Max Voting methods, candidates don’t try to attract the most voters to their platform; candidates move their platform to where there are the most voters.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I hope America is successful in its current effort to make our elections more secure and more accessible, but, I believe after they do, tomorrow will only bring even more voices raised in anger and frustration.
            </Col></Row><p />

        </Container>
        </>
    )
}

export default Blog0043;
