import React, { Component } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios";
import "font-awesome/css/font-awesome.min.css";

import "./SignupFlow2.css";


const BE_URL = process.env.REACT_APP_BE_URL;

export default class SignupFlow3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      precinctList: [],
    };
    this.source = null;
  }

  async componentWillMount() {
    if (this.source) {
      this.source.cancel("ERR_CANCELLED");
    }

    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();

    const { state, county } = this.props;
    if (!state || !county) return;

    this.props.setLoading(true);

    try {
      const response = await axios.get(
        `${BE_URL}/api/total/supporters/USA/${state}/${county}`,
        {
          cancelToken: this.source.token,
        }
      );

      const data = response.data;
      if (data && typeof data.subordinates === "object") {
        const precinctList = Object.keys(data.subordinates)
          .map((supporter) => supporter)
          .sort();
        this.setState({ precinctList, dropdownOpen: true });
      }
      this.source = null;
    } catch (e) {
      if (e.message === "ERR_CANCELLED") return;
      this.setState({ precinctList: [] });
    } finally {
      this.props.setLoading(false);
    }
  }

  componentWillUnmount() {
    if (this.source) {
      this.source.cancel("ERR_CANCELLED");
    }
  }

  toggleDropdown() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  render() {
    const { county, precinct } = this.props;
    const precincts = this.state.precinctList.map((item, index) => {
      return (
        <DropdownItem
          key={index}
          onClick={(e) => {
            this.props.setSelectedPrecinct(item);
          }}
        >
          {item}
        </DropdownItem>
      );
    });

    return (
      <div className="sf-2">
        <p>
          Which voting zone in <b>{county || "selected county"}</b> do you wish to use?
          (unless you've been told otherwise, it will be <b>{county || "selected county"}</b> again)
          {/* registered to vote? (Don't know it? Click the map icon to get help &nbsp;&nbsp;&nbsp;
          <a href={mapLocation} target="_blank" rel="noopener noreferrer">
            <img
              src={flodingMap}
              width="46px"
              height="46px"
              alt="foldingMap"
              title="click here, then enter your county and click 'render' under the map. Use the map to find your precinct? (opens new window)"
            />
          </a>{" "}
          or just pick one. (At least you'll be in the right county.)) */}
        </p>
        <div className="dd-row">
          <Dropdown
            className="dd"
            isOpen={this.state.dropdownOpen}
            toggle={() => this.toggleDropdown()}
          >
            <DropdownToggle caret className="dd-toggle">
              {precinct || "select a voting zone"}
            </DropdownToggle>
            <DropdownMenu
              className="dd-menu"
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: (data) => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: "auto",
                        maxHeight: 100,
                      },
                    };
                  },
                },
              }}
            >
              {precincts}
            </DropdownMenu>
          </Dropdown>
          <Button
            size="sm"
            className="dd-back-btn"
            outline
            color="secondary"
            onClick={(e) => this.props.previousSlide()}
          >
            Reselect County
          </Button>
        </div>
      </div>
    );
  }
}
