import * as types from '../constants'

export function setLevel(data) {
    return { type: types.SET_LEVEL, data }
}

export function setLoading(data) {
    return { type: types.SET_LOADING, data }
}

export function setCurBeltLevel(data) {
    return { type: types.SET_CUR_BELT_LEVEL, data }
}

export function setStateScreenShow(data) {
    return { type: types.SET_SCREEN_SHOW, data }
}

export function setMapView(data) {
    return { type: types.SET_MAP_VIEW, data }
}

export function setPercentView(data) {
    return { type: types.SET_PERCENT_VIEW_MODE, data }
}