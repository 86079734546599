import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0005 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.01.29</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>I had the pleasure of testifying before the New Hampshire Election Law Committee.  No new word on the book. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>The book project will continue into its next rewrite starting this weekend.  After testifying and hearing from editors I think it is missing a very important element—humanity.  I hope to capture more of Us within its pages.  I continue to await my leader’s decision on the editor issue. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>Although I want to continue to push to all states, New Hampshire seems to suggest the clearest strategy.  Much of that I learned from the hearing; and I hope to make good use of it.  I also want to keep a focus on Texas as the effort there will be large.  Nevada, of course, will always remain a personal priority with me.  I’m going to try to start looking for more STAR voting and AV groups and get their information up onto the website.  The question of the week will be, in what states do we not have a movement started. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>I received great feedback from Sara at Equal.vote on the draft script for the next short.  That script is challenging as I’m trying to present an idea that most people pushing for a score voting system do not seem to understand.  This maybe, of course, because I’m complete wrong about it, but I don’t think so. And last time I’ll put out this reminder: free seminar from Heroic Public Speaking, 1-2 Feb: <span><a href='https://heroicpublicspeaking.com/'>https://heroicpublicspeaking.com/</a></span>. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I want to at least have created a new draft of the script for the animated short, have added as many websites for score voting to this site’s contact list as possible. I also want to move forward on the next rewrite.  To do it, I will need to do more research than I like, so we’ll say a goal is to collect two useful stories. I also want to make the modifications to it that I have in my head right now.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0005;
