import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0003 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.01.15</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>I stated I would have a developmental editor by now, and I do, but there remains a question of resourcing.  I also worked to upgrade the site and made one small improvement while realizing one of the previous week’s gains has a terminal bug.  I have inexorably linked the book to the movement—and this is good (that will make more sense in the coming weeks).</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>I have 100% confidence that the editor and I can produce a public wave of interest, and though I see the path forward, I don’t know how I will finance it.  I have some options that I will explore this week, and I plan to at least start down the road.  A chain is forged one-link at a time, so I’ll focus on the first link while scrambled for a way to create the second.  Further, based on how it appears we will need to go forward with the branding, I think I will be the sole author.  I would say this merely recognizes the situation as it already is. Our plan for the book is Malcolm Gladwell’s Blink meets Thomas Paine’s Common Sense.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>Part of the book would involve creating websites, so, though I wouldn’t say I like giving up my free support (for a month) to build the new website, I think everything I make will need to get expunged anyway, which may be more expensive.  Instead, I will try to get the site to point to Unsplitthevote.org until we can properly build it. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>Don’t forget about the free seminar, 1-2 Feb: <span><a href='heroicpublicspeaking.com/'>heroicpublicspeaking.com/</a></span>. I turned in my final version for my editor’s last look.  I feel excellent about it, but as my developmental editor pointed out (toward the book as well), it feels too analytical and lacking the stories to connect myself and the subject to the audience. HPS emphasized this formula as well, but I didn’t create impactful stories, just instructional.  It’s hard to write about oneself.  Likewise, the book will grow in length as I add the narratives that connect people to the issues.  The draft version that sits on the site now will remain as is.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>Find funding. Submit for permission for a 501(c)3 to help with funding (in Nevada). Start on the statement of work with the editor (book), and keep learning more about Redux to improve the webtool.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0003;
