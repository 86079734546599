import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0060 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">22.03.12</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>Ukraine Cries Out</title>
                <strong>Ukraine Cries Out and Our World Changes</strong><p />

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Our prayers go out to Ukraine.</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Everyone's focus, prayers, and well wishes reach out to the citizens of Ukraine during this dark time of invasion by Russia.  As the war goes on, and nations unite to stand against the aggressor, we begin to feel the winds of change in our own land.  Fuel prices begin to rise.  Some products begin to disappear from our shelf.  The impact of lost markets and merchandise in one country have rippling effects around the world, and we all must work to address the challenge of change.  These are interesting times.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>It is time to work together.</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">What serves us best during interesting times is a nation, and its government united in finding the best way to move forward.  We have seen some rare examples of that in the United States as strong economic sanctions against Russia have been called for by elected leaders normally divided and dismissive of each other on principle.  Will it last?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I am afraid that it is only a matter of time before American representatives fall back into their normal cycle of block and attack against members of the other political party.  As concern in the nation rises, volume in the capitol tends to rise as well.  And dialogue tends to fade.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>We are courageous and trust one another.</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But in our swarm, Common Sense for Uniting America, we are courageous, and we trust one another. If something goes horribly wrong, we deal with it then, and only then. We are never nervous in advance. Everything can go wrong, and everything can go right. We are allowed to do the wrong thing, because otherwise, we can never do the right thing either.  These are the principles we would like to see in our government and throughout our land, and we are working everyday to make that happen.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>This week in the swarm.</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">This week we have solidified our directors for our Nevada non-profit and will move forward toward establishing it.  We have had more amazing and created individuals join our team filled with great ideas, courage, and readiness for the future.  Now is a great time to be in the swarm and watch it grow.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Our draft legislation <a href="https://www.nvsos.gov/sos/home/showpublisheddocument/10224/637819264190530000">(C-05-2022)</a> continues to go through its 15 business days of public review, ensuring that all Nevadans are comfortable with what we are offering them.  The deadline for a complaint comes on the 23rd of March, and we hope that all Nevadans have had a chance to read it through.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>What MaxVoting Offers.</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">MaxVoting offers to end our back-and-forth stagger between the political left and political right, which gives our state and nation the appearance of a drunkard trying to find their way home. With MaxVoting, Nevada instead will be able to set reasoned goals supported by the majority opinion of the voters and move forward quickly and directly to achieve them—exactly what interesting times call for.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>But how long do we have?</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">However, the clock is ticking, and certainly the next event, be it inflation or long gas lines, will fan again the partisan flames.  We need you to register your support today at MaxVoting.org.  Our future is waiting.

            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0060;
