import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0052 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.12.31</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>Common Sense for Uniting America</title>
                <strong>This We Do to Ourselves</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The one idea I want you to take away from my presentation today is that we are politically divided as a nation, because we divide ourselves. And if we stop dividing ourselves, we will no longer be divided; it's common sense for uniting America, but it maybe isn't obvious, which is why I'm here. But once you see it, it's like watching a person hammering their hand over and over. Their hand hurts, and they want the pain to stop. But we they can't figure it out.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">So there I am, in the shower, getting ready for work. My wife comes in to the bathroom.  She is crying, almost sobbing, and she manages to sputter out, “I think he's going to win.”  The date is November 9th, 2016, and President Donald Trump is about to win the election. My wife is highly educated, a former Latin instructor, and has lived in places around the world.  She sees President Trump's election as a complete betrayal of women, and anticipates a great decline for the United States.  On 17 Jan 2017, hundreds of thousands of women march in protest throughout America over the election of President Trump.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Now I'm retired from the military.  I work as a contractor in the United Kingdom.  I'm lying on my bed talking to my mother on the phone.  She is sad and greatly concerned.  It is November 2020, and President Joe Biden has just been elected. My mother is highly educated, taught English in Middle and High School for many years, and has lived in places around the world. She senses in this election the coming collapse of our Country, as well as the coming final apocalypse of all humanity. On the sixth of January, 2021, a mob of protesters attacked the U.S. Capitol Building trying to stop the election of President Biden.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We experience politics, on a personal level, as an all or nothing game.  When a candidate wins a 51% majority, they have demonstrated two things.  First, they can ignore the 49% minority and still be elected, and second, they must keep faith with their 51% majority to be re-elected.  When we see ourselves as part of that ignored minority, we act so to ensure we can't be ignored. We march, we riot, we attack.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">When we restrict each voter to choosing one faction, one candidate, and have the most picked candidate win, as we do in our current voting method, plurality voting, we perpetuate our own division.  Ranked-choice voting is no different. Remember the stated goal of ranked-choice voting is to ensure a candidate has 50% ruling majority, which likewise ensures we create an ignored minority.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Is there another way?</Col></Row><p />




        </Container>
        </>
    )
}

export default Blog0052;
