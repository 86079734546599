import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0044 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.11.05</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>max voting, our why</title>
                <strong>Our why.</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                COMMON SENSE FOR UNITING AMERICA (#CmnSens) teaches Americans how to take and keep control of their government, have their voice heard, and ensure their nation, community, and government remains unified and functioning.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We want to live in a society where our government hears every American's voice, regardless of what that American chooses to say, so we can discover the majority opinion of the voters and have the true will of the people guide our politicians and representatives. We create such a society when we institute a #maxvoting method.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">#minvoting methods ultimately assign one voter to one candidate (even if they do so by first taking in a voter's ranked-choice of preferred candidates). By doing so, these methods identify which faction was able to make itself the most dominant. Because the voting methods ultimately restrict voters to supporting one candidate, candidates and representatives have an incentive to create the largest possible faction and ensure it maintains primacy.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">#maxvoting methods record and use how much each voter independently desires each candidate to determine a winner. By doing so, these methods reveal what the people truly desire. Because the methods empower voters to have an independent impact on all candidates, candidates and representatives have an incentive to align their political platform and effort with the will of the people.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">#Minvoting methods have created the divided, gridlocked, gerrymandered society we have today; #maxvoting methods will produce the united, functioning, responsive society we need to effectively respond to the challenges we face now and into the future.
            </Col></Row><p />

        </Container>
        </>
    )
}

export default Blog0044;
