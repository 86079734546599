import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { getSession, setSession } from "../../../services/session";
import { changeAccountData } from "../../../services/account";
import { LoadingSpinner } from "../../common/LoadingSpinner";
import "./ChangeuserNames.css";

export class ChangeuserNamePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      error: {
        userName: "",
        form: "",
      },
      success: "",
      loading: false,
    };
  }

  setuserName(value) {
    const { userName } = this.props.session;
    if (value === userName) {
      this.setState((state) => ({
        ...state,
        userName: value,
        error: {
          ...state.error,
          userName: "New userName can't be same as old one",
        },
      }));
    } else
      this.setState((state) => ({
        ...state,
        userName: value,
        error: {
          ...state.error,
          userName: "",
        },
      }));
  }

  async changeuserName() {
    // Reset error state
    this.setState((state) => ({
      ...state,
      error: { ...state.error, form: "" },
      success: "",
      loading: true,
    }));

    // Perform API call
    const data = { userName: this.state.userName };
    const response = await changeAccountData(data);
    if (response.data) {
      const session = { ...getSession(), userName: data.userName };
      setSession("session", session);
      this.setState({
        success: "userName changed successfully",
        userName: "",
        loading: false,
      });
      this.props.sessionUpdated();
    } else {
      this.setState((state) => ({
        ...state,
        error: {
          ...state.error,
          form: response.error,
        },
        loading: false,
      }));
    }
  }

  render() {
    const { error, success } = this.state;
    return (
      <>
        <LoadingSpinner loading={this.state.loading} />
        <Modal
          isOpen={this.props.isVisible}
          toggle={this.props.reverseIsVisible}
          centered={true}
          modalClassName="login-wrapper"
          className="login-modal"
        >
          <ModalHeader
            toggle={this.props.reverseIsVisible}
            cssModule={{ "modal-title": "w-100 text-left mb-0" }}
          >
            <div className="d-flex">
              <p style={{ textAlign: "left", marginLeft: 80, marginBottom: 0 }}>
                Change userName
              </p>
            </div>
          </ModalHeader>
          <ModalBody>
            <Form className="mt-3 change-userName-form">
              <FormGroup>
                <Label for="current-userName">Current user name</Label>
                <Input
                  id="current-userName"
                  name="current-userName"
                  type="text"
                  disabled={true}
                  value={this.props.session.userName}
                />
              </FormGroup>

              <FormGroup>
                <Label for="new-userName">New user name</Label>
                <Input
                  invalid={!!error.userName}
                  name="new-userName"
                  id="new-userName"
                  type="text"
                  value={this.state.userName}
                  onChange={(e) => this.setuserName(e.target.value)}
                />
                {error.userName && (
                  <FormFeedback>{error.userName}</FormFeedback>
                )}
              </FormGroup>

              <Button
                disabled={!!this.state.error.userName}
                color="primary"
                onClick={() => this.changeuserName()}
              >
                Change
              </Button>

              {error.form && (
                <div className="mt-2">
                  <span style={{ fontSize: "14px", color: "red" }}>
                    {error.form}
                  </span>
                </div>
              )}
              {success && (
                <div className="mt-2">
                  <span style={{ fontSize: "14px", color: "green" }}>
                    {success}
                  </span>
                </div>
              )}
            </Form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
