import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0048 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.12.03</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>How to end division politics</title>
                <strong>The End of Majority Rule</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                To avoid political division, we must not divide our population.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">That sounds obvious, but obviously it’s not, because we divide ourselves into a ruling majority and an ignored minority.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">American’s love the idea of majority rule because we have these three beliefs:
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">    1.	I’m right
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">    2.	I’m moderate
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">    3.	I’m in the majority.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">What we don’t understand about the political majority is:
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">    1.	The majority and minority only differ in size by one to two percent. (51% to 49%)
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">    2.	When a candidate wins a 51% majority, they demonstrate two-things, 1) they can ignore the 49% minority and be elected, and 2) they must take care of the 51% majority to be re-elected
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">    3.	A 51% majority has the power to do what they want, including manipulating the law to allow them to stay the majority.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Majority rule is the problem.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We cause that problem when we force voters to choose one candidate (min voting). This remains true even if it happens iteratively such as instant-runoff voting, ranked-choice voting, preferential voting, top four/top five voting and single transfer vote voting. One voter, one candidate.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">To avoid political division, we must not divide our population.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">When we empower voters to evaluate all candidates (not choosing one), and use all of those evaluations to find the winner, (max voting) we drive candidates to seek and support the majority opinion.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">In any group of people, there exists, one and only one majority opinion on any issue. In max voting all candidates seek and support that single majority opinion because doing so increases their chances of winning.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">When we are ruled by the majority opinion:
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">    1.	All candidates seek out the same political platform
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">    2.	The difference between parties begins to blur and disappears
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">    3.	There are no majorities capable of gerrymandering or ensuring their own survival

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">    •	We should avoid division by not dividing.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">    •	We should be ruled by the majority opinion, not a majority.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">    •	We must replace min voting with max voting.
            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0048;
