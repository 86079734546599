import axios from "axios";
import { getSessionToken } from "./session";

const BE_URL = process.env.REACT_APP_BE_URL;

export const changeAccountData = async data => {
  const url = `${BE_URL}/api/supporters/me/supporters`;
  let response;
  try {
    response = await axios.put(url, data, {
      headers: {
        "x-auth-token": getSessionToken()
      }
    });

    return { data: response.data };
  } catch (e) {
    return { error: (e.response && e.response.data) || e.message };
  }
};

export const deleteSupporter = async () => {
  const url = `${BE_URL}/api/supporters/me/supporters`;
  try {
    const response = await axios.delete(url, {
      headers: {
        "x-auth-token": getSessionToken()
      }
    });
    return { data: response.data };
  } catch (e) {
    return { error: (e.response && e.response.data) || e.message };
  }
};
