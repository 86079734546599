import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0041 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.10.15</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>What is your problem?</strong><p />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Before you consider whether or not to support Ranked-Choice Voting (RCV) ask yourself, “what is the problem I am trying to solve?”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">If your problem is that you like election runoffs but can’t afford them, then RCV (also known as instant runoff voting, preferential voting, top-four voting, top-five voting) has something to offer.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But, if your problem is that your nation is polarized and your society is becoming more divided (remember that civil war we had?), that your government is gridlocked and dysfunctional, that your elections are controlled by two overpowered political parties that lockout third parties and independents, that your election districts are hopelessly gerrymandered, or that you, the voter, have less influence over the direction of the government than special interests and political parties, than, no, RCV has nothing to offer.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But max voting does. And max voting ends requirements for runoffs (and primaries!) as well.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">RCV was designed to end expensive run-off elections. According to the actual information provided to voters by the city for Proposition A in 2002:
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">‘<a href="http://www.smartvoter.org/2002/03/05/ca/sf/prop/A/">Proposition A</a> will allow San Francisco to elect candidates supported by a popular majority without needing expensive, low-turnout December runoff elections. This will SAVE $2 MILLION TAX DOLLARS PER YEAR, RAISE VOTER TURNOUT and REDUCE NEGATIVE CAMPAIGNING. Runoffs are costly to taxpayers. The December runoff for city attorney cost nearly $2 MILLION, an average of $29 per voter.’
            </Col></Row><p />

            <Row ><Col xs="12" sm="12" md="12" className="main">RCV was never designed or intended to deliver the voter’s most desired candidate. To do that would require the election system to know how a voter feels about every candidate and then to sum those feelings for each candidate across the entire voter population. RCV doesn’t know this and can’t do this.  RCV records one voter to one candidate, just like plurality voting.  It doesn’t even use all the information the voter provided to the method.  It only looks at anything beyond a voter’s first choice if that first choice is eliminated, and then it simply assigns that voter to their next most desired candidate on their list <a href="https://www.youtube.com/watch?v=yCj7li7Ifv4&t=3s">WHO HAS NOT YET BEEN ELIMINATED.</a> This system can deliver some of the least desired candidates.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><a href="https://www.youtube.com/watch?v=HRkmNDKxFUU">Max voting delivers the most desired candidate of the voters every time.</a> This happens because it takes in max voter input.  Every voter provides their full, unfettered opinion on every candidate, who they like, don’t like, and how much. Then max voting uses all of that information to determine the winner ensuring max voter influence over the result. Because every voter can impact all candidates simultaneously, all candidates are incentivized to align their platform to where the most voters are, the majority opinion, the true will of the people. Max voting allows us to discover and choose the will of the people.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">With the election process giving a disproportionate advantage to candidates who align their platforms with the will of the people political polarization is severely curtailed, campaign rhetoric is toned down, and party lines blur. With party lines blurred and more independents and third-party candidates being elected the two-party system (in fact, the political party system) ends. When political parties lose majorities, they lose the ability to gerrymander and gain an incentive to undo gerrymandering.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">There is no reason to ever choose RCV over max voting–any kind of max voting. Approval voting, STAR Voting, simple score voting, any variation delivers on all the promises of max voting and vastly outperforms RCV.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">RCV is unnecessary at best and dangerous at worst. The only people who should advocate for it are employees of the Democratic National Committee and the Republican National Committee. It is dangerous as it makes things look better (less fighting, yea!) without making them better (political parties still control the ballot, ah poop!).
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Ranked Choice Voting is a bad choice. Ranked Choice Voting will not solve our national problems. Max voting will.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Max voting. It’s time.


            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0041;
