import React from "react";
import HamburgerMenu from "./hamburgerMenu";
import HamburgerMenuItem from "./hamburgerMenuItem";

import { ChangeuserNamePage } from "./pages/Account/ChangeuserNames";
import { ChangePasswordPage } from "./pages/Account/ChangePasswords";
import { ChangeuserNotificationsPage } from "./pages/Account/ChangeNotifications";
import RevokeSupport from "./pages/Account/RevokeSupport";

export default class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeuserName: false,
      changePassword: false,
      changeuserNotifications: false,
      revokeSupportDialogVisible: false,
    };
    this.reverseVisibility = this.reverseVisibility.bind(this);
    this.reverseChangePassword = this.reverseChangePassword.bind(this);
    this.reverseChangeuserName = this.reverseChangeuserName.bind(this);
    this.reverseChangeuserNotifications = this.reverseChangeuserNotifications.bind(
      this
    );
  }

  handleLinkClick(page) {
    this.props.toggleHamburgerMenu();
    this.setState({ [page.value]: true });
    this.props.setNavigation(page.link);
  }

  handleRevokeSupport() {
    this.setState({ revokeSupportDialogVisible: true });
    this.props.setNavigation("account/revoke-support");
  }

  reverseVisibility() {
    this.setState({
      revokeSupportDialogVisible: !this.state.revokeSupportDialogVisible,
    });
  }

  reverseChangePassword() {
    this.setState({ changePassword: !this.state.changePassword });
  }

  reverseChangeuserName() {
    this.setState({ changeuserName: !this.state.changeuserName });
  }

  reverseChangeuserNotifications() {
    this.setState({
      changeuserNotifications: !this.state.changeuserNotifications,
    });
  }

  render() {
    const menu = [
      {
        caption: "Change user name",
        link: "account/change-userName",
        value: "changeuserName",
      },
      {
        caption: "Change password",
        link: "account/change-password",
        value: "changePassword",
      },
      {
        caption: "Change notifications",
        link: "account/change-userNotifications",
        value: "changeuserNotifications",
      },
      {
        caption: "Revoke support",
        link: "account/revoke-support",
        value: "revokeSupportDialogVisible",
      },
    ];

    const menuItems = menu.map((val, index) => {
      return (
        <HamburgerMenuItem
          key={index}
          delay={`${index * 0.1}s`}
          onClick={() => this.handleLinkClick(val)}
        >
          {val.caption}
        </HamburgerMenuItem>
      );
    });

    return (
      <>
        <RevokeSupport
          isVisible={this.state.revokeSupportDialogVisible}
          reverseIsVisible={this.reverseVisibility}
          offsetTop={this.props.offsetTop}
          session={this.props.session}
          sessionUpdated={() => this.props.sessionUpdated()}
          goToPage={this.props.setNavigation}
        />
        <HamburgerMenu
          open={this.props.hamburgerMenuOpen}
          offsetTop={this.props.offsetTop}
        >
          {menuItems}
        </HamburgerMenu>
        {this.renderPage()}
      </>
    );
  }

  renderPage() {
    const { currentPage } = this.props;
    switch (currentPage) {
      case "account/change-userName":
        return (
          <ChangeuserNamePage
            isVisible={this.state.changeuserName}
            reverseIsVisible={this.reverseChangeuserName}
            session={this.props.session}
            sessionUpdated={() => this.props.sessionUpdated()}
          />
        );
      case "account/change-password":
        return (
          <ChangePasswordPage
            isVisible={this.state.changePassword}
            reverseIsVisible={this.reverseChangePassword}
            session={this.props.session}
            sessionUpdated={() => this.props.sessionUpdated()}
          />
        );
      case "account/change-userNotifications":
        return (
          <ChangeuserNotificationsPage
            isVisible={this.state.changeuserNotifications}
            reverseIsVisible={this.reverseChangeuserNotifications}
            session={this.props.session}
            sessionUpdated={() => this.props.sessionUpdated()}
          />
        );
      default:
        return null;
    }
  }
}
