import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0064 = () => {
    const [image] = useState(logo);

    return (
        <>
            <Container>
                <Row><Col>
                    <img className="image" src={image} alt="" />
                </Col>
                </Row>
                <Row><Col className="date">22.04.09</Col></Row>
                <p />
                <Row ><Col xs="12" sm="12" md="12" className="title">
                    The Weekly Heart Beat
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><title>MaxVoting Update</title>
                    <h1>MaxVoting and Division Free Voting How to Help</h1>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>We have had another great week!</h2>

                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">We released another video that you can see on this page.  More progress toward the 501(c)3 formation, and meet ups with other great movement leaders.

                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">I want to invite everyone to send me their questions so that I can turn them into MaxVoting Minutes.

                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Here are some things you can do to support us:</h2>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">1) register your support.
                </Col></Row>
                <Row ><Col xs="12" sm="12" md="12" className="main">2) Learn more about MaxVoting through our videos, substack articles, and draft book. Our <a href="mailto:mailbox@commonsenseforunitingamerica.org">outreach team</a>  can help
                </Col></Row>
                <Row ><Col xs="12" sm="12" md="12" className="main">3) write an article for a publication. Our <a href="mailto:bev@commonsenseforunitingamerica.org">communications team </a>for support.
                </Col></Row>
                <Row ><Col xs="12" sm="12" md="12" className="main">4) write a letter to an editor, just do it. (especially in NEVADA where our draft legislation is at.)
                </Col></Row>
                <Row ><Col xs="12" sm="12" md="12" className="main">5) echo our social media channels:
                </Col></Row>
                <Row ><Col xs="12" sm="12" md="12" className="main"><a href="https://www.youtube.com/channel/UCqHP9kv-hN7E-AvU9bEq06Q">Youtube</a>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><a href="https://www.facebook.com/MaximumVoting">FaceBook</a>

                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>What is MaxVoting?</h2>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">"MaxVoting is how you vote to guarantee your government cannot
                </Col></Row>
                <Row ><Col xs="12" sm="12" md="12" className="main">silence your voice,
                </Col></Row>
                <Row ><Col xs="12" sm="12" md="12" className="main">ignore your hope, or
                </Col></Row>
                <Row ><Col xs="12" sm="12" md="12" className="main">limit your future."
                </Col></Row><p />
            </Container>
        </>
    )
}

export default Blog0064;
