import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0055 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">22.01.28</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>Voter Control</title>
                <strong>Why it makes sense that your second choice should hurt the chances of your first choice.</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I often hear people say, when I note the necessity to adopt MaxVoting methods, “I like that the fact that identifying my second choices lessons the chance of my first choice winning.”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I flew EP-3s in the navy.  Those are large plans with four engines.  In the cockpit, I had four power levers, one for each engine.  When I pushed one forward the engine torque would increase and the plane would go faster. When I pull it back, the torque would decrease and the plane would go slower. I could actually steer the plan by moving all the power levers independently.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">That is a good analogy for MaxVoting methods.  The higher you rate any candidate, the more you increase their chance of winning.  The lower you rate any candidate, the more you increase the chance of their losing.  By necessity, if you increase the chance of one candidate winning, you, by definition, must increase the chance all the other candidates losing, and vice-versa.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">There were times, when I was flying, that, when we moved the power lever up, nothing would happen.  And when we moved it back, nothing would happen.  We called these times, emergencies, because we could not control the aircraft.  This is a good analogy for plurality and ranked-choice voting.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">With plurality voting, it is like you get to pick one engine and put it at maximum; there is no intermediate stage.  It doesn’t matter what you do with any other power lever, they are completed ignored.  Ranked-choice voting works exactly the same way, except the engine you put the power up on may fail completely at which time you can pick another engine to go max power on, until you are finally down to only two.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">It makes sense to me to have maximum control over who I want to be elected by adjusting ratings in a MaxVoting system.  If I don’t want a candidate to have a better chance of winning, why would I rate them higher?
            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0055;
