import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0040 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.10.08</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>About the Author</strong><p />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                I was born and raised in Nevada living most of my life in Carson City.  When I attended high school there, the state had a program called Boy’s State (it also had a Girl’s State).  This week-long camp aimed to take a group of about a hundred high school seniors and provide them hands-on-experience in U.S. and Nevada systems of government. Imagine Lord of the Flies meets All the King’s Men.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The camp had many lectures, and attending boys volunteered to be candidates for the week.  We were broken up evenly into Democrats and Republicans.  We ran campaigns, made speeches, etc.  It was as much fun there in that pretend society as it is in our real society.   The camp culminated in a debate between two real Nevada State legislators, one Democrat and one Republican.  All of us boys had crammed onto bleachers under a tent with the representatives standing out in front of us at thin wooden podiums.  The hot tent smelled like sage brush and sweat.  After their debate they asked, “Does anyone have a question?”  I had a question.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I had seated myself at the back beside a friend I had made earlier in the week.  I remember him as a blonde-haired kid with a friendly demeanor, who, like me, did not seek attention.  We both preferred to watch from the safety of the sidelines. But I did have a question I wanted to ask so I raised my hand.  After a few other questions, the Democrat looked up and called on me.  I stood and asked the Democrat, “Sir, do you desire to serve and do right by the people of Nevada?”  He said, “yes,” which I thought was a good answer. I then turned to the Republican, “Do you, sir, desire to serve and do right by the people of Nevada?” The Republican said, “Hell, yes!” He was, after all a Republican.  And then I asked what had been nagging at me all week. “If you both have the same goal, why do we have two parties that continually fight each other such that little gets accomplished?”  For a good second no one spoke or moved, and then I heard murmurs and some laughter.  My friend, turned to the boy on his other side and said, “I don’t know him.”  The politicians stuttered out something about differing opinions and have different views on what was best for the people and how to achieve it. But no true answer.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I may have only been a high school senior, but I know that there are more than two ways to solve a problem or see a situation. At least 100 students sat on those ribbed metal bleachers in that oven-like tent.  Did all 100 of us not have differing ideas on what was best? The politicians didn’t know why we had divided ourselves into two warring camps. They made their living fighting each other, and they didn’t know why, it was just what you did.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">After graduation I attended the University of Washington on an ROTC scholarship.  I received my commission as an ensign and would serve the next 28 years in the U.S. Navy.  I spent the first half of my career as a pilot and spent the second half of my career working with foreign militaries and governments.  This allowed me a glimpse of how other countries view us, beneath their normal political veneer.  While serving as a Foreign Affairs Officer with the European Headquarters, I had the opportunity to travel to Baku and meet some of our military staff from the Embassy.  We had various programs and projects that we did with Azerbaijan, but at the moment, as we were getting close to the 2016 elections, decisions on the funding we were seeking for programs that would execute in 2018 and 2019 were being deferred as we did not know what the new administration would desire.  One of our Azeri counterparts shook his head at hearing this and asked, “why don’t you know what you will do next year?”  We explained our elections and how we just had to wait and see, and he retorted, in more of a philosophical way than a harshly critical one, “that is the problem with America, you never know what you will do just a few years down the road.  Everything can just change instantly.”  Then one of our group, thankfully not me, threw out Churchill’s chestnut about how Democracy is the worst form of government except for all the others.  Our counterpart had a response.  “Really.  We know what we’re going to do, next year, and the year after that, and the year after that.  The Russian’s know what they are going to do five years, ten years from now.  The Chinese know what they are going to do fifty years from now.”  He had a point.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I remember reading in Svetlana Alexievich’s amazing book Second-hand Time, a collection of interviews of individuals who lived through the time that the Soviet Union transformed from a nation under Stalin, through perestroika, glasnost, collapse, capitalism, nascent democracy and finally to Putin’s Russia.  Two things remain with me from that book.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The first was the amount of suffering experienced by the people of the Soviet Union.  Hearing it in their own words, made it sound unbearable. The authorities whisked friends and neighbors away nightly. Families had to play loud music in the kitchen so they could talk softly and not be over heard.  A woman, as state police were taking her away to a gulag, asked her neighbor to take care of her daughter when she was gone.  She returned from the gulag 18 years later, her neighbor having raised her daughter into a woman. She then learned it had been her neighbor who had turned her in to the authorities in the first place.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The second thought that has never left me was how strongly those same people, who went through the all those horrors, wanted to return to that state.  They thought longingly of the days of Stalin when they knew what to expect, and knew how to work within their system.  They could plan.  That book illustrated to me how people need stability and direction.  In the United States, the will of the people does not change dramatically every two or four years.  In fact, we see strong continuity in Americans over spans of ten, twenty or even fifty years.  Our society changes mildly, not in the radically veering manner that our government does.  How can this be if the American people are the basis for the government?

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">November 4th 2016 found me in Stuttgart Germany with my wife of 25 years and two of our younger children.  That morning as I stood in the shower preparing for another day at work, my wife came in, sobbing.  “I think he’s going to win,” she said.  She had watched as the election results came in, and it had become clear that Donald Trump would be the next President of the United States.  She sat sobbing knowing that the worst possible fate had befalling us and America had rejected its best hope for the future. The nation had turned its back on its possible first woman president.  One candidate represented hope, the other horror.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I spoke to my mother a short time later and she was relieved at the results of the election.  The United States had dodged a bullet and kept a criminal out of office. She could rest easier, at least for at least the next four years. One candidate represented hope, the other horror.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We are cautioned not to discuss sex, religion, or politics in polite conversation. Is that the natural state of affairs?  Can we not discuss politics because it deals with problems, or choices? Why is politics an all or nothing game? Why must one candidate always be a saint and the other a scoundrel.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">My wife sees herself as moderate, not an extreme liberal by any stretch. She has a brother who she sees as being on the far right to the point of extreme.  He sees himself as a moderate (I have come to believe that every person sees themselves as a moderate) but sees her as an extreme liberal to the point of blocking her on social networks.  Both will describe the negative impact and nefarious influence of media sites, how news outlets and websites feed the false narratives of the other side; but when they speak of it, they mean the other one’s media.  As a former member of the military working with our allies and partners with extensive experience in the intelligence world, I can personally attest to how easy it is for our adversaries to reach into the cracks in our society and rend us apart.  But it is we who make those cracks.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Four years after that 2016 election I had moved to the United Kingdom and in January of 2021, I watched on British Television as hundreds of protesters became rioters attacking the U.S. capitol building, something last done in 1810, and by a foreign enemy.  The perpetrators took selfies of themselves, proudly identifying themselves. They wanted to be seen, and they wanted to be heard.  The next president, congress and senate would all be controlled by the opposing party—their enemy, and they understood that despite all the calls and promises of the president for unity, their voice would not be heard, at least not until the mid-terms.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">A presidential election in 1860 cemented the minds of Southern State Leaders.  Seven southern states had seceded from the Union before Lincoln had even been sworn into office.  The actions of relatively few in 2021 may seem a far cry from the succession of seven states in 1861, but President Trump, the individuals these rioters had sought to support, had received the votes over almost half the population of the country.  We are and have always been evenly divided against ourselves.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">If America could focus its energy and spirit into solving its problems rather than fighting itself, what could we achieve?  For 240 years we have attacked ourselves, cheated ourselves, and slander ourselves.  In 1969 the United States landed humans on the moon and brought them safely home.  How much more could we have achieved if we hadn’t squandered so much time and resources in self-loathing and destruction?  What could we do if we could plan ten, twenty, fifty years ahead?

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">For years, I have sought the answer to the question, what divides America, and how can we stop it. Looking at the actions of individuals from a systemic point of view, where people act to help themselves, where rules and laws shape their behavior, it appears to me now that we have unwittingly created the very system that divides us.  I believe it would divide us even if we are aware of it.  I believe it would divide us even if we all held ourselves to the highest selfless moral calling.  But our condition is yet worsened as we neither notice it, nor think beyond our own self interests.  As such, I argue that our only hope in avoiding repeated civil catastrophes is to modify that system so that our nation naturally leans toward unification over division, even as we all look to our own self-interests.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">In this book I argue that it is not people, parties, personalities, the media, the money, or the moment that tear us apart, but rather a simple set of laws, easily changed at negligible cost.  If we act and make the small changes, in every state, we will move forward, faster, together, and become able to take on whatever awaits us.  We can go so much further working together than we ever can tearing ourselves apart.



            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0040;

