import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0011 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.03.12</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>Giving a strong presentation on score voting at Vote Nevada.  Working on the inspirational video.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>No updates.  But I now have another potential re-look on the book.  I completely botched the “strong presentation” to the Vote Nevada group, but that was just a teaser for July (and a wake-up call for me).  Despite spending hours (seriously, upwards of 20 or so) in the few days prior to the event, I lost my train of thought, twice, completely blanked out on parts, and this was just a 5 min, no-threat event (and it’s been recorded and posted on the internet—sad.)  But it also gave me some new ideas. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>The cover video is very close to completed.  There are a couple of minor tweaks, and it should be ready.  All of the major score voting groups are highlighted, and it feels like just enough of a teaser to get people to try the sites. The new idea I had was on the idea of MO (Majority Opinion). Getting MO, needing MO, etc.  </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>On the MO idea, I am envisioning a live action piece, an article, and an update to the book based on it.  The concept seems so easy and pithy that I think that it can serve as my central vehicle for the message.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I think the cover video will be done this upcoming week.  I will have a full work schedule, but am hoping to have a script for the live-video done, and maybe a first draft of the article for Democracy Chronicles.  The pressure to fix the “find my precinct” portion of the website is building, but not quite there yet. </Col></Row>

        </Container>
        </>
    )
}

export default Blog0011;
