import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0013 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.03.26</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>The rewrite of the book is done.  The MO article is pretty much done.  The one-pager is pretty much done.  And the business card is close to done.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>All of the elements I had hoped to update and add to the book I completed.  I also had some important conversations with significant stakeholders about the future progress of the book.  I will hold up for another week or so until the interested parties can look at the material and consider the next steps.  It may not sound like I made progress, but the project overall has moved forward.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>I have again looked at Equal.vote and it does clearly endorse just STAR Voting.  I’m sure those advocates would approve of score voting (no pun intended), but I don’t feel they will advertise that.  We had three more people join the movement last week which I think constitutes a record.  Especially as I don’t know any of them.  I’m doing training on <span><a href='https://codewithmosh.com//'>https://codewithmosh.com</a></span> to try to improve the website overall and while waiting for movement on the book, I feel that will be a near-term focus.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>I find I need to construct an argument, even to other proponents of score voting, to demonstrate how score voting delivers the most desired candidate.  I will make that a focus this week.  I need to properly lay out the argument in such a way that people, even agreeable ones, find it compelling.  It seems everyone gets skittish when I say that score voting always delivers the most desired candidate.  I find in their response also a nodding recognition of the truth in it. I will also submit the MO article for publication and see how that goes.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>Submit MO article to Democracy Chronicles and Vote Nevada crowd.  Improve aspects of the HTML and CSS on the site.  I have a goal of adding a resource (materials like the one pager, the business card) for people and providing strategies to engage others on the topic.  Complete the business card hand out.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0013;
