import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0002 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.01.08</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>I’ve been hunting for a developmental/substantive editor to further develop the book.  So, you know, the author creates the book’s concept, the developmental or substantive editor help turn that idea into a book that people will understand and (hopefully) seek out.  The copy editor then makes sure it reads correctly (grammar, spelling, word choice), then a layout artist devises the font, spacing, graphics, etc., of the actual book.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>The search to find a developmental editor has been nothing short of inspirational.  I received responses from many highly qualified, enthusiastic editing professionals offering their services toward this great work; it has become almost painful to choose among them.  If you ever want to find a developmental editor, I recommend using the Editor’s Freelancers Association: <span><a href='https://www.the-efa.org/'>https://www.the-efa.org/</a></span>. By the end of the weekend, I’ll conclude selecting the developmental editor and begin this stage of the book’s development.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>I have plans to develop a movement-specific website.  This Swarm Age base site is purpose-made to provide the goals and the current state of the movement to supporters and swarmers. I feel we need a further website that helps individuals comprehend: what this movement is about, how they can help (becoming a supporter or swarmer), who they can contact, and where they can donate.  That will be the four-fold focus of the new site, built on more commercially sound, modern website practices as it will not have to do the heavy-lift work of the Swarm Age site.  To this end, I’ve secured an additional domain, scorevoting.us, which I think provides all the needed focus (on score voting systems United States-wide) and is also easy to remember. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>I continue to develop the TED talk script and received excellent Feedback from Damon Brown at Heroic Public Speaking (<span><a href='heroicpublicspeaking.com/'>heroicpublicspeaking.com/</a></span>). I have started to adjust it based on his sound comments.   By the way, HPS is having their fantastic, free, live-stream 1-2 February.  I highly encourage you to attend this event.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>This time next week, we will have a developmental editor.  I shall have provided an updated version of the TED Talk script to Damon and continue my work on making a few more tweaks to the scorevoting.swarmage.net website.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0002;
