import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0010 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.03.05</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>The instructional video was completed and uploaded and is now available on-line <span><a href='https://www.youtube.com/watch?v=HRkmNDKxFUU&t=161s'>https://www.youtube.com/watch?v=HRkmNDKxFUU&t=161s</a></span>.  The book has been updated.  A new opportunity to present score voting to Vote Nevada <span><a href='https://vote-nevada-blog.org/'>https://vote-nevada-blog.org/</a></span> came up this week, and the creation of that presentation consumed most of my time.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>No updates.  However, in creating the presentation material for Vote Nevada, I have identified a new framing of the deeper issue and I want to ensure the book contains that as well.  So, I will work on another update, and then re-engage on the issue of the developmental editor.  </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>The cover video is progressing.  In its third draft now.  The Saturday Vote Nevada meeting is just to give a taste of the July discussions which will focus on the Nevada mid-term work.  I will reimage my other presentations and keep moving on the book.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>I want to create an article based on the ideas of my five-minute presentation to Vote Nevada, depending on how it is received.  I think the idea of ensuring the government represents the majority opinion instead of a numerical majority can be a strong line of reasoning to pursue.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>Work will consume most of my time this week.  I will present at Vote Nevada, provide input for the “inspirational video,” look at modifications to the book and potentially work on the article. </Col></Row>

        </Container>
        </>
    )
}

export default Blog0010;
