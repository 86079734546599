import React from "react";

const PP = () => {
    return (
      <div
        align="full"
        style={{
          width: "90%",
          maxWidth: "1000px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <br />
          <br />
          <p align="center">
            <strong>
              <u>
                SWARM AGE LLC
                <br clear="all" />
              </u>
            </strong>
          </p>
          <div align="center">
            <hr size="0" width="100%" align="center" />
          </div>
          <p align="center">
            <strong>
              <u></u>
            </strong>
          </p>
          <p align="center">
            <strong>PRIVACY POLICY</strong>
            <br />
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            This policy describes how Swarm Age, LLC ("COMPANY," "we," "us," or
            the "Company") collects, aggregates, stores, safeguards and uses the
            data and information (including non-public personal information, or
            "NPI") provided by users through our websites{" "}
            <a href="http://www.swarmage.net/">www.swarmage.net</a> and{" "}
            <a href="http://www.swarmage.org/">www.swarmage.org</a> (the
            "Site"), as well as information collected by us through other means,
            including by email, over the phone, or in offline communications.
            This Site is operated by the Company and has been created to provide
            information about our company, products, and services (together, the
            "Services").
          </p>
          <p>
            <strong>
              We take your privacy and the security of your information
              seriously.
            </strong>
          </p>
          <p>This policy explains:</p>
          <ul type="disc">
            <li>What information we collect, and how we use it</li>
            <li>
              Choices you can make about the way your information is collected
              and used
            </li>
            <li>
              How we protect personal information electronically and physically
            </li>
          </ul>
          <p>
            <strong>
              This policy is incorporated into and a material term of your
              registration and/or use of COMPANY's Site pursuant to our Terms of
              Use. By using the Site or Services, you consent to the practices
              set forth in this Privacy Policy.
            </strong>
          </p>
          <p>
            <strong>INFORMATION WE COLLECT</strong>
          </p>
          <p>
            <strong>Information You Provide to Us</strong>
            <strong></strong>
          </p>
          <p>
            COMPANY collects information from you when you choose to provide it
            to us through the Site or through any other means. This may include
            when you create an account on the Site, register or request products
            or services, request information from us, sign up for newsletters or
            our email lists, use our Site, or otherwise contact us.
          </p>
          <p>
            The information we collect may include your user name, account
            password, email address, self-identified state, self-identified
            county, and self-identified precinct.
          </p>
          <p>
            <strong>Information We Automatically Collect</strong>
            <strong></strong>
          </p>
          <p>
            We may use cookies or other technologies to automatically collect
            certain information when you visit our Site or interact with our
            emails. For example, if you are responding to a campaign offer,
            promotional email or other email from us, we may automatically
            populate your personal information into our system once you enter
            your identifying device or otherwise accept your campaign.
            Additionally, we may automatically collect certain non-personal
            information from you such as your browser type, operating system,
            software version, and Internet Protocol ("IP") address. We also may
            collect information about your use of the Site, including the date
            and time of access, the areas or pages that you visit, the amount of
            time you spend using the Site, the number of times you return,
            whether you open, forward, or click-through emails, and other Site
            usage data.
          </p>
          <p>
            You may adjust your browser or operating system settings to limit
            this tracking or to decline cookies, but by doing so, you may not be
            able to use certain features on the Site or take full advantage of
            all of our offerings. Check the "Help" menu of your browser or
            operating system to learn how to adjust your tracking settings or
            cookie preferences. Please note that our system may not respond to
            Do Not Track requests or headers from some or all browsers.
          </p>
          <p>
            <strong>
              <br />
            </strong>
          </p>
          <p>
            <strong>HOW WE USE INFORMATION WE COLLECT</strong>
          </p>
          <p>
            COMPANY uses the data and information you provide in a manner that
            is consistent with this Privacy Policy and applicable law. If you
            provide personal data for a certain reason, we may use the personal
            data in connection with the reason for which it was provided. For
            instance, if you contact us by email, we will use the personal data
            you provide to answer your question or resolve your problem. Also,
            if you provide personal data in order to obtain access to the Site
            or Services, we will use your personal data to provide you with
            access to such services and to monitor your use of such services.
          </p>
          <p>
            COMPANY may also use your personal data and other personally
            non-identifiable information collected through the Site or the
            provision of the Services to help us improve the content and
            functionality of the Site or the Services, to better understand our
            users and to improve the Site and the Services. COMPANY and its
            affiliates may use this information to contact you in the future to
            tell you about services we believe will be of interest to you. If at
            any time you wish not to receive any future marketing communications
            or you wish to have your name deleted from our mailing lists, please
            update your account information on the site by revoking support or
            contact us as indicated below.
          </p>
          <p>
            COMPANY may also use the personal email address you provided at
            registration to send important updates on the movement which you
            signed up to support. COMPANY does not intend to send recurrent
            updates to the personal email address you provided. However, Company
            may use the personal email address you provided to seek input on
            your preferences toward the specific legislation being proposed and
            to inform you of key events, such as the commencement of a signature
            campaign, to bring your initiative to the ballot. If, at anytime,
            you do not wish to receive this type of e-mail, please log in to
            your account, open "Change Notifications" and deselect the box.
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            <strong>SHARING OF INFORMATION WE COLLECT</strong>
            <strong></strong>
          </p>
          <p>
            COMPANY is not in the business of selling your information. There
            are, however, certain circumstances in which we may share your
            personal data with certain third parties without further notice to
            you, as set forth below:
          </p>
          <p>
            <strong>
              Agents, Consultants and Third-Party Service Providers:
            </strong>
          </p>
          <p>
            COMPANY, like many businesses sometimes hires other companies to
            perform certain business-related functions. Examples of such
            functions include mailing information, maintaining databases and
            processing payments. When we employ another entity to perform a
            function of this nature, we only provide them with the information
            that they need to perform their specific function.
          </p>
          <p>
            <strong>Related Companies:</strong>
          </p>
          <p>
            We may also share your personal data with our corporate affiliates
            and subsidiaries, if any, for purposes consistent with this Privacy
            Policy.
          </p>
          <p>
            <strong>Legal Requirements:</strong>
          </p>
          <p>
            COMPANY may disclose your personal data if required to do so by law
            or in the good faith belief that such action is necessary to (i)
            comply with a legal obligation, (ii) protect and defend the rights
            or property of COMPANY, (iii) act in urgent circumstances to protect
            the personal safety of users of the Site, the Services or the
            public, or (iv) protect against legal liability.
          </p>
          <p>
            <strong>Third-Party Links </strong>
            The Site may have links to third-party websites, which may have
            privacy policies that differ from our own. We are not responsible
            for the practices of such sites, nor does any such link imply that
            COMPANY endorses or has reviewed the third-party site subject to
            such link. We suggest contacting those sites directly for
            information on their privacy policies.
          </p>
          <p>
            <strong>CHILDREN AND MINORS</strong>
            <strong></strong>
          </p>
          <p>
            COMPANY does not knowingly collect personal data from minors under
            the age of 18. If you are under the age of 18, please do not submit
            any personal data through the Services. We encourage parents and
            legal guardians to monitor their children's Internet usage and to
            help enforce our Privacy Policy by instructing their children never
            to provide personal data without their permission. If you have
            reason to believe that a minor under the age of 18 has provided
            personal data to COMPANY through the Site or the Services, please
            contact us, and we will endeavor to delete that information from our
            databases.
          </p>
          <p>
            <strong>DATA SECURITY</strong>
            <strong></strong>
          </p>
          <p>
            We have taken certain physical, administrative, and technical steps
            to safeguard the information we collect from and about our customers
            and Site visitors. While we make reasonable efforts to help ensure
            the integrity and security of our network and systems, we cannot
            guarantee our security measures. Therefore, you should take special
            care in deciding what information you send to us via email. Please
            keep this in mind when disclosing any personal data to the Company
            via the Internet.
          </p>
          <p>
            <strong>ACCESS TO YOUR PERSONAL INFORMATION</strong>
            <strong></strong>
          </p>
          <p>
            To keep your personal data accurate, current, and complete, please
            contact us as specified below. We will take reasonable steps to
            update or correct personal information in our possession that you
            have previously submitted via the Services.
          </p>
          <p>INFORMATION FOR EEA USERS</p>
          <p>
            <em>What Rights Do I Have?</em>
          </p>
          <p>
            Individuals located in the European Economic Area (EEA) have certain
            rights in respect of your NPI, including the right of access,
            rectification, restriction, opposition, erasure and data
            portability. Where possible, we rely on user consent as a lawful
            basis for processing personal data and obtain such consent in
            compliance with applicable laws. In some cases, COMPANY may process
            NPI pursuant to legal obligation or to protect your vital interests
            or those of another person.
          </p>
          <p>
            <em>How May I Exercise My Individual Rights?</em>
          </p>
          <p>
            COMPANY users may access and update their NPI through their account
            settings in the COMPANY platform. Users located within the EEA may
            contact us with questions or requests regarding their NPI using the
            contact information below. Please note that COMPANY may request
            additional information from you to verify your identity before we
            disclose any personal or account information. We only send marketing
            communications to users we believe to be located in the EEA with
            your prior consent, and you may opt out of such communications at
            any time by clicking the "unsubscribe" link found within COMPANY
            email updates and changing your contact preferences by revoking
            support. Please note, you will continue to receive essential
            account-related information, even if you unsubscribe from
            promotional emails.
          </p>
          <p>
            <em>
              [Who Can I Contact at COMPANY Regarding Data Protection Issues?{" "}
            </em>
          </p>
          <p>
            COMPANY has designated a Data Protection Officer to assist with data
            privacy and data protection issues. You may contact him or her by
            emailing them and addressing your questions or concerns to the Data
            Protection Officer at admin@swarmage.org.
          </p>
          <p>
            <strong>IF YOU HAVE QUESTIONS</strong>
            <strong></strong>
          </p>
          <p>
            If you have any questions about this Privacy Statement or the
            practices described herein, you may contact us at
            admin@swarmage.org.
          </p>
          <p>
            <strong>CHANGES TO THIS STATEMENT</strong>
            <strong></strong>
          </p>
          <p>
            COMPANY reserves the right to revise this Privacy Policy at any
            time. When we do, we will post the change(s) on the Site. This
            Privacy Policy was last updated on the date indicated above. Your
            continued use of the Site and the Services after any changes or
            revisions to this Privacy Policy shall indicate your agreement with
            the terms of such revised Privacy Policy.
          </p>
          <p>
            <strong></strong>
          </p>
        </div>
      </div>
    );
}

export default PP;
