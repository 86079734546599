import React, { Component } from "react";
import { Container } from "reactstrap";
import { getSession } from "./services/session";
import MainPage from "./containers/MainPage";

class Central extends Component {
  constructor() {
    super();
    this.state = {
      currentPage: "home",
      session: getSession(),
      // This object is meant to hold states for different components
      page: {
        hamburgerMenuOpen: false
      }
    };
    this.sessionUpdated = this.sessionUpdated.bind(this);
    this.toggleHamburgerMenu = this.toggleHamburgerMenu.bind(this);

    this.staticHeaderRef = null;
    this.dynamicHeaderRef = null;
  }

  componentDidMount() {
    this.staticHeaderRef = document.querySelector(".static-header");
    this.dynamicHeaderRef = document.querySelector(".dynamic-header");
  }

  sessionUpdated() {
    const session = getSession();
    if (!session) this.setState({ session, currentPage: "home" });
    else this.setState({ session: getSession() });
  }

  toggleHamburgerMenu() {
    this.setState(state => ({
      ...state,
      page: {
        ...state.page,
        hamburgerMenuOpen: !state.page.hamburgerMenuOpen
      }
    }));
  }

  getOffsetTop() {
    if (this.staticHeaderRef && this.dynamicHeaderRef) {
      return (
        this.staticHeaderRef.clientHeight + this.dynamicHeaderRef.clientHeight
      );
    }
    return 0;
  }

  render() {
    return (
      <Container style={{ position: "relative" }}>
        <MainPage
          session={this.state.session}
          sessionUpdated={this.sessionUpdated}
          hamburgerMenuOpen={this.state.page.hamburgerMenuOpen}
          toggleHamburgerMenu={this.toggleHamburgerMenu}
          currentPage={this.state.currentPage}
          setNavigation={page => this.setState({ currentPage: page })}
        />
      </Container>
    );
  }
}

export default Central;
