import React, { Component } from "react";

/* HamburgerMenu.jsx */
class HamburgerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  render() {
    const styles = {
      container: {
        position: "absolute",
        top: this.props.offsetTop,
        right: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        height: this.state.open ? "100%" : 0,
        display: "flex",
        flexDirection: "column",
        opacity: 0.95,
        color: "black",
        transition: "height 0.3s ease",
        zIndex: 2
      },
      menuList: {
        paddingTop: "3rem"
      }
    };
    return (
      <div style={styles.container}>
        {this.state.open ? (
          <div style={styles.menuList}>{this.props.children}</div>
        ) : null}
      </div>
    );
  }
}

export default HamburgerMenu;
