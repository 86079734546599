import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import RobinRowe from "./blogImg/HistoricRobin.png";
import Olive from "./blogImg/GeorgeOlive.png";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0059 = () => {
    const [image] = useState(logo);
    const [r1] = useState(RobinRowe);
    const [o1] = useState(Olive);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">22.03.05</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>Nevada Ballet Initiative</title>
                <strong>Common Sense Engaged this Week</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Historic Week</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Greatings Swarm,
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">This has been an historic week. Robin Rowe and Peggy Borgman presented our notice of intent to circulate for signatures our Division Free Voting constitutional initiative.  I truly hope you are excited about this as I am.  I honestly don't know of another time such an initiative has been put forward at the state level.  Here is Peggy's photo of Robin as she presented it.
                <img className="imageInternal" src={r1} alt="" />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I also wanted you all to be aware of the news wire that went out.  You can see it all here: <a href="https://send2press.net/report/22-0303regpro-nevada/">https://send2press.net/report/22-0303regpro-nevada/</a>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">This link will show you who picked it up (published it) and the original release.  Some spoilers, we were picked up by the Associated Press, Google News, Apple News and appeared in publications in about 12 states.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>George is Listening</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We are also working our logo.  We like the idea of RCA's old picture "His Master's Voice" of a dog listening to a phonograph record.  For us, the dog is listening to the voice of the voters.  The dog is thus our elected representative and for us, the only one of our president's unanimously elected, George Washington, and so we have Olive, appearing as George. This will evolve into our branding logo, and we have lots of ideas of how people (supporters and activists, et al) can get involved with this work, and I'm really looking forward to seeing it evolve.
                <img className="imageInternal" src={o1} alt="" />
            </Col></Row><p /><Row ><Col xs="12" sm="12" md="12" className="main"><h2>Websites Updated</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We have updated both the commonsenseforunitingAmerica.org web page, and this site (of course), and from now on the weekly heartbeat will actually be a weekly heartbeat (again).
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">In the coming weeks we are looking at expanding out to do more social media such as Instagram and FaceBook.  I will also be working with the team to put together some presentation materials and creating a 20 min TED Talk style presentation.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Our non-profits are coming together as well.  Expect to see the formal formation of Common Sense for Uniting America within the next 90 days.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>You Have a Place in the Swarm</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Remember our goal is to gain supporters and we need activists to do that. We follow Rick Falkwinge's Swarm methodology (see our resources) so don't need to ask for permission, just have a sense of mission. If you would like to be more involved, drop us a line at <a href="admin@commonsenseforunitingamerica.org">admin@commonsenseforunitingamerica.org</a>, or <a href="commonsenseforunitingamerica@gmail.com">commonsenseforunitingamerica@gmail.com</a>.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I truly believe we are making history, initiating something as powerful as the golden age of democracy, democracy free of partisan politics.  This isn't the end of the great democratic experiment—it's just about to begin.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">And don't forget to stop by each week for the latest.
            </Col></Row><p />


        </Container>
        </>
    )
}

export default Blog0059;
