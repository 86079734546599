import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0042 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.10.22</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Open Them Doors</strong><p />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Neither Max Voting nor any other voting method will fundamentally affect a two-candidate race.  In a two-candidate race, a voter will prefer one candidate over the other, or they will have no preference.  In the latter case, the voter has no incentive to vote.  In the former case, the voter will use whatever method to rate or identify one candidate as superior to the other.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><a href="https://www.rangevoting.org/BallAccess.html">Some states</a> in the United States have taken steps to ensure that political races will effectively consist of only one Democrat and one Republican.  This list includes Georgia, Florida, Texas, Arkansas, West Virginia, Minnesota, Maryland, and Massachusetts.  To realize the full benefit of Max Voting methods, voters must express their desire to their representatives to change laws that effectively restrict the ballot to only two candidates.  Although we do not argue here that we should require elections to consist of more than two candidates, systemic ballot limitations that ensure only two-candidate races can exist likewise ensure our societal problems persist.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Additionally, election runoffs with only two candidates are the same as elections with only two candidates and should be fervently avoided.   It is as if someone patched the roof and then installed a drain from the roof right into the puddle to ensure the problem continues.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><a href="http://www.people-press.org/2016/07/07/2016-campaign-strong-interest-widespread-dissatisfaction/">“Overall satisfaction with the choice of candidates is at its lowest point in two decades. Currently, fewer than half of registered voters in both parties – 43% of Democrats and 40% of Republicans – say they are satisfied with their choices for president.  Roughly four-in-ten voters (41%) say it is difficult to choose . . . because neither would make a good president . . . And just 11% say the choice is difficult because either would make a good chief executive. . .</a>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><a href="https://fivethirtyeight.com/features/americans-distaste-for-both-trump-and-clinton-is-record-breaking/">“Americans’ distaste for both Trump and Clinton is record-breaking.”</a>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I strongly advocate for the acceptance of the provisions of the <a href="https://www.congress.gov/bill/103rd-congress/house-bill/1755">1993 House Resolution Bill entitled “The Fair Elections Act of 1993” (H.R.1755 — 103rd Congress (1993-1994)).</a>  It proposed that, for individuals to become candidates on a ballot for the Office of the President of the United States (though I think this should be applied to all elections), they should receive signatures equivalent to 1/10th of 1 percent of the number of votes cast in the previous elections or at least, 1000 signatures. Establishing such a method for all elections would drastically improve access for citizens to get on to the ballot.  If one has concerns about the ballot being flooded with this reduced requirement for signatures, they may find comfort in the fact that, in the United States, from 1890 (when preprinted ballots came into use) up to 2005, <a href="https://rangevoting.org/BallInit.html">there have only been two cases</a> where a ballot for some statewide office (or U.S. presidential race) has ever had more than 10 candidates on it, provided at least 2500 signatures were required to get on the ballot (and even in those cases, there were less than 12 candidates).
            </Col></Row><p />


        </Container>
        </>
    )
}

export default Blog0042;
