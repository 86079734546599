import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0028 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.07.16</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                16 - 22 July:  get Ohio on the <span><a href='https://scorevoting.swarmage.net'>scorevoting.us</a></span> scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>Swarm Age tool has been upgraded to make it easier to use. I’m very excited about that as it can still be set up for individuals.  Worked a lot on getting the speech down.  It is still rough, but better.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Newsletter. </strong>No news, will reconnect with the editor this week.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>We now have 20 states!!  It wasn’t Ohio, but Massachusetts has joined in. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>The message is solid and I think this will be the base, and I will keep working to make it more transformational using the great work of <span><a href='https://www.amazon.com/Steal-Show-Michael-Port-audiobook/dp/B014X33YCU/ref=tmm_aud_swatch_0?_encoding=UTF8&qid=1626445592&sr=8-1'>Mike and Amy Port</a></span>!</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>Big presentation is on Sunday.  I will work Saturday on smoothing what I can and dialing it up. After that, I will reach out to the editor and start doing articles as well as doing the 5 min update to the speech. </Col></Row>

        </Container>
        </>
    )
}

export default Blog0028;
