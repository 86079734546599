import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0032 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.08.13</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>We have some big changes to discuss.</strong><p />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                The first change is our focus.  Previously I had focused on what the voter does, score candidates. Hence, score voting.  I, working with Bev, realized that we shouldn’t focus on what the voter does, but what the voter gets when using score voting: maximum input, maximum influence, and maximum political power.  Hence Max Voting.  So our movement name has changed and the messaging will shift, but we are still talking about adopting the same voting methods for the same reasons.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                The second change will be our method.  I intend to educate everyone on what Max Voting offers so that they can conscientiously make a decision on what they want.  That means less advocacy and calls to action and more explanation and education.  But that doesn’t mean we become a text book here.  We still will have the supporter goals and seek to bring supporters in.  Additionally, we want to empower supporters with easy stories and illustrations so that they can easily speak to others about what Max Voting has to offer.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                The third change will be this message space.  I intend to reach out to the local media, focusing on Nevada to begin with as it is my beloved home state, and this site will be used to capture those same short messages going out to the local papers.  They obviously will not be exclusive to Nevada as Max Voting is beneficial nationwide, but I want this space to become more of an outreach resource, then just rambling on the meta-side of the movement.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                I will be keeping all of the back editions, and our publication plan will be alternating weeks of newspaper engagement and article publication.  Also, I am keeping the weekly goal and the discord channel (link is above on this website). I’m continuing to develop the presentation as well so expect many more good things to come.  In the meantime, click the link above to have a look at the latest edition of Common Sense for Uniting America.  It has stuff on chickens.
            </Col></Row>

        </Container>
        </>
    )
}

export default Blog0032;

