import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0012 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.03.19</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>The cover video is out (<span><a href='https://www.youtube.com/watch?v=O2wKOI3_AUA'>https://www.youtube.com/watch?v=O2wKOI3_AUA</a></span>) and on the score voting youtube channel. I had an article published in the Democracy Chronicles (<span><a href='https://democracychronicles.org/ranked-voting-in-oregon/'>https://democracychronicles.org/ranked-voting-in-oregon/</a></span>) focused on the Oregon legislative session on 16 March where I also testified alongside the STAR Voting team.  I also worked on a new line of argument and additional material.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>This week I will have time to do a rewrite on the book.  The focus will be an update to the STAR Voting section, given my new understanding of it as a compromise between score voting and ranked-choice voting.  Also, I will look to incorporate the concept of MO (Majority Opinion) and a correlation between using a voting system and getting a certain outcome.  I don’t think most RCV advocates understand what they are signing up for.  I hope to have the next version done by the end of this week and look to discuss the next steps on the book by the end of the month.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>After testifying at to the Oregon legislature, Sara Wolk asked if I would consider starting a STAR Voting chapter (<span><a href='https://www.starvoting.us/'>https://www.starvoting.us/</a></span>).  I don’t like the idea as it is a compromise (meaning STAR Voting may not produce the population’s most desired candidate).  She then asked about starting an equal.vote chapter (<span><a href='https://www.equal.vote/'>https://www.equal.vote/</a></span>), which looks like exactly the same thing as a STAR Voting chapter.  I haven’t researched it yet, but she said it supported more of a variety of voting methods.  I’m not sure what it would help with at this point, as I already have Doug’s Nevadans for Election Reform group (<span><a href='https://nevadansforelectionreform.org/'>https://nevadansforelectionreform.org/</a></span>) which has members and is a 401c3 organization, and can also work with <span><a href='https://unsplitthevote.org/'>unsplitthevote.org</a></span>.  But I will investigate equal.vote further. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>I worked a lot with this new idea.  It’s based around the concept that, if people truly believe that an election winner must be someone’s number one candidate, then score voting can’t help them.  Also, some people get bent out of shape when I point out that score voting ALWAYS delivers the voter’s most desired candidate. I think they feel like I’m loading up the argument, but it’s true. I also like the idea that I’m not pushing a voting system, just trying to help people get the society they want based on what they think is right.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>A rewrite of the book is top.  A continued refinement of my one page and the new business card hand out is second and third.  After that, the desire to fix the website is building more, so that would be fourth.  The MO script may be on hold for now. </Col></Row>

        </Container>
        </>
    )
}

export default Blog0012;
