import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0023 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.06.11</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                11-17 June: get New York on the scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>I did a lot of practice and work for the Nevada presentation.  I also wrote out “my origin story,” something Bev says we will need for the book.  Bev and I had, happily, a lot of back and forth through the course of the week talking about foundational issues.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book. </strong>Bev and I are on the same page, but looking at it from different angles.  I think this evening will help to clarify what the nature and tone of the articles should be.  The plan is articles - creating following - pitch book - do book - as much public attention as we can get.  That’s the idea anyway.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>We had another joiner from California, Sacramento this time.  No one from New York.  It would be better if the system could translate addresses into voting precincts, but it’s just not that capable yet. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>Bev says, and she’s right and I have heard these words again and again, we need stories.  Emotional stories that are credible and surprise the reader.  Something that will give someone something to talk about around the water cooler.  But finding the story, while it is still hot, and tying it into score voting, without the article being about score voting, is a challenge.  I think we need to focus on the mantra, fail fast, fail often.  We just need to be throwing stuff out and seeing what sticks.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I think we will work to get our first article out this upcoming week, but also, I will work on the Nevada pitch.  It isn’t great. It sounds like a lecture and not a performance, so I have a lot more work to do.  Again, I need to find more stories.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0023;
