import React, { Component } from "react";
import { Button, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import { LoadingSpinner } from "./../common/LoadingSpinner";
import axios from "axios";

import SignupFlow1 from "./SignupFlow1";
import SignupFlow2 from "./SignupFlow2";
import SignupFlow3 from "./SignupFlow3";
import SignupFlow4 from "./SignupFlow4";
import { registerSupporter, supporterNumber } from "../../services/supporter";
import "./index.css";

import SendTweets from "./../sendTweets";

const BE_URL = process.env.REACT_APP_BE_URL;
const MOVEMENT = process.env.REACT_APP_MOVEMENT;

export default class SignupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 1,
      nation: "USA",
      nationNum: 0,
      state: "",
      stateNum: 0,
      county: "",
      countyNum: 0,
      precinct: "",
      precinctNum: 0,
      email: "",
      userName: "teddy",
      password: "",
      certCode: "",
      movement: "",
      mission: "",
      levelValues: [],
      coreSupporter: true,
      registerResponse: null,
      loading: false,
      registrationsuccess: false,
    };
  }

  setSelectedState(state) {
    this.setState({
      state,
      county: "",
      precinct: "",
      currentSlide: this.state.currentSlide + 1,
    });
  }

  setSelectedCounty(county) {
    this.setState({
      county,
      precinct: "",
      currentSlide: this.state.currentSlide + 1,
    });
  }

  setSelectedPrecinct(precinct) {
    this.setState({ precinct, currentSlide: this.state.currentSlide + 1 });
  }

  setToPreviousSlide() {
    const { currentSlide } = this.state;
    if (currentSlide === 4) {
      this.setState({
        email: "",
        userName: "",
        password: "",
        registerResponse: null,
      });
    }
    this.setState({ currentSlide: currentSlide - 1 });
  }

  async createCall(email, movement) {
    try {
      await axios.post(
        `${BE_URL}/api/supporters/${MOVEMENT}/create-pdf`,
        this.state
      );
    } catch (e) {
      return { error: (e.response && e.response.data) || e.message };
    }
  }

  async register(email, userName, password, coreSupporter) {
    this.setState({ loading: true });
    const apiResponse = await registerSupporter({
      userName,
      email,
      password,
      coreSupporter,
      state: this.state.state,
      county: this.state.county,
      precinct: this.state.precinct,
      nation: "USA",
    });
    await supporterNumber({
      state: this.state.state,
      county: this.state.county,
      precinct: this.state.precinct,
      nation: "USA",
    });

    this.setState({
      userName: apiResponse.data.userName,
      movement: apiResponse.data.movement,
      mission: apiResponse.data.mission,
      nation: apiResponse.data.nation,
      nationNum: apiResponse.data.nationNum,
      state: apiResponse.data.state,
      stateNum: apiResponse.data.stateNum,
      countyNum: apiResponse.data.countyNum,
      county: apiResponse.data.county,
      precinctNum: apiResponse.data.precinctNum,
      precinct: apiResponse.data.precinct,
      certCode: apiResponse.data.newId,
      levelValues: apiResponse.data.levelValues,
      responseNation: apiResponse.data.responseNation,
      responseState: apiResponse.data.responseState,
      responseCounty: apiResponse.data.responseCounty,
      responsePrecinct: apiResponse.data.responsePrecinct,
      email: email,
      loading: false,
      registerResponse: apiResponse,
      registrationsuccess: !!apiResponse.data,
      coreSupporter: apiResponse.data.coreSupporter,
      movementHash: apiResponse.data.movementHash,
    });

    const {
      state,
      county,
      precinct,
      levelValues,
      movement,
      movementHash,
    } = this.state;

    this.createCall(email, movement);
    //checks to see if a new supporter level has been reached in which case a tweet is sent
    SendTweets(state, county, precinct, levelValues, movementHash);
  }

  getSlideStyles(slideIndex) {
    const { currentSlide } = this.state;
    return {
      display: currentSlide === slideIndex ? "block" : "none",
    };
  }

  closeAllModal() {
    this.setState({
      state: "",
      county: "",
      precinct: "",
      currentSlide: 1,
      registrationsuccess: false,
    });
    this.props.toggleVisibility();
  }

  render() {
    const { isOpen } = this.props;
    const { state, county, precinct, currentSlide } = this.state;

    return (
      <>
        <LoadingSpinner loading={this.state.loading} />
        <Modal
          backdrop={"static"}
          isOpen={isOpen}
          toggle={() => this.closeAllModal()}
          centered={true}
          modalClassName="signup-wrapper"
          className="signup-modal"
        >
          <ModalHeader toggle={() => this.closeAllModal()}>
            REGISTER YOUR SUPPORT
          </ModalHeader>
          <ModalBody>
            {currentSlide === 1 && (
              <div className="slide" style={this.getSlideStyles(1)}>
                <SignupFlow1
                  state={state}
                  setLoading={(val) => this.setState({ loading: val })}
                  setSelectedState={(state) => this.setSelectedState(state)}
                />
              </div>
            )}
            {currentSlide === 2 && (
              <div className="slide" style={this.getSlideStyles(2)}>
                <SignupFlow2
                  state={state}
                  county={county}
                  setSelectedCounty={(county) => this.setSelectedCounty(county)}
                  previousSlide={() => this.setToPreviousSlide()}
                  setLoading={(val) => this.setState({ loading: val })}
                />
              </div>
            )}
            {currentSlide === 3 && (
              <div className="slide" style={this.getSlideStyles(3)}>
                <SignupFlow3
                  state={state}
                  county={county}
                  precinct={precinct}
                  setSelectedPrecinct={(precinct) =>
                    this.setSelectedPrecinct(precinct)
                  }
                  previousSlide={() => this.setToPreviousSlide()}
                  setLoading={(val) => this.setState({ loading: val })}
                />
              </div>
            )}
            {currentSlide === 4 && (
              <div className="slide" style={this.getSlideStyles(4)}>
                <SignupFlow4
                  previousSlide={() => this.setToPreviousSlide()}
                  setLoading={(val) => this.setState({ loading: val })}
                  registerResponse={this.state.registerResponse}
                  register={(email, userName, password, coreSupporter) =>
                    this.register(email, userName, password, coreSupporter)
                  }
                />
              </div>
            )}
          </ModalBody>
          <Modal
            isOpen={this.state.registrationsuccess}
            modalClassName="signup-success-wrapper"
            className="signup-success-modal"
          >
            <ModalBody>
              <span style={{ color: "green" }}>Registration successful</span>
              <hr className="hr" />
              <Row className="mx-0">
                <Button
                  color="primary"
                  size="sm"
                  onClick={(e) => this.closeAllModal()}
                >
                  Done
                </Button>
              </Row>
            </ModalBody>
          </Modal>
        </Modal>
      </>
    );
  }
}
