import { AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CustomizedAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  position: "sticky",
  top: 0,
  zIndex: 3,

  "& .MuiToolbar-root": {
    paddingInline: { lg: 3.75, xl: 165 },
    color: theme.palette.common.darkGray,
    justifyContent: "space-between",
  },

  "& .MuiList-root": {
    display: "flex",

    "& .MuiListItem-root": {
      width: "auto",
    },
  },
}));
