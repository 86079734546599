import { createContext, useState } from "react";

export const LanguageContext = createContext(null);

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("");

  const languageValues = {
    language,
    setLanguage,
  };
  return (
    <LanguageContext.Provider value={languageValues}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
