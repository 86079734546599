import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0058 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">22.02.18</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>Common Sense for Uniting America Guiding Principles</title>
                <strong>CSUA Guiding Principles</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Our Why</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Our “why” is to end America’s political division by replacing the cause (min voting methods–methods that ask the voter “Which candidate do you want to represent you?”) with the cure (MaxVoting methods–methods that ask the voter, “How much does each candidate represent you?”)
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We do not seek to end political disagreement, or differences of opinion.  We do not seek to change anyone’s mind on any political issue. We recognize that each of us has come to where we are today via our own unique journey that has shaped our values and opinions. We believe it critical to the wellness of the nation that every American’s voice is, not just spoken, but heard.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>MaxVoting</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We define a MaxVoting method as a voting method that allows each voter to rate each candidate on a set scale (which can be as small as 0 to 1) independent of how they rate other candidates, and that the winner is determined by using all of the rating information provided by the voter.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We support any form of MaxVoting method regardless of range, inclusion of an instant runoff (as long as all voter input is considered prior to the runoff), or score assigned automatically to a candidate not marked by a voter (or if the voter is not included in calculating that candidate’s average score).
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>All our Welcome</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We welcome all American voters to serve as an activist or officer regardless of any demographic category including gender, race, ethnicity, sexual orientation, sexual identity, religion, or anything else someone might dream up as a reason to exclude somebody.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">As a movement, we have no opinion on any political issue beyond the instituting of MaxVoting methods.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">As a movement, we have no opinion on the candidacy of any individual who is running for officer or the performance of any representative or official in office.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">As a movement, we have no opinion on any political party.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Activist Focused</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Officers in our organization support activists.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Activists are encouraged to do anything that they understand to be moral, ethical, and legal to spread the message of the importance of MaxVoting to the wellness of the nation, and to gain more supporters.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>No Fear of Failure</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We expect all our activists and officers to try. We also expect them to fail, to learn, to grow, and, eventually, to win.
            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0058;
