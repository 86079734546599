import React, { Component } from "react";
import { Container } from "reactstrap";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { Scrollbar } from "react-scrollbars-custom";
import StaticTitleBar from "../../components/StaticTitleBar/StaticTitleBar";
import DynamicHeader from "../../components/dynamicHeader/dynamicHeader";
import Page from "../../components/Page";
import ParentBlock from "../../components/ParentBlock/ParentBlock";
import PercentBlock from "../../components/PercentBlock/PercentBlock";
import SupporterNumber from "../../components/SupporterNumber/SupporterNumber";
import BeltLevelBar from "../../components/BeltLevelBar/BeltLevelBar";
import TreeMap from "../../components/TreeMap";
import ImageButton from "../../components/ImageButton/ImageButton";
import EndZone from '../../components/endzone';
import { LoadingSpinner } from "../../components/common/LoadingSpinner";
import {
  setLevel,
  setLoading,
  setCurBeltLevel,
  setStateScreenShow,
  setMapView,
  setPercentView,
} from "../../redux/actions/vote.action";
import * as constants from "./const";
import * as opConsts from "../../redux/constants";
import "./MainPage.css";
import Async from 'react-async';
import axios from "axios";


const BE_DB = process.env.REACT_APP_MOVEMENT;
const BE_URL = process.env.REACT_APP_BE_URL;
const BE_CODE = process.env.REACT_APP_BE_NEWEST;


class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthForTreeMapUpdate: 100,
      listOfNames: [],
      nation: "USA",
      state: "",
      county: "",
      precinct: ""
    };
  }

  componentDidMount() {
    const { setLevel, setLoading, currentSelInfo } = this.props;
    setLoading(true);
    setLevel({ comment: "nation", innerText: "USA", currentSelInfo });

    this.staticHeaderRef = document.querySelector(".static-header");
    this.dynamicHeaderRef = document.querySelector(".dynamic-header");

    window.addEventListener("resize", this.updateWindowDimensions);
  }

  getOffsetTop() {
    if (this.staticHeaderRef && this.dynamicHeaderRef) {
      return (
        this.staticHeaderRef.clientHeight + this.dynamicHeaderRef.clientHeight
      );
    }
    return 0;
  }

  handleClick(comment, innerText) {
    this.props.setLoading(true);
    this.props.setLevel({
      comment,
      innerText,
      currentSelInfo: this.props.currentSelInfo,
    });
  }

  arrowClick() {
    const { stateScreenShow, setStateScreenShow } = this.props;
    setStateScreenShow(!stateScreenShow);
  }

  hoverEvent(level) {
    const { setCurBeltLevel } = this.props;
    if (level === "Goal") level = 10;
    setCurBeltLevel(level);
  }

  updateWindowDimensions = () => {
    this.setState({ widthForTreeMapUpdate: window.width });
  };

  renderDynamicBar() {
    const {
      session,
      sessionUpdated,
      hamburgerMenuOpen,
      toggleHamburgerMenu,
      stateScreenShow,
      setNavigation,
      currentPage,
    } = this.props;
    return !stateScreenShow ? (
      <>
        <DynamicHeader
          session={session}
          sessionUpdated={sessionUpdated}
          hamburgerMenuOpen={hamburgerMenuOpen}
          toggleHamburgerMenu={toggleHamburgerMenu}
        />

        <Page
          currentPage={currentPage}
          setNavigation={setNavigation}
          session={session}
          sessionUpdated={sessionUpdated}
          hamburgerMenuOpen={hamburgerMenuOpen}
          toggleHamburgerMenu={toggleHamburgerMenu}
          offsetTop={41}
        />
      </>
    ) : null;
  }

  renderStaticTitleBar() {
    const { session, sessionUpdated, stateScreenShow } = this.props;
    return !stateScreenShow ? (
      <StaticTitleBar session={session} sessionUpdated={sessionUpdated} />
    ) : null;
  }

  renderParentBlocks() {
    const {
      level,
      currentSelInfo,
      selStatisticInfo,
      stateScreenShow,
      percentViewMode,
    } = this.props;
    let renderBlocks = [];

    for (let i = 0; i < opConsts.LEVELS.length; ++i) {
      const comment = opConsts.LEVELS[i];
      const isOpened = comment === level;
      const innerText = currentSelInfo[comment];
      const statistics = selStatisticInfo[comment];

      if (innerText !== "" && statistics) {
        const {
          headVoteTotal,
          headSupportTotal,
          headPercent,
          headRecentPercent,
        } = statistics;
        const percent = !percentViewMode ? headPercent : headRecentPercent;
        const headLevel = constants.getCurrentLevel(headPercent);
        const currentLevel = constants.getCurrentLevel(percent);
        const showVal =
          currentLevel === 10
            ? 0
            : Math.ceil(
              (headVoteTotal * constants.BELT_LEVEL[headLevel + 1]) / 100 -
              headSupportTotal
            );

        renderBlocks.push(
          <Row key={`row-${i}`} className="block-row">
            <ParentBlock
              noComment={false}
              comment={comment}
              innerText={innerText}
              isOpened={!isOpened || stateScreenShow ? 1 : 0}
              handleClick={() => this.handleClick(comment, innerText)}
              arrowClick={() => this.arrowClick()}
              blockColor={constants.levels[currentLevel].blockColor}
              textColor={constants.levels[currentLevel].color}
            />
            <SupporterNumber number={showVal} />
            <PercentBlock
              percent={percent.toFixed(2)}
            />
          </Row>
        );
      }
      if (isOpened) {
        break;
      }
    }

    return renderBlocks;
  }

  renderStateScreen() {
    const { level, stateScreenShow, treeMapData, percentViewMode } = this.props;

    if (!stateScreenShow || level === "precinct") return;

    let nextLevel = "precinct";
    for (let i = 0; i < opConsts.LEVELS.length; ++i) {
      if (level === opConsts.LEVELS[i]) {
        nextLevel = opConsts.LEVELS[i + 1];
        break;
      }
    }

    let renderBlocks = [];

    treeMapData &&
      treeMapData.forStatesScreen &&
      treeMapData.forStatesScreen.map((county, index) => {
        const percent = !percentViewMode
          ? county.headPercent
          : county.recentPercent;
        const headLevel = constants.getCurrentLevel(county.headPercent);
        const curLevel = constants.getCurrentLevel(percent);
        const showVal =
          curLevel === 10
            ? 0
            : Math.ceil(
              (county.voters * constants.BELT_LEVEL[headLevel + 1]) / 100 -
              county.supporters
            );

        return renderBlocks.push(
          <Row key={`screen-${index}`} className="block-row">
            <ParentBlock
              noComment={true}
              comment={nextLevel}
              innerText={county.name}
              isOpened={2}
              handleClick={() => this.handleClick(nextLevel, county.name)}
              blockColor={constants.levels[curLevel].blockColor}
              textColor={constants.levels[curLevel].color}
            />
            <SupporterNumber number={showVal} />
            <PercentBlock percent={constants.BELT_LEVEL[curLevel].toFixed(2)} />
          </Row>
        );
      });
    return (
      <Scrollbar className="scroll-bar">
        <Container style={{ position: "relative" }}>{renderBlocks}</Container>
      </Scrollbar>
    );
  }

  renderParentDescption() {
    const {
      level,
      currentSelInfo,
      selStatisticInfo,
      stateScreenShow,
    } = this.props;

    if (level !== "nation" || stateScreenShow) return;

    const innerText = currentSelInfo[level];
    const statistics = selStatisticInfo[level];

    if (statistics) {
      const { headVoteTotal, headSupportTotal, headPercent } = statistics;
      const currentLevel = constants.getCurrentLevel(headPercent);
      const number = Math.ceil(
        (headVoteTotal * constants.BELT_LEVEL[currentLevel + 1]) / 100 -
        headSupportTotal
      );
      const percent = constants.BELT_LEVEL[currentLevel].toFixed(2);

      return (
        <>
          <Row>
            <Col
              xs="8"
              sm="8"
              md="8"
              className="description-block description-left"
            >
              In the <strong>{innerText}</strong> we need{" "}
              <span className="highlighted">{number}</span> more supporters to
              reach our next supporter level
            </Col>
            <Col xs="4" sm="4" md="4" className="description-block">
              <strong>{percent}%</strong> of voters are supporters
            </Col>
          </Row>
        </>
      );
    }
  }

  renderBeltLevelBar() {
    const { curBeltLevel, stateScreenShow, level } = this.props;
    return (
      <BeltLevelBar
        curLevel={curBeltLevel}
        stateScreenShow={stateScreenShow}
        level={level}
      />
    );
  }

  renderTreeMap() {
    const {
      treeMapData,
      level,
      mapSelectedView,
      stateScreenShow,
      percentViewMode,
    } = this.props;

    if (level === "precinct" || stateScreenShow) return null;

    let nextLevel = "precinct";
    for (let i = 0; i < opConsts.LEVELS.length; ++i) {
      if (level === opConsts.LEVELS[i]) {
        nextLevel = opConsts.LEVELS[i + 1];
        break;
      }
    }

    return (
      <Row className="treemap" style={{ marginTop: 20, padding: 10 }}>
        <TreeMap
          data={treeMapData}
          hover={(level) => this.hoverEvent(level)}
          nextLevel={nextLevel}
          mouseDown={(comment, innerText) =>
            this.handleClick(comment, innerText)
          }
          mapSelectedView={mapSelectedView}
          stateScreenShow={stateScreenShow}
          percentViewMode={percentViewMode}
          level={level}
          widthForTreeMapUpdate={this.state.widthForTreeMapUpdate}
        />
      </Row>
    );
  }


  renderMapView() {
    const {
      mapSelectedView,
      setMapView,
      percentViewMode,
      setPercentView,
    } = this.props;

    return (
      <Row className="map-view-title">
        <Col xs="4" sm="4" md="4">
          change map view
        </Col>
        <div style={{ display: "flex" }}>
          <ImageButton
            type={0}
            selected={mapSelectedView}
            setMapView={setMapView}
          />
          <ImageButton
            type={1}
            selected={mapSelectedView}
            setMapView={setMapView}
          />
        </div>
        <div style={{ display: "flex" }}>
          <ImageButton
            type={2}
            percentViewMode={percentViewMode}
            setPercentView={setPercentView}
          />
          <ImageButton
            type={3}
            percentViewMode={percentViewMode}
            setPercentView={setPercentView}
          />
        </div>
      </Row>
    );
  }


  render() {

    const { loading, level } = this.props;
    //render Name List gets names for the latest joiners:
    const renderNameList = async () => {
      //get current nation, state, county, precinct from props (currentSelInfo)
      const loca = Object.entries(this.props.currentSelInfo);
      let nation = loca[0][1];
      let state = loca[1][1];
      let county = loca[2][1];
      let precinct = loca[3][1];
      //create address for call to backend
      let address = nation + "/" + state + "/" + county + "/" + precinct;
      if (precinct !== "") {
        address = nation + "/" + state + "/" + county + "/" + precinct;
      } else if (county !== "") {
        address = nation + "/" + state + "/" + county;
      } else if (state !== "") {
        address = nation + "/" + state;
      } else { address = nation; }
      // add variables for later
      const obj = { nameCode: BE_CODE };
      //Make call to back end to get data

      const promise = await axios.post(`${BE_URL}/api/Newest/${BE_DB}/${address}`, obj);
      return (promise.data)
    }


    return (
      <><Container fluid>

        <script src="..."></script>
        {this.renderDynamicBar()}
        {this.renderStaticTitleBar()}
        <LoadingSpinner loading={loading} />
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid grey",
          }}
        >
          <div
            className="treemap-zone"
            style={{ height: level === "precinct" ? "400px" : "750px" }}
          >
            <Row>
              <Col xs="12" sm="12" md="12" className="question-bar" style={{ color: "#5A8A8E " }}>
                {constants.QUESTION}
              </Col>
            </Row>
            {this.renderParentBlocks()}
            {this.renderParentDescption()}
            {this.renderBeltLevelBar()}
            {this.renderStateScreen()}
            {this.renderTreeMap()}
            {this.renderMapView()}
          </div>
          <div className="treemap-zone" style={{ height: "650px" }}>

            <Row
              style={{
                display: "flex",
                color: "blue",
                fontSize: "20px", justifyContent: "center", paddingBottom: "25px", paddingTop: "25px"
              }} >
              <span width="100%" alignText="center" style={{ color: "#5A8A8E" }}>
                <b>Our Newest Supporters!</b>
              </span>

            </Row>

            <Row >
              <Col xs="12" sm="12" md="12" style={{ textAlign: 'center' }}>

                <Async promiseFn={renderNameList}>
                  {({ data, error, isPending }) => {
                    if (isPending) return "Loading..."
                    if (error) return `Something went wrong: ${error.message}`
                    if (data)
                      return (
                        <div>
                          {data.map(name => <h4 key={name} style={{ fontSize: '20px', color: "#CE786B" }}><b>{name}</b></h4>)}
                        </div>
                      )
                    return null
                  }}
                </Async>
              </Col></Row>



          </div>
        </Row>

        <Row style={{
          display: "flex",
          borderBottom: "1px solid grey",
        }}></Row>

        <Row
          style={{
            display: "flex",
            color: "#5A8A8E",
            fontSize: "20px", justifyContent: "center", paddingBottom: "25px", paddingTop: "25px"
          }} >
          <span width="100%" alignText="center"><b>
            Organizations whom we support and who support us!
          </b></span>

        </Row>
        <Row style={{ display: "flex" }}>
          <Col xs="6" sm="6" md="4" lg="3" alignItems="center">
            <a href="https://www.democracygroup.org/">
              <img src="https://uploads-ssl.webflow.com/6024691f085d0ae25df9b8f1/6024742bee6066c5a4058c15_TDG-Small-White.png" alt="Democracy Group" style={{ backgroundColor: "#1c3d64", paddingBottom: "10px", paddingTop: "10px", marginTop: "50px" }} width="100%" />
            </a><br />
          </Col>
          <Col xs="6" sm="6" md="4" lg="3" style={{ display: "flex", alignItems: "center" }}>
            <a href="https://www.braverangels.org/">
              <img src="https://braverangels.org/wp-content/uploads/2020/04/Braver-Angels-Logo.png" alt="Braver Angels" height="100%" width="100%" style={{ paddingBottom: "10px", paddingTop: "10px" }} />
            </a><br />
          </Col>
          <Col xs="6" sm="6" md="4" lg="3" style={{ display: "flex", alignItems: "center" }}>
            <a href="https://www.nextgenpolitics.org/">
              <img src="https://static1.squarespace.com/static/5f7c4dfef2909807572c2554/t/5f9b37bbab99143931641551/1604007870918/large-NGP-Logo-Transparent-1+%281%29.png?format=1500w" alt="NExt Gen Politics" height="100%" width="100%" style={{ paddingBottom: "10px", paddingTop: "10px" }} />
            </a><br />
          </Col>
          <Col xs="6" sm="6" md="4" lg="3" alignItems="center">
            <a href="https://vote-nevada.org/">
              <img src="https://votenevada.files.wordpress.com/2020/12/cropped-transparent-png.png?w=250" alt="Vote Nevada" style={{ paddingBottom: "0px", paddingTop: "0px" }} width="100%" />
            </a><br />
          </Col>
        </Row>
        <Row style={{
          display: "flex",
        }}><br />

          <Col xs="6" sm="6" md="4" lg="3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <a href="https://www.thepeople.org/">
              <img src="https://thepeople.org/wp-content/uploads/2019/10/logo-color_new.png" alt="The People" alignItems="center" height="100%" />
            </a><br />
          </Col>
          <Col xs="6" sm="6" md="4" lg="3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <a href="https://www.activote.net/">
              <img src="https://www.activote.net/wp-content/uploads/2019/01/ActiVote-white-O-1.png" height="100%" alt="Activote" width="100%" style={{ paddingBottom: "10px", paddingTop: "10px" }} />
            </a><br />
          </Col>
          <Col xs="6" sm="6" md="4" lg="3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <a href="https://nationalvoterregistrationday.org/">
              <img src="https://nationalvoterregistrationday.org/wp-content/uploads/2020/04/logo1.jpg" alt="National Voter Registration Day" width="100%" style={{ paddingBottom: "10px", paddingTop: "10px" }} />
            </a><br />
          </Col>
          <Col xs="6" sm="6" md="4" lg="3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <a href="https://nevadansforelectionreform.org/">
              <img src="https://nevadansforelectionreform.org/wp-content/uploads/2017/07/cropped-logo-working-fb-PNG.png" alt="NV for Election Reform" width="100%" style={{ paddingBottom: "10px", paddingTop: "10px" }} />
            </a><br />
          </Col>

        </Row>
        <Row style={{
          display: "flex"
        }}><br />

          <Col xs="6" sm="6" md="4" lg="3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <a href="https://www.starvoting.us/">
              <img src="https://assets.nationbuilder.com/unifiedprimary/sites/4/meta_images/original/starlogonarrow.png?1615424286" alt="STARVoting" width="100%" style={{ backgroundColor: "black", padding: "10px" }} />
            </a><br />
          </Col><Col xs="6" sm="6" md="4" lg="3" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px", marginBottom: "50px" }}>
            <a href="https://ngngenterprises.com/">
              <img src="https://149647968.v2.pressablecdn.com/wp-content/uploads/2021/03/NGNG-sm-web-v6.png" alt="No Guts No Glory" width="100%" style={{ backgroundColor: "#1D2F60", padding: "10px" }} />
            </a><br />
          </Col>
          <Col xs="6" sm="6" md="4" lg="3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <a href="https://electionscience.org/">
              <img src="https://electionscience.org/wp-content/uploads/2018/11/es-logo-horizintal-color-white.svg" alt="election science" width="100%" style={{ backgroundColor: "#4E6F71", padding: "20px" }} />
            </a><br />
          </Col>
          <Col xs="6" sm="6" md="4" lg="3" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "black", fontSize: "30px", font: "HelveticaNeue, Helvetica Neue, helvetica,Sans-Serif" }}>
            <a href="https://deliberation.us/">DELIBERATIONS.US
            </a><br />
          </Col>

        </Row>


        <Row style={{
          display: "flex"
        }}><br />

          <Col xs="6" sm="6" md="4" lg="4" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <a href="https://unsplitthevote.org/">
              <img src="https://unsplitthevote.org/wp-content/uploads/2019/02/unsplit_emblem_rgb_360_FB.png" alt="unsplit the vote" width="100%" style={{ padding: "20px" }} />
            </a><br />
          </Col><Col xs="6" sm="6" md="4" lg="4" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px", marginBottom: "50px" }}>
            <a href="https://cpfuture.org/">
              <img src="https://images.squarespace-cdn.com/content/v1/5efa7da75c26a80414ad90e6/ab6d5bfb-3019-4543-9938-4aa7612c7537/CP+Future+-+for+white+background.png?format=1500w" alt="Common Power Future" width="100%" style={{ padding: "10px" }} />
            </a><br />
          </Col>
          <Col xs="6" sm="6" md="4" lg="4" style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "25px", Color: "black" }}>
            <a href="https://www.electoralcollegereformmovement.com/" >
              Electoral<br />
              College <br />
              Reform<br />
              Movement<br />
            </a><br />
          </Col>

        </Row>
        <EndZone />
      </Container>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  level: state.level,
  currentSelInfo: state.currentSelInfo,
  selStatisticInfo: state.selStatisticInfo,
  loading: state.loading,
  curBeltLevel: state.curBeltLevel,
  stateScreenShow: state.stateScreenShow,
  mapSelectedView: state.mapSelectedView,
  percentViewMode: state.percentViewMode,
  subordinates: state.subordinates,
  treeMapData: state.staticInfo,
});

const mapDispatchToProps = {
  setLevel,
  setLoading,
  setStateScreenShow,
  setCurBeltLevel,
  setMapView,
  setPercentView,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MainPage);
