import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0066 = () => {
    const [image] = useState(logo);

    return (
        <>
            <Container>
                <Row><Col>
                    <img className="image" src={image} alt="" />
                </Col>
                </Row>
                <Row><Col className="date">22.04.23</Col></Row>
                <p />
                <Row ><Col xs="12" sm="12" md="12" className="title">
                    The Weekly Heart Beat
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><title>MaxVoting Update</title>
                    <h1>More engagement on #voting and #democracy for #MaxVoting</h1><br />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>#MaxVoting last week</h2>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">This past week has been one of meeting amazing people:
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Sasha at <a href='https:// https://cpfuture.org/'>Common Power Future</a>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Lyla at <a href='https:// https://electoralcollegereformmovement.com/'>Electoral College Reform Movement</a>

                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">and learning amazing things:
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><a href="https://www.amazon.com/Who-Not-How-Accelerating-Teamwork-ebook/dp/B0867ZJ151/ref=sr_1_1?crid=3G1NQSNRBXA1O&keywords=who+not+how&qid=1650805785&sprefix=who+n%2Caps%2C1444&sr=8-1">Who not How</a> by Dr. Benjamin Hardy and Dan Sullivan.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">And check out the great tools at <a href="https://resources.strategiccoach.com/">Strategic Coach</a><p /><br /><br />
                    <Row ><Col xs="12" sm="12" md="12" className="main"><h2>#MaxVoting next week</h2>
                    </Col></Row><p />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- hold the incorporator's meeting which will decide our path to incorporate Common Sense
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- publish another press release
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- publish another video
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">- and maybe a TikTok channel and a LinkedIn channel.<p /><br />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Another project</h2> <br />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">We are developing a new long presentation.<p />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Here is a clip of it:

                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">"We have to solve immigration, the economy, healthcare, climate change, and that is going to require us to work together like we never have before. A few will say that MaxVoting methods are unproven. Yes, they are.  And all the ones we have now, plurality and ranked-choice voting, are definitely proven--proven to create political division, dysfunction, and deafness.  If we want what we have never had, a united country, a government that listens to all of its people all the time, then we have to do what we have never done. Institute MaxVoting.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">"Winter is coming.  And we can be ready, but will we be?"
                </Col></Row><p />
            </Container>
        </>
    )
}

export default Blog0066;
