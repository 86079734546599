import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0025 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.06.25</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                25 June – 1 July:  get Ohio on the <span><a href='https://scorevoting.swarmage.net'>scorevoting.us</a></span> scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>I focused on smoothing and memorizing the script for 17 July. <span><a href='https://commonsenseforunitingamerica.substack.com'>commonsenseforunitingamerica.substack.com</a></span></Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Newsletter. </strong>Do not fear.  The book remains my primary intermediate goal, but we will focus on the Newsletters and the presentation for the time being.  In the short term we need to gain more of a following.  We plan to use the newsletter and presentation toward that goal.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>We had a great week with two new states joining the big board, both New York and Kansas.  This puts us at 19 states total.  We will have to find a way to celebrate when we reach 25. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>The message I see coming from discovery work this week is that our candidates sit blameless and helpless for our current state of disfunction.  It makes sense when you think about it and I hope I can do that with the presentation. We need to recognize that our representatives must support their party and base over the needs of their constituents, otherwise they won’t be the representative anymore.  We see sobering lessons to that effect over and over. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>Bev will focus on the next Newsletter, and I should see and chop that this week.  I remain fixed on the presentation.  I want to get it down cold and create all of the slides that will go with it.  In two weeks, I want to start doing rehearsals to an audience.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0025;
