import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0006 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.02.05</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>Websites were added to the site, but not that many are actually out there.  Did some additional work on the book, new chapter 1 and updates to RCV chapter.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>Still waiting.  Will give it until I am done with the RCV article, rewrite of the book and creation of the new script.  Probably the end of the month. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>I had some letters to the editor published in the papers in Nevada.  Also, I had some great training from Vote Nevada which Sondra Cosgrove started.  I am planning how to more fully engage with the representatives to educate them on the issue as well as the public. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>I have an avenue to a zine in which I can publish an article.  I will work on the RCV report card. The elections of 2020 in Maine vividly demonstrate that RCV doesn’t change anything.  That message needs to get out. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>This will be a big week.  By weeks end I want to have gone through all of Vote Nevada’s training, have written the RCV article (first draft) and have done another version of new animated short.  Also, I have had some ideas for improving the online tool by allowing direct links to sites that allow for easy identification of one’s own precinct.  I will put the plumbing into the webtool first and then go looking for all the best SoS sites.  </Col></Row>

        </Container>
        </>
    )
}

export default Blog0006;
