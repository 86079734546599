import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0031 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.08.06</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                07-12 Aug: get Ohio on the <span><a href='https://scorevoting.swarmage.net'>scorevoting.us</a></span> board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>I was focused on both getting some articles written (I only wrote four), and working with the next draft of the speech that I received from Bev.  I didn’t script out the video, but I did have a new video idea. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Newsletter. </strong>I will publish on Monday and at least every week.  The to mask or not to mask will go out tomorrow, but I want to have five on the site before I start releasing articles to Facebook, LinkedIn, the Discord and Slack channels, etc.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>No new supporters. It may be a while before some new ones come in. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>Should be an interesting conversation to night.  Score voting may morph to “power voting.”  This was an idea I had years ago when I looked at how much power a voter exerts (based on how much information they put into the voting method that goes to determining the winner of the election) and plurality seemed to allow the minimum amount of power (I don’t know how it could be less) and score voting (greater than a range of 2 so not approval voting in this case) offered the maximum amount of voting power.  But the point is, would it resonate?  Will ask Bev tonight.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I’m going to publish one article, write two more and get the next draft of the new speech done.  That’s a lot to do, but I may add more to the welcome letter so people have clearer next steps to follow.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0031;
