import React, { Component } from "react";
import * as d3 from "d3";
import * as constants from "../containers/MainPage/const";
import "./template.css";

function dotme(text) {
  text.each(function () {
    var text = d3.select(this);
    var words = text.text().split("");

    var ellipsis = text
      .text("")
      .append("tspan")
      .attr("class", "elip")
      .text("...");
    var width =
      parseFloat(text.attr("width")) -
      ellipsis.node().getComputedTextLength() -
      5;
    var numWords = words.length;

    var tspan = text.insert("tspan", ":first-child").text(words.join(""));

    while (tspan.node().getComputedTextLength() > width && words.length) {
      words.pop();
      tspan.text(words.join(""));
    }

    if (words.length === numWords || words.length === 0) {
      ellipsis.remove();
    }
  });
}

class TreeMap extends Component {
  componentDidMount() {
    this.drawBarChart();
  }
  componentDidUpdate() {
    this.drawBarChart();
  }

  drawBarChart() {
    const { data, mapSelectedView, percentViewMode } = this.props;
    const { maxHeadPercent, maxRecentPercent } = data;
    if (!data || !data.children) return;

    var h = 340;
    var parentelement = d3.select(".treemap").node();
    var width = parentelement.getBoundingClientRect().width - 30;

    d3.select(".tooltip").remove();
    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("top", -100)
      .style("left", -200);
    var treemap = d3.layout
      .treemap()
      .round(false)
      .size([width, h])
      .sticky(true)
      .sort(function (a, b) {
        return a.value - b.value;
      })
      .value(function (d) {
        return !percentViewMode
          ? mapSelectedView
            ? d.headPercent
            : maxHeadPercent - d.headPercent
          : mapSelectedView
          ? d.recentPercent
          : maxRecentPercent - d.recentPercent;
      });

    d3.select(".treemapClass").remove();
    const svgCanvas = d3
      .select(this.refs.canvas)
      .append("svg:svg")
      .attr("class", "treemapClass")
      .style("width", width)
      .style("height", h)
      .append("svg:g")
      .attr("transform", "translate(-.5, -.5)");

    var nodes = treemap.nodes(data).filter(function (d) {
      return !d.children;
    });

    var cell = svgCanvas
      .selectAll("g")
      .data(nodes)
      .enter()
      .append("svg:g")
      .attr("class", "cell")
      .attr("transform", function (d) {
        return "translate(" + d.x + "," + d.y + ")";
      });

    let onClickOccur = 0;

    cell
      .append("svg:rect")
      .attr("x", 1)
      .attr("y", 1)
      .attr("width", function (d) {
        return Math.max(0, d.dx - 1);
      })
      .attr("height", function (d) {
        return Math.max(0, d.dy - 1);
      })
      .style("fill", (d) => {
        const level = this.getLevel(
          d.voters,
          !percentViewMode ? d.supporters : d.recentSupporters
        );
        const blockColor = level.blockColor;
        d.level = level.level;
        d.textColor = level.textColor;
        return blockColor;
      })
      .style("stroke", "#C85C12")
      .style("stroke-width", 1)
      .on("click", (d) => {
        tooltip.remove();
        onClickOccur = 1;
        this.props.mouseDown(this.props.nextLevel, d.name);
      })
      .on("mouseover", (d) => {
        if (onClickOccur) {
          d3.select(".tooltip").remove();
          return;
        }
        this.props.hover(d.level);
        const level = constants.getCurrentLevel(d.headPercent);
        const showVal =
          level === 10
            ? 0
            : Math.ceil(
                (d.voters * constants.BELT_LEVEL[level + 1]) / 100 -
                  d.supporters
              );
        const currentPercent = d.headPercent;

        tooltip.transition().duration(200).style("opacity", 0.9);
        tooltip.html(
          '<p style="background-color: transparent; color: white; margin-bottom: 5px">' +
            d.name +
            "</p>" +
            '<div style="display: flex; justify-content: space-between; background-color: transparent">' +
            '<p style="background-color: transparent; color: white; margin-bottom: 3px;"> Supporters to go:&nbsp</p>' +
            '<p style="background-color: transparent; color: white; margin-bottom: 3px">' +
            showVal +
            "</p>" +
            "</div>" +
            '<div style="display: flex; justify-content: space-between; background-color: transparent">' +
            '<p style="background-color: transparent; color: white; margin: 0">Total Percentage:&nbsp</p>' +
            '<p style="background-color: transparent; color: white; margin: 0">' +
            currentPercent.toFixed(2) +
            "%</p>" +
            "</div>"
        );
        // return tooltip.style("visibility", "visible").style("opacity", 1)
      })
      .on("mousemove", function () {
        tooltip
          .style("top", d3.event.pageY - 10 + "px")
          .style("left", d3.event.pageX + 10 + "px");
      })
      .on("mouseout", function () {
        tooltip.transition().duration(500).style("opacity", 0);
      });

    cell
      .append("svg:text")
      .attr("class", "state-name")
      .attr("x", 5)
      .attr("y", 10)
      .attr("width", function (d) {
        return Math.max(d.dx - 1, 0);
      })
      .attr("dy", ".35em")
      .attr("text-anchor", "start")
      .attr("font-size", "14px")
      .attr("fill", (d) => {
        return d.textColor;
      })
      .text(function (d) {
        return 25 > d.dy - 1 ? "" : d.name;
      });

    cell
      .append("svg:text")
      .attr("class", "voters")
      .attr("x", 5)
      .attr("y", 25)
      .attr("width", function (d) {
        return d.dx - 1;
      })
      .attr("dy", ".35em")
      .attr("font-size", "14px")
      .attr("fill", (d) => {
        return d.textColor;
      })
      .attr("text-anchor", "start")
      .text(function (d) {
        return d.value;
      })
      .text(function (d) {
        const level = constants.getCurrentLevel(d.headPercent);
        const showVal =
          level === 10
            ? 0
            : Math.ceil(
                (d.voters * constants.BELT_LEVEL[level + 1]) / 100 -
                  d.supporters
              );
        return this.getBBox().height + 25 > d.dy - 1 ? "" : showVal;
      });

    d3.selectAll(".state-name").call(dotme);
    d3.selectAll(".voters").call(dotme);
  }

  getLevel(voter, sup) {
    if (sup === undefined) sup = 0;
    if (!voter) voter = 1;
    const percent = (sup / voter) * 100;
    let blockColor, textColor, level;
    if (percent < 0.05) {
      blockColor = "#ffffff";
      textColor = "#333333";
      level = 0;
    }
    if (percent >= 0.05 && percent < 0.1) {
      blockColor = "#fcfbfd";
      textColor = "#333333";
      level = 1;
    }
    if (percent >= 0.1 && percent < 0.2) {
      blockColor = "#efedf5";
      textColor = "#333333";
      level = 2;
    }
    if (percent >= 0.2 && percent < 0.39) {
      blockColor = "#dadaeb";
      textColor = "#333333";
      level = 3;
    }
    if (percent >= 0.39 && percent < 0.78) {
      blockColor = "#bcbddc";
      textColor = "#333333";
      level = 4;
    }
    if (percent >= 0.78 && percent < 1.6) {
      blockColor = "#9e9ac8";
      textColor = "#333333";
      level = 5;
    }
    if (percent >= 1.6 && percent < 3.1) {
      blockColor = "#807dba";
      textColor = "#FFFFFF";
      level = 6;
    }
    if (percent >= 3.1 && percent < 6.3) {
      blockColor = "#6a51a3";
      textColor = "#FFFFFF";
      level = 7;
    }
    if (percent >= 6.3 && percent < 13) {
      blockColor = "#54278f";
      textColor = "#FFFFFF";
      level = 8;
    }
    if (percent >= 13 && percent < 25) {
      blockColor = "#3f007d";
      textColor = "#FFFFFF";
      level = 9;
    }
    if (percent >= 25) {
      blockColor = "#FFFF00";
      textColor = "#D9001B";
      level = "Goal";
    }

    const levelData = {
      blockColor: blockColor,
      textColor: textColor,
      level: level,
    };

    return levelData;
  }

  render() {
    return <div ref="canvas" style={{ border: "2px solid #C85C12" }}></div>;
  }
}

export default TreeMap;
