import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0045 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.11.12</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>Political School Board Battles</title>
                <strong>Our Portait Drawn in Battle Lines</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                The <a href='https://www.npr.org/2021/10/21/1047334766/school-board-threats-race-masks-vaccines-protests-harassment'>NPR report</a> on life on the front lines presents a harrowing portrait of the current level of dysfunctionality in our nation. You can feel the fear in the voices of the school board members as their neighbors and friends threaten their lives. Likewise you can sense the anger and frustration of the parents who feel powerless to shape or have any meaningful impact upon what and how the schools that their children attend teach.  The environment of the United States feels toxic and hopeless.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I would offer that the attacks on school boards may be seen as only the latest chapter in our political party's on-going battle to obtain political supremacy, a battle that began with the founding of our nation around issues of Federalism, support to foreign nations, and slavery.  The issues change over time, but the threats and repression of our neighbors, family, and friends (by our neighbors, family, and friends) has not.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We may gain some measure of hope from the fact that our nation is not too far gone to still find our way from division to unification, and unification on a level that has never existed in the United States. This common sense for uniting America flows from a recognition that our division starts not with flaws of our character, but within our democratic systems, specifically, our voting method.  Americans do not need to change who they are, only how they vote.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Our min voting method, plurality voting, which restricts a voter to the minimum amount of input and influence in our elections (one voter - one candidate), divides our nation and creates two political power positions, one to the left and one to the right of the true will of the people.  Plurality, and ranked-choice voting, by assigning each voter to one candidate, places us in candidate camps that then battle for supremacy, and battle any way they can.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Max voting methods, where all voters can provide their opinion on all candidates and all of that input is used to determine the winner, gives an advantage to politicians and candidates who support the majority-opinion, the true will of the people. As our parties move their platforms toward each other, they begin to blur the seam between them and find common ground.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Threats upon those brave enough to offer themselves to public service, regardless of party, sends shocks through a true American's heart.  But, as the 1860s demonstrated, it can still get much worse if we don't act now to make it better.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We deserve and can have better than this.


            </Col></Row><p />

        </Container>
        </>
    )
}

export default Blog0045;
