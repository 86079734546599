import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0054 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">22.01.21</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>Nevada Constitutional Initiative 2022</title>
                <strong>Our Manifesto</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Hello. I'm Ted Getschman.  native Nevadan, retired Naval Commander, and founder of Common Sense for Uniting Nevada. I drafted a state constitutional initiative to institute “Division Free Voting.” Division Free Voting end political division in our state.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">What's “Division Free” voting? It's like reviewing products on Amazon, all voters rate all candidates and the highest rated candidate wins. It is about as plain vanilla as expressing your desires gets.  We rate all kinds of things, all the time. But not candidates?

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I'm doing this because plurality voting has divided our state, communities, and families. My wife won't talk to her brother because of our divisive two-party politics. And final-five voting doesn't end division, it ensures it.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Picture Beverly, a native New Yorker looking forward to using ranked-choice voting in the Democratic Primary for Mayor.  The election feels less toxic, and Bev has had to examine all the candidates instead of just one, so she feels more informed about the candidates and the issues.  She feels good about the election; which makes it tragic that her vote actually reinforces our political division. Let me explain.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We voters, have a shape.  Did you know that?  It's called a public opinion curve. That's a graph showing how many people hold a specific political opinion on an issue laid out along a political spectrum ranging from “yes-always” to “no-never”.  “Should we legalize marijuana?”  A few us will say “yes, always” and a few will say “no, never.”  But most will say, “maybe, sometimes, it depends”.  Because of this, these curves tend to take the shape of hills with the majority of voters' opinions, the majority opinion, mounding up somewhere on the political spectrum.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Candidates try to attract voters by placing their political platform along the political spectrum, but their platform only reaches so far away. So, candidates can gain the most votes by placing their platform at the majority opinion. But when a second candidate does the same thing, they divide the voters, in half.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Now, a candidate can gain more voters by moving a little toward the fringe. The opponent, however, can do the same. They can both keep creeping out until the maximum reach of their platform aligns with the majority opinion.  Any further out or in and they lose more voter than they gain: stalemate. These are the two political power positions traditionally held by Republicans and Democratics.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">If a third candidate joins the election supporting our majority opinion, there are not enough voters between the two power positions to win.  Can't win in the middle; can't win on the fringe.  These candidates can spoil the election for one of the two power candidates, but there is nowhere for them to go to win.  And this happens without bad people, bad parties, or bad primaries.  Plurality voting favors major parties.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Do think final-five voting can fix this?  Ranked choice voting gets rid of the lowest scoring candidate if no one has over 50% of the vote. That's this gal. If she's on the inside, if she's on the outside.  We could put 20 candidates on the ballot between the two power candidates.  More choices, right? More competition, right? But ranked-choice voting just eliminates them all, one after another. Ranked-choice voting favors major parties.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Don't believe me? Australia has had ranked-choice voting since 1918.  The Australian Parliament stated in a report title, Australian Electoral Methods, quote, “preferential voting [ranked choice voting] gives a disproportionate advantage to major parties.  The major parties have thus won 99.4% of all house of representative contests in the 23 commonwealth elections since 1949. No seat has been won by a minor party candidate, despite three reasonably strong minor parties.” Unquote.  Australia originally instituted ranked-choice voting to keep out independents and minority parties. Read it for yourself, google “Swan by-election.”

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I researched 256 ranked choice voting elections in California and Minnesota.  About 64% of the time, Instant Runoffs didn't even happen, and when they did, 95% of that time the election was reduced to only two candidates with the winner taking 55% of the vote.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Remember, the goal of ranked-choice voting is to ensure a candidate gets a greater than 50% majority, and that is what causes of our political division. When a candidate wins a 55% majority, they have demonstrated two things. 1) they can ignore the 45% minority and be elected, and 2) they must keep faith with the 55% in order to be elected.  Ruling majorities ensure ignored minorities.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Beverly's Democratic Primary in New York was reduced to two candidates with the winner taking 50.4% of the vote.  Do you think that united voters? Was that the winners marching on Washington in 2016? In 2020, Biden had over 51% of the popular vote.  Did that make us more united on 6th of January? When you vote in Plurality or Ranked-choice voting elections, you are actually dividing us, hurting democracy—it's crazy.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">If we want a different answer, we need to ask a different question. Instead of our voting method asking “which one candidate do you want to represent you?” it needs to ask, “How much does each candidate represent you?”  “How much does Donald Trump represent who you are, what you want?”  “How much does Andrew Yang, Dean Heller or Steve Sisolak?”  When we add up how much each candidate represents each voter, we discover which candidate best represents the voters as a group. Voting methods that do that are called MaxVoting methods.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">With MaxVoting methods, candidates gain an advantage by aligning their platform to the most voters.  That happens at one place, the majority opinion. So now all of the candidates are looking to support roughly the same political platform. So, what is the difference between a Republican and a Democrat when they support the same political platform?

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">With Division Free Voting, voters set the agenda, political distance between candidates shrinks, collaboration increases, gridlock dies, and gerrymandering becomes virtually impossible. With MaxVoting, we unite Nevada and can start to fix your world.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">You make this happen by going to maxvoting.org and quickly and easily registering your support. We need 150,000 supporters to register, including at least 36,000 from each of the four Petition Districts in Nevada. We can do that easy. Spread the word.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Are you a fan of approval voting or STAR Voting?  Hey, so am I! Those are also MaxVoting methods.  When enough supporters registered on MaxVoting.org, we'll do a poll and you, our Nevada supporters, will ensure we are putting forward the kind of MaxVoting method you want, so register today so you don't miss out.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">I want supporters of ranked-choice voting to win, to have a united, functioning community, but ranked-choice voting systemically won't let that happen. MaxVoting methods will.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">MaxVoting.org. Register now. Change the question; end the division. It's time to be lucky, Nevada.

            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0054;
