import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0015 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.04.09</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                09-15 April:  get both New York and Ohio on the scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>The discord site is up and working (<span><a href='https://discord.gg/TF9Tpp8m2c'>https://discord.gg/TF9Tpp8m2c</a></span>).  We have successfully met our first challenge.  I have started posting these above. I wrote two articles for Reddit posts. I will try to provide those to Democracy Chronicles and perhaps CivilPolity.org as well.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>Still waiting for management to read the book.  The parties in question think they can have it read by the end of next weekend, so no more progress for a few more weeks.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>The elements are all starting to come into alignment.  The book will still be the most significant outreach, and I think its launch will genuinely be the movement’s launch.  Smaller articles and interactions on the STAR Voting slack channel and the Center for Election Science Discord server will hopefully help me gain additional visitors.  I plan to try out arguments on Reddit.  The Discord should be a regular opportunity to communicate and work together.  The Swarm Age tool forms the focus providing us with instant feedback as to our progress.  We could next put into place the 501d3 to allow for funding of actual legal efforts.  I think those are all the elements.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>A strong message coming up this week is that a very successful plurality or ranked-choice voting campaign still doesn’t tell you what happened in an election.  I think that may be a revelation for many people, but hard not to cross it with how election methods affect society.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I need to think about getting advertising onto the Swarm Age tool to get the costs under control.  I should have two articles on Reddit and also offered to Democracy Chronicles, with two more written.  I’ll keep reaching out on the other networks as well.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0015;
