import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0053 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">22.01.14</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>Change the Question</title>
                <strong>Change the Question</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">You want to change the answer? Change the question.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Currently we answer the question, “Which candidate do you want to represent you.”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But we really should answer the question, “How much does each candidate represent you.”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">In the first case, we pick one.  Pick one side, pick one faction.  We divide ourselves into supporter groups.  We divide ourselves into a left or a right, a liberal or a conservative.  When we vote, we divide.  Our voting method requires us to.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">“Which one” voting methods, like plurality and ranked-choice voting force us to enter one camp, if we wish to participate.  Ranked-choice voting may appear to offer more, but remember that the ranked-choice voting method employs a series of instant runoffs, within each, a voter must choose one, there top choice from their ballot.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">“How much?” Not only changes the question, it changes the conversation.  When we ask “which one”, we want to know which candidate has the largest number of voters who want them.  When we ask “how much”, we want to know how much each candidate represents the entire voter population.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Consider the 2016 election. Which candidate do you want to represent you? Hillary Clinton, Donald Trump, Gary Johnson, Jill Stein, or Darrell Castle?  Compare that to How much does Hillary Clinton represent you, your views, your beliefs your desires?  How much does Donald Trump?  How much does Gary Johnson?  How much does Jill Stein, or Darrel Castle?  Who most represents you—you character, your attitude, you. Who least represents?  And how much do all the other candidates represent you in relation to these two extremes.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We divide when we answer “which one.”  We unite when answer “how much.”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The one idea I want you to take away from my presentation today is that we are politically divided as a nation, because we divide ourselves. And if we stop dividing ourselves, we will no longer be divided; it's common sense for uniting America, but it maybe isn't obvious, which is why I'm here. But once you see it, it's like watching a person hammering their hand over and over. Their hand hurts, and they want the pain to stop. But we they can't figure it out.

            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0053;
