import styled from "@emotion/styled";
import { FormControl } from "@mui/material";

export const LanguageDropdown = styled(FormControl)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    fontSize: 14,
    border: "transparent",
    overflow: "unset !important",
    color: "inherit",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent !important",
  },
}));
