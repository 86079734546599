import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0034 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.08.27</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Tragically Inevitable Story of Kelly Ayotte.</strong><p />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                She began as a non-partisan.  In 2009, Kelly Ann Ayotte ran as a Republican for the U.S. Senate for the state of New Hampshire. Kelly was born and raised in Nashua, New Hampshire, married an Air Force Officer and had two children.  She started a law career at the New Hampshire Supreme Court and rose to be appointed New Hampshire Attorney General. Her first appointment came in June 2004, from then Governor Craig Benson, an entrepreneurial Republican.  The next governor, Governor John Lynch, a Democrat, asked her to continue in this position, twice.  During her tenure she fought changes in environmental laws that permitted power plants to withhold reporting on their emissions.  She prosecuted murders and preached to parents about the risks of their child’s unfettered access to the internet.  She testified before the supreme court for the overturning of a lower court’s verdict which declared New Hampshire’s mandatory disclosure of a minor’s planned abortion to her parent’s illegal. She resigned in July 2009 to campaign for U.S. Senate as a Republican.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                The plurality election pushed her right.  As she began down the campaign trail, those around her noticed that she had “studiously avoided partisanship and avoided political events throughout her career.”  Quickly, other Republican candidates began to cast doubt on her support for the conservative platform, but powerful conservative representatives just as quickly began to provide vocal support for candidacy.  Ovide Lamontagne questioned her conservative credentials from the start, “When the president passed the stimulus with Congress, Governor Lynch and Kelly Ayotte were encouraging cities and towns and individuals from around the state to apply for stimulus,” Lamontagne said. “Now she stands opposed to it and says she would repeal it. I was out there right away saying this is pork-barrel spending.” Regardless, Ayotte went ahead in polling early in the race, polling even ahead of her potential Democratic rival.  The other republican candidates continued to attack her as Democrats hoped that the primary would push her further to the political right.  Ayotte reached out to the farthest right seeking the endorsement of Tea Party representative Jim DeMint. Ayotte called Representative’s DeMint call to repeal the House passage of the 2010 Health Care Reform act as a “critical first step.”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                She became partisan. Another candidate, William “Bill” Binnie, also ran for Republican nomination. A noted industrialist and philanthropist, he had served as the Chairman of the Finance Committee for the New Hampshire Republican State Committee forced to explain past statements that rebuked the GOP, signaled tepid support for gay marriage. Binnie later emphatically says he believes marriage is between a man and a woman.  Ayotte declared him to be too liberal. Ayotte has accused Binnie of being too liberal accusing him of “shipping jobs overseas.” For the five years before this, she had worked as the Attorney General for New Hampshire serving at the request of both Republican and a Democratic Governors. During the election campaign, other Republican candidates began to cast doubt on her support for the conservative platform.  In response Kelly Ann reached out for the endorsement of conservative Tea Party member, Senator Jim DeMint. She received the endorsement and won the election.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                She drifted back toward non-partisan.  The Lugar Center and Georgetown University have created a partisanship rating index. The Lugar Center (www.thelugarcenter.org) and Georgetown University's McCourt School of Public Policy have created a partisanship rating index. The index awards each member of congress, both in the House and the Senate, a score based on how their bills gain support from the opposing party and how often they support opposing party’s bills. That score will either be positive (better) or negative (worse) based on whether it is more or less than a 20-year bipartisan benchmark.  Both the 112th (Senator Ayotte’s first congressional session) and 113th Congresses had the two lowest scores among the eleven Congresses the index had measured.  In the 112th Congress, Senator Ayotte received a score of -.40915, giving her a rank of 41 out of 98 Senators.  The highest score received was Senator Olympia Snowe, Maine (2.0814) and the lowest was Senator Jim DeMint, South Carolina (-2.09564.)  In her second congressional session, her score rose to .95416 (7th ranking in the Senate), and in her third, .829278 (ranking 12th).
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                She drifted too far.  Senator Ayotte had little difficulty securing the Republican nomination as she ran for re-election in 2016.  The re-election rate for Senate that year would be 93.1%. Senator Ayotte lost by 2,034 votes.  One of her competitors was Aaron Day.  Mr. Day ran as an independent on a very conservative platform. Aaron Day received 35,484 votes, only 5% of the number of votes Senator Ayotte received, but enough to allow Democratic challenger, Maggie Hassan to win. Mr. Day later explained that “my whole point in doing this was I want to send a message to Republicans like Kelly Ayotte, Lindsey Graham, and John McCain that we’re not going to play with the establishment sand box. I came up with this plan 15 months ago in part because Ayotte interfered with our speaker’s race.”
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                What can we learn from Kelly Ayotte?  She had to become partisan to be elected, and then did not stay partisan enough for Aaron Day who weaponized the voting method to ensure she would not be re-elected. This is plurality voting.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Candidates who win elections like to say that they support all the people, but, under plurality voting, can they?  Who did Kelly Ayotte need to keep faith with to be re-elected?  And who did she demonstrate she could ignore to be elected?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                The plurality voting method creates two power candidates, a two-party system, with the population divided between them. The winning strategy appears to be to occupy either a left or right power positions and focus on taking care of the party. None of this has to do with the moral character of the people, only the rules of the game under which they are playing. Senator Ayotte tried to stand between the two extremes, and paid the price.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Why must support either the political right or political left, but never the majority opinion? Our voting method minimizes us: minimum input, minimum influence, and minimum control.  It works to divide us.  It creates and sustains a two-party system that gridlocks our government and disenfranchises our voters.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                But what if we the voters had the maximum?  Maximum Input, maximum influence, and maximum control.  What if our voting method worked to unite us?  What if our voting method pushed politicians to the majority opinion instead of the left or right?  What would happen to gridlock? To gerrymandering? To polarization in our nation?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                My message today is simply this: Max or Min, it’s your choice, it’s your future, but you do have the power to make it what you want.  So I’ll leave it in your good hands.

            </Col></Row><p />

        </Container>
        </>
    )
}

export default Blog0034;
