import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0063 = () => {
    const [image] = useState(logo);

    return (
        <>
            <Container>
                <Row><Col>
                    <img className="image" src={image} alt="" />
                </Col>
                </Row>
                <Row><Col className="date">22.04.02</Col></Row>
                <p />
                <Row ><Col xs="12" sm="12" md="12" className="title">
                    The Weekly Heart Beat
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><title>MaxVoting Update</title>
                    <h1>MaxVoting and Division Free Voting Updates</h1>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">We are taken steps toward enacting our nonprofit in Nevada.  We plan to incorporate before the end of the month.  The directors are on board and we are hammering out some final questions.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">We had another new video out this past week on Division Free Voting celebrating our getting passed the 15-day waiting period with no legal challenges.  Those videos are available on this, our resource page.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">We continue outreach to the numerous social and professional groups in Nevada.  If you would like us to reach out to yours so that we can explain MaxVoting more personally, please write us at <a href="mailbox@commonsenseforunitingamerica.org">mailbox@commonsenseforunitingamerica.org</a>.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">I refreshed the draft book (also available on this resource page) and offer it to you as a resource for learning more about our initiative and MaxVoting.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>In the Near Future for the MaxVoting Movement</h2>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Our next article for <a href="https://bit.ly/CmnSens">CommonSenseForUnitingAmerica.substack.com</a> comes out tomorrow.  We delve even deeper into the school board controversy and find hope that it may show a pathway to better Democracy!
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Look for our new feature MaxVoting Minute videos.  One minute to discuss one concept in MaxVoting.  If you have a suggestion for one, let us know via our Common Sense mailbox.
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h1>Do You Want to End Political Polarization?</h1>
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Some things to keep in mind.<p />
                    <h2>Try something.</h2>
                    We all have different levels and types of experience, but none of us have ever tried to change how Democracy functions in the world before (and that IS what we are doing), so your ideas are as good as anyone else's.  Sanity-check them with a couple of other folks and then go.

                    <p /><h2>Learn something.</h2>
                    For those who want to learn more about MaxVoting, you're the reason I went through the draft book again.  It actually isn't that bad (if I do say so myself) and you don't need to read the whole thing to get the idea.  But it does go deeper the further you go so you can better understand different variants of MaxVoting and why not ranked-choice voting.

                    <p /><h2>Transmit something.</h2>
                    Keep an eye out for new items we'll be publishing this week. Every little thing you do for the cause gets us that much closer--please don't undervalue your contribution; taken together, they are legion.

                </Col></Row><p />
            </Container>
        </>
    )
}

export default Blog0063;
