import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0016 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.04.16</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                16-22 April:  get New York on the scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>No new ideas on advertising on the Swarm Age tool, but ready to let that run for a while longer.  No articles were published on Reddit, the rules are quite restrictive, and I don’t seem to have the interest to read through the tons of other notes people have made.  I had a new article published in Democracy Chronicles (<span><a href='https://democracychronicles.org/what-does-ranked-voting-tell-us/'>https://democracychronicles.org/what-does-ranked-voting-tell-us/</a></span>).  The business cards have come in allowing for more impromptu advocacy training.  And the book . . . is moving forward.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>We have an editor, and it was my first choice.  That is all for now, but I am very excited about how the project is now moving forward. It took a long time, but I feel confident in the decision to wait.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>Working with my editor will influence all of the movement.  I don’t know everything that will unfold, but it will certainly be a ton of work, except this time the work will be more focused and more likely to produce results.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>I continue to think that helping understand what score voting can do is key.  I want to avoid simply attacking RCV, which is hard when I hear people hopefully talking about what they think it can do, but which I know it can’t.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>A little lighter focus this week.  I will wait for the editor to fully absorb all that she has on her plate with this effort.  I don’t see how I can get movement in New York for the scorevoting.us board.  This has always been the issue with advocacy.  It is about helping people see the value of the system, and that really needs to be through a trust relationship.  That can be built quickly, but not through a letter to the editor.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0016;
