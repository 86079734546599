import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0024 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.06.18</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                18-24 June: get New York on the scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>Lot of work this week on the 17 July presentation script and the first article.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book. </strong>BAs mentioned previously, the book will come after we gain a following.  Also, happy to hear that Bev has no intention of making the book an anthology of the articles we plan to write from the Substack.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>No New York or any other joiners for this week.  No new articles written by me elsewhere either. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>The challenge this week was making the presentation “education” and not “advocation.”  However, that is how the message needs to be anyway so it was good to do.  It still may be to on the nose.  I need to present the facts (or at least sensible logic) so that people can decide for themselves.  There is a good argument for ranked-choice voting and it comes down to whether or not a person wants to unite the country or ensure the most supported party is in power.  They are both legitimate positions and the second sounds more real-politic, like “in nature only the strong survive, so our system needs to model on nature.”  That’s not me, but I can’t say it isn’t valid.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>We should get our first Substack article out this week and have the script that I will be practicing.  I also need to see where I need slides and build those and then practice much more effectively than I did for my first presentation with Vote Nevada (the shame!) At times like this I wish I had done Grad II from Heroic Public Speaking, but I can only push the company so far on these matters.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0024;
