import { applyMiddleware, createStore } from 'redux';
// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers/vote.reducer';
import rootSaga from './sagas';


export default function configureStore(initialState) {
    const sagaMiddleware = createSagaMiddleware();
    const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })

    const getMiddleware = () => {
        if (process.env.NODE_ENV === 'development') {
            return applyMiddleware(sagaMiddleware);
        }
        return applyMiddleware(sagaMiddleware);
    };

    const store = createStore(
        reducers,
        initialState, composeEnhancers(
            getMiddleware()),
    );

    sagaMiddleware.run(rootSaga);

    return store;
}