import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0068 = () => {
    const [image] = useState(logo);

    return (
        <>
            <Container>
                <Row><Col>
                    <img className="image" src={image} alt="" />
                </Col>
                </Row>
                <Row><Col className="date">23.04.12</Col></Row>
                <p />
                <Row ><Col xs="12" sm="12" md="12" className="title">
                    The Weekly Heart Beat for Uniting America
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><title>MaxVoting Update</title>
                    <h1>One Simple Hack to Unite America</h1><br />
                </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>“Uniting America video time”</h2> </Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">Pop the popcorn and turn down the lights and get ready for <a href="https://youtu.be/HRkmNDKxFUU">“One Simple Social Hack that Could Unite America.”</a></Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">This five minute video shows how MaxVoting methods end political division by focusing on the public opinion curve.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">A public opinion curve graphs the number of people who hold a specific political opinion along a political spectrum ranging from “yes-always” to “no-never.”</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main"><h2>Watch to discover:</h2></Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">How MaxVoting gives candidates a reason to move toward the majority opinion of the voters as it increases their chance of winning.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">How ranked-choice voting incentivizes candidates to move away from the majority opinion.</Col></Row><p />
                <Row ><Col xs="12" sm="12" md="12" className="main">And why majority opinion candidates usually can’t win ranked choice voting elections.</Col></Row><p />
            </Container>
        </>
    )
}

export default Blog0068;
