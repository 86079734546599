import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0018 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.05.07</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                7-13 May:  get New York on the scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>Worked on the Burlington story (what a rabbit hole that is!).  Also worked on the 5 min pitch.  I also added SSL to the scorevoting.us redirect as I thought that may be making some people nervous.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book. </strong>Book is progressing with the focus on Brush, Colorado and Burlington, Vermont.  Probably a couple weeks to get those two stories where they need to be.  Both are currently with the editor.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>Still quite as an organization.  I try to stop by the chat rooms, but most of there stuff seems to be pontification or very regionally focused, which doesn’t help me in the U.K.  I am focused on the book and the 5-minute.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>I’m still triggering when I hear people talk about ranked-choice voting, but I’m not triggered if they simply say the don’t want the liberal (or conservative) side to split.  If American politics is a boxing match and our goal is to ensure the strongest boxer wins instead, then RCV works.  I just don’t think our politics should be a pugilistic competition in the first place.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>Focus remains on the two stories.  I’m also trying to allow myself to relax a bit and not get so tense over how things are not moving forward as I would like (the movement overall, not the book).</Col></Row>

        </Container>
        </>
    )
}

export default Blog0018;
