// actions types
export const SET_LEVEL = 'SET_LEVEL'
export const SET_LEVEL_SUCCESS = 'SET_LEVEL_SUCCESS'
export const SET_LEVEL_FAILED = 'SET_LEVEL_FAILED'
export const SET_LOADING = 'SET_LOADING'
export const SET_CUR_BELT_LEVEL = 'SET_CUR_BELT_LEVEL'
export const SET_SCREEN_SHOW = 'SET_SCREEN_SHOW'
export const SET_MAP_VIEW = 'SET_MAP_VIEW'
export const SET_PERCENT_VIEW_MODE = 'SET_PERCENT_VIEW_MODE'

// constants for redux
export const LEVELS = ["nation", "state", "county", "precinct"]

export const getSortedStaticInfo = function (suborindates, innerText) {
    let maxHeadPercent = 0
    let maxRecentPercent = 0
    let children = Object.keys(suborindates).sort((a, b) => {
        if (a > b) return 1;
        else if (a < b) return -1;
        return 0;
    }).map(key => {
        let headPercent = (suborindates[key].supporters * 100) / suborindates[key].voters
        let recentPercent = (suborindates[key].recentSupporters * 100) / suborindates[key].voters
        // headPercent = headPercent > 100 ? 100 : headPercent
        recentPercent = recentPercent > 100 ? 100 : recentPercent

        maxHeadPercent = Math.max(maxHeadPercent, headPercent)
        maxRecentPercent = Math.max(maxRecentPercent, recentPercent)

        return { name: key, ...suborindates[key], headPercent, recentPercent }
    })

    return {
        name: innerText,
        children,
        forStatesScreen: Array.from(children),
        maxHeadPercent: maxHeadPercent + 0.4,
        maxRecentPercent: maxRecentPercent + 0.4
    }
}