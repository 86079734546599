import React, { Component } from "react";
import "../../App.css";
import "./dynamicHeader.css";
// import Login from "./login";
import SignupModal from "../SignupModal";

const BE_URL = process.env.REACT_APP_BE_URL;

export default class SupporterButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: {
        email: "",
        password: "",
        userName: "jkjjljjkljkjkljljkjiojjlk",
      },
      loggedInSupporter: false,
      activeTab: "3",
      orgs: [
        { title: "U.S.A. Voting", site: `${BE_URL}` },
        { title: "ISPPW", site: "https://www.google.com" },
      ],
      dropdownOpen: false,
      showSignupModal: false,
      showLoginModal: false,
      contentBody: 0,
    };

    this.sessionUpdated = this.sessionUpdated.bind(this);
  }

  signupButtonStyles = () => {
    return {
      //   fontSize: "1.8vw",

      cursor: "pointer",
      outline: "none",
      textAlign: "center",
      border: "none",
      color: "rgb(66, 66, 66)",
      fontFamily: [
        "GillSans",
        "Gill Sans MT",
        "Gill Sans",
        "Trebuchet MS",
        "sans-serif",
      ],
      textDecoration: "underline",
      backgroundColor: "#FFFDF3",
      marginTop: "3",
      paddingTop: "3",
      boxSizing: "content-box",
      outerWidth: "100%",
      whiteSpace: "nowrap",
    };
  };

  toggleModalVisibility() {
    this.setState((prevState) => ({
      ...prevState,
      showSignupModal: !prevState.showSignupModal,
    }));
  }

  sessionUpdated() {
    this.setState({ showLoginModal: false });
    setTimeout(() => this.props.sessionUpdated(), 0);
  }

  render() {
    return (
      <div>
        <button
          style={{
            ...this.signupButtonStyles(),
            color: "#5A8A8E",
            fontSize: "36px",
          }}
          data-toggle="tooltip"
          title="Click to register your support.  
          When we reach supporter level 9 in your state,
          you can end political division and take control of your government."
          onClick={(e) => this.toggleModalVisibility()}
        >
          <span style={{ backgroundColor: "transparent", color: "#5A8A8E" }}>Click here to get it!</span><br/>
        </button>
        {/* <span style={{
            ...this.signupButtonStyles(),
            color: "#5A8A8E",
            fontSize: "36px",backgroundColor: "transparent" 
          }}>{" "}</span>
        <button
          style={{
            ...this.signupButtonStyles(),
            color: "#5A8A8E",
            fontSize: "36px",
          }}
          data-toggle="tooltip"
          title="Click here to sign up."
          onClick={(e) => this.toggleModalVisibility()}
        >
          <span style={{ backgroundColor: "transparent", color: "#5A8A8E"}}> political division</span>
        </button>{" "} */}
        <SignupModal
          isOpen={this.state.showSignupModal}
          toggleVisibility={(e) => this.toggleModalVisibility()}
        />
      </div>
    );
  }
}
