//checks to see if the supporter level has increased
import LevelFinder from "./levelFinder";

const TweetCheck = (levelPercent) => {
  const current = LevelFinder(levelPercent[0]);
  const previous = LevelFinder(levelPercent[1]);

  return current !== previous;
};
export default TweetCheck;
