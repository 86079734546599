import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { LogoLG, LogoLGIcon } from "../../../img/svg";
import { TextInput } from "../../../styles/common/textInput";

const ComingSoon = () => {
  const theme = useTheme();
  const { orange_four, blue, black } = theme.palette.common;
  return (
    <Box pt={7.5} pb={8} px={20.25}>
      <Grid container height="calc(100vh - 124px)" spacing={4}>
        <Grid item xs={7}>
          <LogoLG />

          <Typography
            mt="33px"
            maxWidth="620px"
            variant="h1"
            lineHeight="107px"
            letterSpacing="0.01em"
            color={blue}
          >
            WE ARE COMING SOON!!!
          </Typography>

          <Box mt="85px">
            <Typography variant="h4" color={black}>
              Get notified when we launch
            </Typography>

            <Stack direction="row" mt="14px">
              <TextInput
                placeholder="Email"
                id="email"
                sx={{
                  "& .MuiInputBase-input": {
                    height: "40px",
                    borderColor: "black",
                    backgroundColor: "transparent",
                    borderRadius: "12px 0 0 12px",
                  },
                }}
              />
              <Button
                variant="contained"
                ml="-5px"
                sx={{
                  borderRadius: " 0px 12px 12px 0px",
                  backgroundColor: `${orange_four} !important`,
                }}
              >
                Subscribe
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={5} display="flex" alignItems="center">
          <LogoLGIcon />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComingSoon;
