import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0057 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">22.02.11</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>Majority Rule versus Majority Opinion Rule</title>
                <strong>Majority Rule versus Majority Opinion Rule</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>A Majority</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Imagine a ballroom with one hundred seats, 50 on the left and 50 on the right.  Everyone sitting on the right is politically right leaning from very much so to almost not at all.  Everyone on the left leans left.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">If one person moves from the right to the left, the left is now “a majority.”  The left now has the ability to politically move the entire group in the direction it chooses, following politically left ideas and policies, effectively able to ignore those on the right.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">If two people from the left, now join the group on the right, the right now has “a majority” and can lead the entire population in the direction of the right, ignoring those on the left.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>The Majority Opinion</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But, within any group of people there is one and only one majority opinion.  The members of the group do not need to agree on it or even discuss it, and none of them know what it is.  Our group of 100 has a single majority opinion on any issue.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Should anyone wish to discover the group's majority opinion, they would need to ask each person, or a randomized statistical sampling, what that person's opinion is.  After this listening, they will have a sound understanding of the majority opinion of that group.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The majority opinion is not static or bound to a specific place on the political spectrum.  It changes as the mix of voters changes, as the issue changes, and as time changes.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Imagine then that the whole group chooses to follow the group's majority opinion.  Now two of the most extreme right depart the group, and two new individuals on the extreme left join the group.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">When the make-up of the voters changes, so to does the majority opinion.  To rediscover it, we would, again, need to listen to all of the voters, but as only two have changed, we need only to subtract the opinions of the two that left and add the opinions of the two that joined.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><h2>The Reason to Abandon Majority Rule</h2>
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">If we follow majority rule, how much of a sea change is there when two individuals move from right to left? How much if we follow majority opinion rule?
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">How many people are ignored under majority rule? How many under majority opinion rule?

            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0057;
