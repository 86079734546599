import React from "react";
import ReactDOM from "react-dom";
import DotLoader from "react-spinners/DotLoader";

export function LoadingSpinner({ loading }) {
  const css = "background-color: transparent;";
  const body = document.body,
    html = document.documentElement;
  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  return ReactDOM.createPortal(
    loading && (
      <div
        style={{
          background: "rgba(0,0,0,0.5)",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: height,
          display: "flex",
          justifyContent: "center",
          zIndex: 9979
        }}
      >
        <DotLoader
          css={css}
          color="#fff"
          sizeUnit={"px"}
          size={110}
          loading={true}
        />
      </div>
    ),
    body
  );
}
