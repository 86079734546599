export const btnOrangeFour = {
  backgroundColor: "#FF5700",

  "&:hover": {
    backgroundColor: "#FF8F56",
  },
};

export const btnOrangeFive = {
  backgroundColor: "#FF8F56",

  "&:hover": {
    backgroundColor: "#FF5700",
    color: "#fff",
  },
};
