import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0050 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.12.17</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>A Letter to Nevada</title>
                <strong>A Letter to Nevada</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">It is time for us to end partisan division in Nevada, and we are creating an initiative to do that by our next elections.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Our “pick one” minimum voting method forces us into factions, even when we “pick one” iteratively as we do in ranked-choice voting or final-five voting. When we establish a ruling faction capable of ignoring the rest of us, we all fight each other like hell to be the rulers.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We end this self-destructive process when we adopt a maximum voting method where every voter scores every candidate and the candidate with the highest average score wins. When voters give their opinion on every candidate we don't divide into factions, we don't create majorities and minorities, and we discover which candidate is most desired by the voters as a group, as a community.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">When we implement max voting, candidates, regardless of party, seek out the single majority opinion of the people to give themselves an advantage in the election. The majority opinion flows from every voter's unique personal opinion, and it cannot be controlled by parties, special interests, or the candidates themselves.  As the majority opinion is unique to any group of voters at that time, all candidates create similar political platforms blurring the lines of the political parties, ending gridlock, and making gerrymandering as difficult as it is pointless.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">All that we need to do to end political division in Nevada is institute a max voting method. This End Political Division in Nevada initiative would allow all voters to give a number of points, from 0 to 7, to each candidate and have the candidate with the highest average win. We call it the Division Free Voting. Today we rate everything, products on Amazon, hotels, restaurants, but not our candidates.  This is the most vanilla possible system of finding what our community wants. Our current voting machines can already do this, and we only need to print a few more bubbles after each name.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">This initiative is for Nevadans, and it needs Nevadans.  If you want to help end our division permanently, go now to our max voting site, <a href="https://bit/ly/MaxVote">https://bit/ly/MaxVote</a> and register as a supporter.  You will be asked only for a username, password, and county of residence.  If you support approval voting or STAR voting, go to this site and register, because when our number of supporters equals 3% of our voters, we will hold a pole to ensure we have the max voting method that Nevada wants.  If you want a method different than Division Free Voting, we will submit the initiative Nevada wants.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">When we our supporters reach about 10% of the number of voters, we will execute our signature drive and reach out to you again to ensure we get your initiative on the ballot.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We are serious, about ending division. We know we must get politicians focused on what you want, not what their party wants.  We will do this because Nevadans deserve to have a functioning government and united society will still ensuring everyone is free to hold their own beliefs.  This isn't about changing or silencing your voice, it is about making sure your voice is heard and matters regardless of who wins.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Some may note that we've never done this.  I think that is obvious, as if we had we would not have the divided nation we currently do.  If you are finished being unable to talk to your relatives about politics, finished feeling that every election is all or nothing and being decided for you, and finished with gridlock and gerrymandering, let us end the division.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Before you consider whether or not to support Ranked Choice Voting (RCV) ask yourself, “what is the problem I am trying to solve?”
            </Col></Row><p />

        </Container>
        </>
    )
}

export default Blog0050;
