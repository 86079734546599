import React, { Component } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import axios from "axios";

import "./SignupFlow2.css";

const BE_URL = process.env.REACT_APP_BE_URL;
export default class SignupFlow2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      countyList: []
    };
    this.source = null;
  }

  async componentWillMount() {
    if (this.source) {
      this.source.cancel("ERR_CANCELLED");
    }

    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();

    const { state } = this.props;
    if (!state) return;

    this.props.setLoading(true);

    try {
      const response = await axios.get(
        `${BE_URL}/api/total/supporters/USA/${state}`,
        {
          cancelToken: this.source.token
        }
      );
      const data = response.data;
      if (data && typeof data.subordinates === "object") {
        const countyList = Object.keys(data.subordinates)
          .map(supporter => supporter)
          .sort();
        this.setState({ countyList, dropdownOpen: true });
      }
      this.source = null;
    } catch (e) {
      if (e.message === "ERR_CANCELLED") return;
      this.setState({ countyList: [] });
    } finally {
      this.props.setLoading(false);
    }
  }

  componentWillUnmount() {
    if (this.source) {
      this.source.cancel("ERR_CANCELLED");
    }
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    const { state, county } = this.props;
    const countys = this.state.countyList.map((item, index) => {
      return (
        <DropdownItem
          key={index}
          onClick={e => this.props.setSelectedCounty(item)}
        >
          {item}
        </DropdownItem>
      );
    });

    return (
      <div className="sf-2">
        <p>
          In which county in <b>{state || "selected state"}</b> are you
          registered to vote?
        </p>
        <div className="dd-row">
          <Dropdown
            className="dd"
            isOpen={this.state.dropdownOpen}
            toggle={() => this.toggleDropdown()}
          >
            <DropdownToggle caret className="dd-toggle">
              {county || "Select a county"}
            </DropdownToggle>
            <DropdownMenu
              className="dd-menu"
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: data => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: "auto",
                        maxHeight: 100
                      }
                    };
                  }
                }
              }}
            >
              {countys}
            </DropdownMenu>
          </Dropdown>
          <Button
            size="sm"
            className="dd-back-btn"
            outline
            color="secondary"
            onClick={e => this.props.previousSlide()}
          >
            Reselect State
          </Button>
        </div>
      </div>
    );
  }
}
