import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0039 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.10.01</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Burning of Brush</strong><p />
            </Col></Row>
            <Row ><Col xs="12" sm="12" md="12" className="main">
                Brush Colorado is a tiny two-horse town in the middle of the high-altitude pasture land on the western plains. The residents farm crops and raise cattle.  They have mud on their cowboy boots, and actively support their local American Legion. The folks in Brush go out on Friday night for dinner at the True Grits Steakhouse, or catch a movie starring Tom Cruise or Harrison Ford at the Sands Cinema. On Sunday, everyone goes to church. Welcome to the wild, wild west in 2006.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Most everybody in Brush works the land for a living, and sees the world in pretty much the same way.  But controversies do kick up. When conflicts arise, even ones of great pitch and moment, the people of Brush resolve their differences pretty much the same way they have for centuries. They squawk at and listen to each other long enough to arrive at a reasonable solution . . . eventually.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">On one occasion a passionate debate broke out over whether or not the law should allow folks in the “city” to have chickens in their backyard. That kind of thing was normally reserved for the farming district, and the town erupted with opinions for and against. Some folks felt strongly that they had a civil right to have a chicken coop in their backyard. Others feared that the town would soon be overrun with ungovernable fowl cock-a-doodle-dooing at dawn, even on Saturdays when people slept in as late as nine thirty.  In the end, they came to agreement, and the town passed an ordinance allowing “city” folks to have their chickens, so long as no roosters lived in the coops. All remained peaceful in this town that actively embraced civic compromise.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">All that changed in the fall of 2006. Brush sat in Colorado’s 4th Congressional district, a reliably Republican district in part, thanks to Morgan County, of which Brush was a part. But in 2004, Morgan County began to show signs of wavering, putting the election onto the radar of both major parties.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The two-term Republican incumbent, Marilyn Musgrave, continued to campaign on a strongly conservative platform.  In 2004, Marilyn received 51% of the vote with her Democratic rival securing only 45%.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But this year a new candidate had stepped forward. Angie Paccione, a Democrat who reflected more liberal views, which were gaining popularity in the district.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Despite their differences, both candidates were pretty much in sync when it came to the issues that really mattered to the people of Brush, like the price of sugar beets, drilling in Rocky Mountain National Park, or the fate of a jumping field mouse, who though endangered and protected by federal regulations, could really do a lot of damage to a sugar beet crop. Both of the candidates were women of faith, and even spent their Sundays at the same Assembly of God church, where many of the folks in Brush worshipped.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Then a third candidate joined the race.  Reformist Party candidate, Eric Eidsness, had served as an environmental advisor to the Reagan administration and was a Vietnam vet. He entered the race for the general election after losing in the Republican primary. Despite his loss of the Republican nomination, he had received significant support and thought he still had something to offer the community.  His political platform sat between Marilyn’s and Angie’s, but his background and politics attracted more voters from the political right than the political left.  Even though he never received close to the level of support he needed to win, his presence began to change the trajectory of the race. By October polls showed Marilyn Musgrave losing to Angie Paccione.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The voters of Brush did as their voting method required of them, they divided into their candidate camps and fought to make their camp the largest. These political election battles stress out a community at the best of times.  Unlike discussions over chickens where folks can talk to each other over what they want, what they don’t and how they feel about different alternatives coming to civic consensus before enacting a law, in an election, you only get one vote.  There are no peaceful civic compromises available.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">In a minimal voting system like ours, you can say only one thing, the name of a candidate whose political platform was built, not for betterment of the community, but for electoral victory.  This had rattled along on the tracks pretty much unquestioned by this reliably Republican district, until the polls started flashing purple. When the Republican National Committee noticed that they were at risk of losing a legacy district, they jumped right into the middle of things with both feet with the Democratic National Committee right behind.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The race went national, as the national parties began driving the debate. The Republican and Democratic national committees spent over eleven million dollars combined on attack ads. Suddenly ranchers and sugar beet farmers started arguing over immigration reform, the War in Iraq, internet privacy laws, and global warming.  Issues that had little to impact on their lives and of which they had little to no knowledge became flashpoints of anger and rage.  What mattered to the community of Brush, no longer mattered to their election. Even George W. Bush aired in, and gave a speech in the district in support of Marilyn Musgrave. In the end, the Republicans managed to hold onto the seat on election day receiving only about 45% of the vote. The Republican party won, but Brush lost.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">That vicious election in 2006 changed life in Brush, and certainly changed life for members of the local Assembly of God church forever. The community had been divided by politicians doing what politicians have to do in order to win an election, but they left a little town in doubt about why their method for reaching peaceful compromise, a method that could resolve sticky chicken issues, had suddenly failed.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">The amped up Congressional race in 2006 had become so personal and corrosive that Angie and Eric, gifted politicians and devoted public servants, left government service forever. Marilyn went on to serve her third term in Congress and was defeated in the following election. The 4th Congressional district continued to be an election battle ground. The Democrats took the district two years later, but then it flipped back to Republican’s in 2010.  Republicans gerrymandered the district in 2011 and the district has remained a safe Republican seat to this day.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">No one designed our current plurality voting method with the intent of tearing apart our communities, congregations, and lives.  No one designed it to do anything, except identify a winner. One-voter-picks-one-candidate seemed like such a simple idea that no one stopped to think about the consequences of employing it, or about what candidates, politicians, and political parties would be required to do to win an election using this method.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">But tear apart families and communities is exactly what it is doing; from small towns to municipalities across America; it is the engine that drives or national division—we just give it all the fuel we can.


            </Col></Row><p />
        </Container>
        </>
    )
}

export default Blog0039;
