import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0019 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.05.14</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>This week's goal. </strong>
                14-20 May:  get New York on the scorevoting.us board.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose of the Weekly Heart Beat. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>Waited for more feedback on the two stories.  None came, so had a slower week (for the effort—work was very busy).</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book. </strong>Editor has been election-story gathering, and we will focus on that for the upcoming week (if you have any good ones, send me an email at all50socreby2024@gmail.com.)  </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>Some planning on the organization this week.  Ideas about creating a 501(c)3 non-profit.  Sales of the book would then be a donation.  Also, we need to create easy to remember and pass on stories that form the basic examples of why the two-party system, which derives from our voting method (not people or the parties) drives our social problems. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong> I will write the book with the basic elements of the message and I still need to figure that out.  Right now, I want to find the right stories, okay, any stories at this point. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I will look for the stories.  I also now have the ability to place advertising on the website.  That will help reduce costs in the long run.</Col></Row>

        </Container>
        </>
    )
}

export default Blog0019;
