import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"


const Blog0007 = () => {
    const [image] = useState(logo);
    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.02.12</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Purpose. </strong>
                The weekly heartbeat keeps all supporters and swarmers informed on the movement’s progress nationwide.</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Last Week’s Focus. </strong>I published an article in The Democracy Chronicles <span><a href='https://democracychronicles.org/ranked-voting-in-the-2020-elections'>https://democracychronicles.org/ranked-voting-in-the-2020-elections</a></span>.  I have created most of the new video.  I also attended training from Vote Nevada, and learned more about the legislative schedule. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Book's Editor. </strong>Still waiting.  I do plan on working another edit of the book this weekend.  </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Organization. </strong>I will try to produce as much material for everyone else, but I have come to learn that one can only influence the elected officials of one’s own legislature.  There is still the ability to influence the masses, so the masses will continue to be the target audience. For Nevada, my effort at influencing the politicians must wait until 2023, but that will give more time to created the needed base and to get my book out there. </Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>The Message. </strong>I am happy to have compiled some useful information for all supporters of ranked-choice voting which I included in that article.  I think we now have hard evidence that RCV does not change anything (in regards to fixing our country).  I think it will also help when I get this next video done to explain exactly why our nation is divided.</Col></Row>
            <p /><Row ><Col xs="12" sm="12" md="12" className="main">
                <strong>Next Week’s Focus. </strong>I will try to get a draft of the new video done.  Finding a way to be usable audio will be more of a challenge.  Maybe I’ll just go with a professional.  I will also work on getting another draft of the book done with the goal to have this completed by the end of next weekend.  Another potential side project will be to start to develop better help files for the swarm age tool. </Col></Row>

        </Container>
        </>
    )
}

export default Blog0007;
