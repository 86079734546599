import React, { useState } from 'react';
import logo from "./blogImg/LogoIcon.svg";
import { Container, Row, Col } from "reactstrap";
import "./blog.css"

const Blog0051 = () => {
    const [image] = useState(logo);

    return (
        <><Container>
            <Row><Col>
                <img className="image" src={image} alt="" />
            </Col>
            </Row>
            <Row><Col className="date">21.12.24</Col></Row>
            <p />
            <Row ><Col xs="12" sm="12" md="12" className="title">
                The Weekly Heart Beat
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main"><title>End Division in America</title>
                <strong>For Nevada, for America</strong><p />
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We mandate our own politically division when we require voters to pick a candidate. This happens even when done iteratively as in ranked-choice voting methods. When a candidate wins a 51% majority they demonstrate, to be elected, they can ignore the 49% minority but must support of the 51% majority. These voting methods divide us.  I call them “min voting” methods.
            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">We politically unite America when American voters can say how much they desire each candidate and we declare the voter's most desired candidate the winner. Doing so gives an advantage to candidates who align their political platform to the majority opinion of the voters.  These voting methods unite us. I call them “max voting” methods.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">This initiative would change Nevada's Constitution for the general election of United States senator and member of Congress, district and state officers to the max voting method, Division Free Voting. Division Free Voting allows each voter to award points, on a scale from 0 to 7, to each candidate. The candidate with the highest average number of points wins.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Switching to Division Free Voting requires no new voting machines.  Any voting machine that can handle our current plurality voting method can handle the proposed Division Free Voting method.  Ballots must also be redesigned, reprinted, and re-issued each election so that, too, is an already sunk cost.  I believe the only likely additional cost is that of printing the extra six marking areas next to each candidate's name.  Any additional cost would be paid through a sufficient increase in Nevada state sales tax.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">This initiative is for all Nevadans and needs Nevadans to succeed.  If you want to permanently end our political division, go now to our maxvoting.org site and register as a Nevada supporter.  We can all monitor this site to watch our number of supporters grow county by county.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">When approximately 3% of Nevada voters have registered as supporters, we will validate that Division Free Voting is the type of max voting Nevada wants through a vote by supporters, so those desiring approval voting or STAR voting are encouraged to register as well. The final initiative for signature will match what Nevadans said they wanted in this vote.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">When approximately 10% of Nevada voters have signed up on the site as supporters, we will execute the signature campaign to place the initiate on the ballot.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">To people who think Nevada voters will blindly follow the wishes of candidates and political parties, I say, you don't get how independent Nevadan's are.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">To people who say that we should adopt ranked-choice voting (or final-five voting) because everyone else is doing it, I say, since when do Nevadan's blindly follow the tracks of others who have wandered out into the desert?

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">To people who say that max voting methods are untested, I say, that min voting methods have been thoroughly tested and shown to create and maintain our destructive division; we need what we've never had to do what we've never done.

            </Col></Row><p />
            <Row ><Col xs="12" sm="12" md="12" className="main">Successfully implementing max voting changes everything and demonstrates Nevada’s leadership in ending political division in the United States. Partisanism diminishes when all candidates seek to support the same majority opinion platform in an election.  Governmental gridlock disappears when elected representatives aren't beholding to a “political majority” for support. And gerrymandering becomes as meaningless as it is difficult to enact when there is no “political majority” to support it.
            </Col></Row><p />


        </Container>
        </>
    )
}

export default Blog0051;
