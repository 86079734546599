export const QUESTION = "What is your next supporter level goal?";
export const MANIFESTO_QUESTION = "Our Mission Statement";
export const BELT_LEVEL = [
  0,
  0.05,
  0.1,
  0.2,
  0.39,
  0.78,
  1.6,
  3.1,
  6.3,
  13,
  25,
  100,
];
export const MANIFESTO_CONTEXT = `<title> max voting unites us</title>
    <b>When we ask the wrong question, we divide.</b> 
    <br/>When we ask voters, "Which candidated do you want to represent you?" we forceable divide ourselves into political factions. We then require candidates to create the largest faction to win. But when a candidate wins a 55% majority, they have demonstrated two things. 1) they can ignore the 45% minority and be elected, and 2) they must take care of the 55% majority to be elected.<br/>  
    <br/><b>When we ask the right question, we unite.</b><br/>
    When we ask voters, "How much does each candidate represent you?” we can discover and elect the candidate that best represents all of us, as a whole community of voters. When we ask "how much?" candidates have an incentive to choose a political platform that most represents all of us, as a whole community of voters, and there is only one political platform that does that, the majority opinion of the voters. <br/>
    <br/>"How much?"" changes “majority rule” into “majority opinion rule.”  And, what is the difference between a Republican and a Democrat when they support the same political platform? <br/>
    <br/><b>You can help us ask the right question.</b>
    <br/>Most people are looking for a way to reduce our polarized disfunction, and we want to help you do that! Show us you want to start asking the right question by registering your support on this site.
    <br/><br/><b>Our Mission.</b><br/>
    We are dedicated to ending political division in every state and Washington D.C. by instituting voting methods which ask the right question. <br/><br/>
    <b>What is MaxVoting.</b>
    <br/>MaxVoting methods are voting methods that allow all voters to rate all candidates on a set scale, and use all of that information to find a winner.<br/>
    * <b>Division Free Voting</b> is MaxVoting method<br/>
    * <b>Approval Voting</b> is a MaxVoting method<br/>
    * <b>STAR Voting</b> is a MaxVoting method<br/>
    <br/><b>Your voting method.</b><br/>
    Not only do we want to institute a MaxVoting method in your state, we want it to be the one you want.
    <br/><br/> When we reach supporter level 7 (~3% of the population), we will poll supporters in that state to decide the type of MaxVoting they want (range, runoffs, & “no opinions”—different configurations create approval voting, or STAR voting, etc.)<br/> 
   <br/>	When we reach supporter level 9 (~13% of the population), we will initiate the signature campaign for ballot measures and/or state legislative actions to institute their form of MaxVoting.<br/><br/>
<b><i>We end our division when we start asking the right question. <br/>MaxVoting. It's time. </i</b><br/>


`;

export const levels = [
  {
    level: 0,
    value: ".00%",
    blockColor: "#ffffff",
    color: "#333333",
    levelColor: "black",
    selected: false,
  },
  {
    level: 1,
    value: ".05%",
    blockColor: "#fcfbfd",
    color: "#333333",
    levelColor: "black",
    selected: false,
  },
  {
    level: 2,
    value: ".10%",
    blockColor: "#efedf5",
    color: "#333333",
    levelColor: "black",
    selected: false,
  },
  {
    level: 3,
    value: ".20%",
    blockColor: "#dadaeb",
    color: "#333333",
    levelColor: "black",
    selected: false,
  },
  {
    level: 4,
    value: ".39%",
    blockColor: "#bcbddc",
    color: "#333333",
    levelColor: "black",
    selected: false,
  },
  {
    level: 5,
    value: ".78%",
    blockColor: "#9e9ac8",
    color: "#333333",
    levelColor: "black",
    selected: false,
  },
  {
    level: 6,
    value: "1.6%",
    blockColor: "#807dba",
    color: "#FFFFFF",
    levelColor: "black",
    selected: false,
  },
  {
    level: 7,
    value: "3.1%",
    blockColor: "#6a51a3",
    color: "#FFFFFF",
    levelColor: "black",
    selected: false,
  },
  {
    level: 8,
    value: "6.3%",
    blockColor: "#54278f",
    color: "#FFFFFF",
    levelColor: "black",
    selected: false,
  },
  {
    level: 9,
    value: "13%",
    blockColor: "#3f007d",
    color: "#FFFFFF",
    levelColor: "black",
    selected: false,
  },
  {
    level: "Goal",
    value: "25%",
    blockColor: "#FFFF00",
    color: "#D9001B",
    levelColor: "#D9001B",
    selected: false,
  },
];

export const getCurrentLevel = (levelVal) => {
  if (Number.isNaN(levelVal)) return 0;
  let i = 0;

  for (i = 0; i < BELT_LEVEL.length - 1; ++i) {
    if (levelVal >= BELT_LEVEL[i] && levelVal <= BELT_LEVEL[i + 1]) {
      break;
    }
  }

  if (i === 11) return 0;

  return i;
};
