import React, { Component } from "react";
import { Col } from "reactstrap";
import './SupporterNumber.css'

class SupporterNumber extends Component {
    render() {
        const { number } = this.props
        return (
            <Col
                xs="4"
                sm="4"
                md="4"
                className='supporter-number'
            >
                {number >= 0 ? number : 0}
            </Col>
        )
    }
}

export default SupporterNumber;